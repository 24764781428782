import React from 'react';

export default React.createContext({
    products: [],
    services: [],
    platforms: [],
    categories: [],
    sliderItems: [],
    recommendation: [],
    subscriptions: [],
    orders: [],
    articles: [],
    articleHighlights: [],
    cart: { cartItems: [], cartID: 0 },
    baseUrl: "",
    token: "",
    awsS3url: "",
    paymentResult: "",
    paymentAction: "",
    userID: 0,
    logged: 0,
    productsCount: 0,
    loading: false,
    cookiesAllowed: false,
    usertype: -1,
    variant: {},
    product: {},
    storeDetails: {},
    paymentIsCompleteVisible: false,
    CheckoutRequestID: "",
    menuVisible: "hidden",
    order: {},
    article: {},
    searchResult: {},
    addresses: [],
    tokenVal: "",
    postComment: (articleID, strEmail, strName, strComment) => { },
    postLike: (articleID, uid) => { },
    postDisLike: (articleID) => { },
    fetchArticle: (articleID) => { },
    fetchArticles: () => { },
    fetchArticlesHighlight: () => { },
    attachAddressToCart: (addressId) => { },
    changePasswd: (phoneNumber, code, password) => { },
    getArticle: (articleID, articles) => { },
    scrollToTop: () => { },
    fetchArticlesWithPromise: (page, url) => { },
    fetchArticlesSectionWithPromise: (section, page, url) => { },
    fetchArticleWithPromise: (articleID, url) => { },
    requestAuthReset: (phoneNumber) => { },
    initiatePay: (phoneNumber) => { },
    completeOrder: (cartID) => { },
    completePayment: (txt) => { },
    toggleMenu: () => { },
    fetchOrder: (cartID) => { },
    fetchPaymentDetails: () => { },
    fetchCartByIP: () => { },
    fetchProduct: (id) => { },
    fetchVariant: (id) => { },
    fetchRecommend: (id) => { },
    removeItem: (id, itemType) => { },
    subItem: (id, itemType, qty) => { },
    search: (searchText) => { },
    itemInCart: (cart, itemID, itemType) => { },
    addItem: (id, itemType, qty) => { },
    addCart: (uid) => { },
    fetchProducts: () => { },
    fetchCategories: () => { },
    subscribe: (email, category) => { },
    fetchByItemType: (key) => { },
    fetchServicesByCategory: (key) => { },
    fetchProductByCategory: (key) => { },
    fetchPrimaryAddress: (uid) => { },
    fetchAddresses: (uid) => { },
    fetchUser: (uid) => { },
    fetchSubscriptions: (uid) => { },
    fetchOrders: (uid) => { },
    addAddress: (uid, area, street, city) => { },
    updateUserName: (uid, firstName, lastName) => { },
    fetchUserWithResp: (uid, url, tokenVal) => { },
    logout: () => { },
    login: (phoneNumber, password, to) => { },
    register: (phoneNumber, password, email, username, referredBy) => { },
    addContact: (name, email, subject, issue) => { },
    fetchServices: () => { }
});