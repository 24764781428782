import React, { Component, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ShopContext from '../../context/shop-context';
import Swal from 'sweetalert2';


export const TopHeader = (props) => {
    const context = useContext(ShopContext);
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(false)
    const mobile = useMediaQuery({ query: `(max-width: 480px)` });
 
    const searchItem = () => {
        if (values.searchText == "") {
            Swal.fire("Search", "Text to search is missing.", "error")
            return
        }
        navigate("/search", { state: { "searchText": values.searchText } })
        context.scrollToTop()
        const openSearch = document.querySelector("#openSearch")
        const closeSearch = document.querySelector("#closeSearch")
        const searchForm = document.querySelector(".searchForm")
        searchForm.classList.remove("open")
        closeSearch.style.display = "none"
        openSearch.style.display = "block"
    }

    const [values, setValues] = useState({
        searchText: "",
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    useEffect(() => {
        if (mobile) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        const openSearch = document.querySelector("#openSearch")
        const closeSearch = document.querySelector("#closeSearch")
        const searchForm = document.querySelector(".searchForm")

        openSearch.addEventListener("click", function (event) {
            searchForm.classList.add("open")
            this.style.display = "none"
            closeSearch.style.display = "block"
        })

        closeSearch.addEventListener("click", function (event) {
            searchForm.classList.remove("open")
            this.style.display = "none"
            openSearch.style.display = "block"
        })
    }, [props])
    return (
        <div className="container">
            <div className="header-top">
                <div className="logo">
                    <Link to="/"><img src={"https://nitrade.pro/assets/images/logos/nitrade_logo.png"} alt="" /></Link>
                    <span className="logoText">NITRADE</span>
                </div>
                <div className="navRight">
                    {context.logged != 1 ?
                        <div className="myAccountContainer">
                            <span className="accountIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" width="20px" viewBox="0 0 50 50"><path d="M24 23.95q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1ZM8 40v-4.7q0-1.9.95-3.25T11.4 30q3.35-1.5 6.425-2.25Q20.9 27 24 27q3.1 0 6.15.775 3.05.775 6.4 2.225 1.55.7 2.5 2.05.95 1.35.95 3.25V40Zm3-3h26v-1.7q0-.8-.475-1.525-.475-.725-1.175-1.075-3.2-1.55-5.85-2.125Q26.85 30 24 30t-5.55.575q-2.7.575-5.85 2.125-.7.35-1.15 1.075Q11 34.5 11 35.3Zm13-16.05q1.95 0 3.225-1.275Q28.5 18.4 28.5 16.45q0-1.95-1.275-3.225Q25.95 11.95 24 11.95q-1.95 0-3.225 1.275Q19.5 14.5 19.5 16.45q0 1.95 1.275 3.225Q22.05 20.95 24 20.95Zm0-4.5ZM24 37Z" /></svg>
                            </span>
                            <Link to="/login">Sign in</Link>
                            <span className="divider">|</span>
                            <Link to="/register">Register</Link>
                            <div id="mobile-menuClose"className="mobile-menuClose"  onClick={context.toggleMenu} style={{ visibility: context.menuVisible }}>
                                <span className="material-symbols-outlined">close</span>
                            </div>
                        </div>
                        : <div className="myAccountContainer">
                            <div>
                                <span className="accountIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" width="20px" viewBox="0 0 50 50"><path d="M24 23.95q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1ZM8 40v-4.7q0-1.9.95-3.25T11.4 30q3.35-1.5 6.425-2.25Q20.9 27 24 27q3.1 0 6.15.775 3.05.775 6.4 2.225 1.55.7 2.5 2.05.95 1.35.95 3.25V40Zm3-3h26v-1.7q0-.8-.475-1.525-.475-.725-1.175-1.075-3.2-1.55-5.85-2.125Q26.85 30 24 30t-5.55.575q-2.7.575-5.85 2.125-.7.35-1.15 1.075Q11 34.5 11 35.3Zm13-16.05q1.95 0 3.225-1.275Q28.5 18.4 28.5 16.45q0-1.95-1.275-3.225Q25.95 11.95 24 11.95q-1.95 0-3.225 1.275Q19.5 14.5 19.5 16.45q0 1.95 1.275 3.225Q22.05 20.95 24 20.95Zm0-4.5ZM24 37Z" /></svg>
                                </span>
                                <Link to="/dashboard">My Account</Link>
                            </div>

                            <div id="mobile-menuClose" onClick={context.toggleMenu} className="mobile-menuClose" style={{ visibility: context.menuVisible }}>
                                <span className="material-symbols-outlined">close</span>
                            </div>
                        </div>}

                    <div className="cartSearchContainer">
                        <div className="searchIcon">
                            <div id="openSearch">
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <div id="closeSearch" style={{ display: "none" }}>
                                <span className="material-symbols-outlined">close</span>
                            </div>
                        </div>
                        <Link to="/cart" className="cartBox">
                            <div className="cartIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M14.35 43.95q-1.5 0-2.55-1.05-1.05-1.05-1.05-2.55 0-1.5 1.05-2.55 1.05-1.05 2.55-1.05 1.5 0 2.55 1.05 1.05 1.05 1.05 2.55 0 1.5-1.05 2.55-1.05 1.05-2.55 1.05Zm20 0q-1.5 0-2.55-1.05-1.05-1.05-1.05-2.55 0-1.5 1.05-2.55 1.05-1.05 2.55-1.05 1.5 0 2.55 1.05 1.05 1.05 1.05 2.55 0 1.5-1.05 2.55-1.05 1.05-2.55 1.05Zm-22.6-33 5.5 11.4h14.4l6.25-11.4Zm-1.5-3H39.7q1.15 0 1.75 1.05.6 1.05 0 2.1L34.7 23.25q-.55.95-1.425 1.525t-1.925.575H16.2l-2.8 5.2h24.55v3h-24.1q-2.1 0-3.025-1.4-.925-1.4.025-3.15l3.2-5.9L6.45 7h-3.9V4H8.4Zm7 14.4h14.4Z" /></svg>
                            </div>
                            <div className="cartCount">{context.cart.cartItems.length}</div>
                        </Link>
                    </div>
                    <div id="mobile-menu" onClick={context.toggleMenu} style={{ visibility: isMobile ? "visible" : "hidden" }} className="hamburger-menu">
                        <div className="line-menu"></div>
                        <div className="line-menu"></div>
                        <div className="line-menu"></div>
                    </div>
                    <div className="searchForm">
                        <input type="text" name="searchText" onChange={onChange} className="form-control" placeholder="Search text." />
                        <div className="searchFormBtn">
                            <button className="btn" onClick={searchItem}>
                                <span className="material-symbols-outlined">search</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}