
import React, { useEffect } from 'react';
import Header from "../components/header";
import Footer from '../components/footer';
import ProductsView from '../components/product/products-view';
import Cookies from '../components/cookies';
import Loading from '../components/loading';

export const ProductsViewPage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="shop" />
      <ProductsView />
      <Footer />
      <Cookies />
    </div>
  )
}

export default ProductsViewPage