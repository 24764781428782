import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import ShopContext from "../../context/shop-context";
import toast, { Toaster } from "react-hot-toast";

const options = {
  // items: 3,
  slideBy: 1,
  responsiveRefreshRate: 0,
  lazyLoad: true,
  lazyloadeager: 1,
  autoplay: true,
  loop: true,
  nav: true,
  navText: [
    '<span class="material-symbols-outlined">navigate_before</span>',
    '<span class="material-symbols-outlined">navigate_next</span>',
  ],
  dots: false,
  margin: 15,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayHoverPause: false,

  responsive: {
    // breakpoint from 0 up
    0: {
      stagePadding: 40,
      items: 1,
    },
    // breakpoint from 570 up
    570: {
      stagePadding: 100,
      items: 1,
    },
    // breakpoint from 670 up
    670: {
      stagePadding: 100,
      items: 2,
    },
    // breakpoint from 960 up
    960: {
      stagePadding: 190,
      items: 2,
    },
    // breakpoint from 1100 up
    1100: {
      stagePadding: 245,
      items: 1,
    },
  },
};

export const HomeContent = (props) => {
  const context = useContext(ShopContext);
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [otherArticles, setOtherArticles] = useState([]);
  const navigate = useNavigate();
  const fetchProductsByItemType = (e) => {
    var key = e.currentTarget.getAttribute("data-key");
    navigate("/products", { state: { itemType: key } });
    context.scrollToTop();
  };
  const fetchProductsByCategory = (e) => {
    var key = e.currentTarget.getAttribute("data-key");
    navigate("/products", { state: { category: key } });
    context.scrollToTop();
  };
  const fetchServicesByCategory = (e) => {
    var key = e.currentTarget.getAttribute("data-key");
    navigate("/services", { state: { category: key } });
    context.scrollToTop();
  };
  // const fetchByCategory = (e) => {
  //     var key = e.currentTarget.getAttribute("data-key")
  //     context.fetchServicesByCategory(key)
  // }
  const [values, setValues] = useState({
    email: "",
    category: "all",
  });

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const viewBlogItem = (e) => {
    let key = e.currentTarget.getAttribute("data-key");
    navigate("/article", { state: { article: key } });
    context.scrollToTop();
  };
  const GetEscapedString = (str) => {
    let escapedString = str.replace(/ /g, "_");
    return escapedString;
  };
  const RemoveUnderscore = (str) => {
    let resultString = str.replace("_", " ");
    return resultString;
  };

  const subscribeBlog = () => {
    if (values.category == "" || (values.email = "")) {
      toast.error("Email missing.");
      return;
    }
    context.subscribe(values.email, values.category);
  };

  useEffect(() => {
    context
      .fetchArticlesSectionWithPromise("highlight", page, context.baseUrl)
      .then((val) => {
        var offset = (val.message.length - 1) * -1;
        var others = val.message.slice(offset);
        setArticles(val.message);
        setOtherArticles(others.slice(0, 3));
      });
  }, []);

  return (
    <div id="homePage">
      <Toaster position="bottom-right" reverseOrder={false} />
      <section className="sliderModule">
        <div className="no-container">
          <OwlCarousel id="sliderModule1" className="owl-carousel" {...options}>
            {context.sliderItems.map((item) => (
              <div className="item">
                <a href="#!">
                  <img
                    className="responsive-image"
                    src={"assets/images/banners/" + item.img}
                    alt={item.title}
                  />
                  <div className="item-content">
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                    {/* <button data-key={item.category} onClick={fetchServicesByCategory} className="btn btn--primary">Buy Now</button> */}
                  </div>
                </a>
              </div>
            ))}
          </OwlCarousel>
          ;
        </div>
      </section>
      {context.categories.length != 0 ? (
        <section id="gamesDigitalCards">
          <div className="smallContentBlock bg-gray">
            <div className="container">
              <div className="smallContentWrapper">
                <div className="smallContentHeading">
                  <h3 className="h2 text-uppercase">
                    GIFTCARDS, GAMES, MERCH and DEVICES
                  </h3>
                  <div className="seeAllLink">
                    <Link to="/products">
                      <span>See All</span>
                      <span className="material-symbols-outlined small-icon">
                        navigate_next
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="smallContentBoxy">
                  {context.categories.map((category) => (
                    <div
                      className="smallContentItem"
                      data-key={category}
                      onClick={fetchProductsByItemType}
                    >
                      <a href="#!">
                        <div className="smallContentImage">
                          <img
                            src={
                              "assets/images/banners/" +
                              RemoveUnderscore(category) +
                              "_subheader.png"
                            }
                            alt=""
                            className="responsive-image"
                          />
                        </div>
                        <div className="smallContentText">
                          {RemoveUnderscore(category)}
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      <section id="ourCoreProducts">
        <div className="mediumContentBlock">
          <div className="container">
            <div className="mediumContentWrapper">
              <div className="mediumContentBoxy">
                <div className="mediumContentItem">
                  <a
                    href="#!"
                    data-key="recent"
                    onClick={fetchProductsByCategory}
                  >
                    <div className="mediumContentImage">
                      <img
                        className="responsive-image"
                        src={"assets/images/banners/recent-releases.jpg"}
                        alt=""
                      />
                    </div>
                    <div className="mediumContentText">RECENT RELEASES</div>
                  </a>
                </div>
                <div className="mediumContentItem">
                  <Link to="/services">
                    <div className="mediumContentImage">
                      <img
                        className="responsive-image"
                        src={"assets/images/banners/tournament_banner.jpg"}
                        alt=""
                      />
                    </div>
                    <div className="mediumContentText">TOURNAMENT</div>
                  </Link>
                </div>
                <div className="mediumContentItem">
                  <a
                    href="#!"
                    data-key="upcoming"
                    onClick={fetchProductsByCategory}
                  >
                    <div className="mediumContentImage">
                      <img
                        className="responsive-image"
                        src={"assets/images/banners/upcoming.jpeg"}
                        alt=""
                      />
                    </div>
                    <div className="mediumContentText">UPCOMING</div>
                  </a>
                </div>
                <div className="mediumContentItem">
                  <a
                    href="#!"
                    data-key="deal"
                    onClick={fetchProductsByCategory}
                  >
                    <div className="mediumContentImage">
                      <img
                        className="responsive-image"
                        src={"assets/images/banners/deals.jpeg"}
                        alt=""
                      />
                    </div>
                    <div className="mediumContentText">DEALS</div>
                  </a>
                </div>

                <div className="mediumContentItem">
                  <a
                    href="#!"
                    data-key="exclusive"
                    onClick={fetchProductsByCategory}
                  >
                    <div className="mediumContentImage">
                      <img
                        className="responsive-image"
                        src={"assets/images/banners/nitrade_exclusive.jpg"}
                        alt=""
                      />
                    </div>
                    <div className="mediumContentText">NITRADE EXCLUSIVE</div>
                  </a>
                </div>
                <div className="mediumContentItem">
                  <a
                    href="#!"
                    data-key="trending"
                    onClick={fetchProductsByCategory}
                  >
                    <div className="mediumContentImage">
                      <img
                        className="responsive-image"
                        src={"assets/images/banners/trending_category.png"}
                        alt=""
                      />
                    </div>
                    <div className="mediumContentText">TRENDING</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="accessoriesDevices">
                <div className="smallContentBlock bg-gray">
                    <div className="container">
                        <div className="smallContentWrapper">
                            <div className="smallContentHeading">
                                <h3 className="h2 text-uppercase">STREAMING: MUSIC, MOVIES, LIVE SPORTS AND DOCUMENTARIES</h3>
                                <div className="seeAllLink">
                                    <Link to="/services">
                                        <span>See All</span>
                                        <span className="material-symbols-outlined small-icon">navigate_next</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="smallContentBoxy">
                                <div className="smallContentItem">
                                    <a href="#!" data-key="livesports" onClick={fetchServicesByCategory}>
                                        <div className="smallContentImage">
                                            <img src={"assets/images/banners/livesports_subheader.png"} alt=""
                                                className="responsive-image" />
                                        </div>
                                        <div className="smallContentText">LIVE SPORTS</div>
                                    </a>
                                </div>
                                <div className="smallContentItem">
                                    <a href="#!" data-key="movies" onClick={fetchServicesByCategory}>
                                        <div className="smallContentImage">
                                            <img src={"assets/images/banners/movies_subheader.png"} alt="" className="responsive-image" />
                                        </div>
                                        <div className="smallContentText">MOVIES & SERIES</div>
                                    </a>
                                </div>
                                <div className="smallContentItem">
                                    <a href="#!" data-key="anime" onClick={fetchServicesByCategory}>
                                        <div className="smallContentImage">
                                            <img src={"assets/images/banners/anime_subheader.png"} alt="" className="responsive-image" />
                                        </div>
                                        <div className="smallContentText">ANIME</div>
                                    </a>
                                </div>
                                <div className="smallContentItem">
                                    <a href="#!" data-key="music" onClick={fetchServicesByCategory}>
                                        <div className="smallContentImage">
                                            <img src={"assets/images/banners/music_subheader.png"} alt=""
                                                className="responsive-image" />
                                        </div>
                                        <div className="smallContentText">MUSIC</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* <section id="ourLatestProducts">
                <div className="mediumContentBlock">
                    <div className="container">
                        <div className="mediumContentWrapper">
                            <div className="mediumContentBoxy">
                                <div className="mediumContentItem">
                                    <a href="#!" data-key="livesports" onClick={fetchServicesByCategory}>
                                        <div className="mediumContentImage">
                                            <img src={"assets/images/banners/football_category.png"} alt="" className="responsive-image" />
                                        </div>
                                        <div className="mediumContentText">LIVE FOOTBALL</div>
                                    </a>
                                </div>
                                <div className="mediumContentItem">
                                    <a href="#!" data-key="livesports" onClick={fetchServicesByCategory}>
                                        <div className="mediumContentImage">
                                            <img src={"assets/images/banners/ufc_category.png"} alt="" className="responsive-image" />
                                        </div>
                                        <div className="mediumContentText">UFC TV</div>
                                    </a>
                                </div>
                                <div className="mediumContentItem">
                                    <a href="#!" data-key="livesports" onClick={fetchServicesByCategory}>
                                        <div className="mediumContentImage">
                                            <img src={"assets/images/banners/racing_category.png"} alt="" className="responsive-image" />
                                        </div>
                                        <div className="mediumContentText">F1 TV</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {articles.length != 0 ? (
        <section id="latestBlog">
          <div className="homeBlog">
            <div className="container">
              <div className="homeBlogHeading">
                <h2 className="heading-h2">Trending Topics</h2>
              </div>
              <div className="homeBlogWrapper">
                <div
                  className="homeBlog__item item--large"
                  key={articles[0].articleID}
                  data-key={articles[0].articleID}
                  onClick={viewBlogItem}
                >
                  <div className="homeBlog__image">
                    <a href="#!">
                      <img
                        className="responsive-image"
                        src={context.awsS3url + "/images/" + articles[0].photo}
                        alt=""
                      />
                    </a>
                  </div>
                  <h2 className="heading-h2">
                    <a href="#">{articles[0].title}</a>
                  </h2>
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: articles[0].content.slice(0, 180) + "...",
                    }}
                  ></div>
                  <div className="nt-block-post-top-meta">
                    <div className="nt-block-post-author">
                      <span className="author-avatar">
                        <img
                          loading="lazy"
                          className="responsive-image"
                          src={"assets/images/blog/no_photo.jpg"}
                          alt="No photo available"
                          width="25"
                          height="25"
                        />
                      </span>
                      <span className="nt-blocks-author-pretext">
                        By{" " + articles[0].author}
                      </span>
                    </div>
                    <div className="nt-block-meta-divider nt-block-meta-has-divider">
                      |
                    </div>
                    <div className="kt-blocks-date">
                      <time className="nt-blocks-post-date">
                        {articles[0].dateAdded}
                      </time>
                    </div>
                  </div>
                </div>
                <div className="homeBlog__item item--small">
                  {otherArticles.length != 0
                    ? otherArticles.map((item, id) => (
                        <div
                          className="homeBlog__item__boxy item-boxy--small"
                          key={item.articleID}
                          data-key={item.articleID}
                          onClick={viewBlogItem}
                        >
                          <div className="homeBlog__image">
                            <a href="#!">
                              <img
                                className="responsive-image"
                                src={context.awsS3url + "/images/" + item.photo}
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="homeBlogContent">
                            <h2 className="heading-h2">
                              <a href="#">{item.title}</a>
                            </h2>
                            <div
                              className="desc"
                              dangerouslySetInnerHTML={{
                                __html: item.content.slice(0, 80) + "...",
                              }}
                            ></div>
                            <div className="nt-block-post-top-meta">
                              <div className="nt-block-post-author">
                                <span className="author-avatar">
                                  <img
                                    loading="lazy"
                                    className="responsive-image"
                                    src={"assets/images/blog/no_photo.jpg"}
                                    alt="No photo available"
                                    width="25"
                                    height="25"
                                  />
                                </span>
                                <span className="nt-blocks-author-pretext">
                                  By{" " + item.author}
                                </span>
                              </div>
                              <div className="nt-block-meta-divider nt-block-meta-has-divider">
                                |
                              </div>
                              <div className="kt-blocks-date">
                                <time className="nt-blocks-post-date">
                                  {item.dateAdded}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <section id="homeSubscription">
            <div className="homeSubscription-image"></div>
            <div className="container">
              <div className="subcribeHome">
                <h3>Subscribe to our newsletters</h3>
                <p>
                  Nitrade is offering newsletters and updates on the latest
                  developments in the world of competitive video gaming,
                  streaming, sports and technologies. Subscribe to stay informed
                  and receive exclusive offers.
                </p>

                <div className="subcribeHome__input">
                  <input
                    name="email"
                    onChange={onChange}
                    type="text"
                    className="form-control"
                    placeholder="Enter email"
                  />
                </div>
                <div className="subcribeHome__button">
                  <button
                    type="button"
                    onClick={subscribeBlog}
                    className="btn btn--accent"
                  >
                    Subscribe
                  </button>
                </div>
                <div className="subcribeHome__checkboxes">
                  <ul>
                    <li>
                      <input
                        name="category"
                        id="products"
                        onChange={onChange}
                        type="checkbox"
                        value="products"
                      />
                      Products
                    </li>
                    <li>
                      <input
                        name="category"
                        id="services"
                        onChange={onChange}
                        type="checkbox"
                        value="services"
                      />
                      Services
                    </li>
                    <li>
                      <input
                        name="category"
                        id="events"
                        onChange={onChange}
                        type="checkbox"
                        value="events"
                      />
                      Events
                    </li>
                    <li>
                      <input
                        name="category"
                        id="coaching"
                        onChange={onChange}
                        type="checkbox"
                        value="coaching"
                      />
                      Coaching
                    </li>
                    <li>
                      <input
                        name="category"
                        id="all"
                        onChange={onChange}
                        type="checkbox"
                        value="all"
                      />
                      All
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};
