
export const streamSlider02 = {
    items: 3,
    slideby: 1,
    responsiverefreshrate: 0,
    lazyload: "true",
    lazyloadeager: 1,
    autoPlay: true,
    nav: "false",
    navText: ['<span class="material-symbols-outlined">navigate_before</span>', '<span class="material-symbols-outlined">navigate_next</span>'],
    dots: "true",
    margin: 15,
    autoplaytimeout:5000,
    autoplayhoverpause:"false",   
    responsive: {
        // breakpoint from 0 up
        0: {
            items: 1,
        },
        // breakpoint from 570 up
        570: {
            items: 2,
        },
        // breakpoint from 670 up
        670: {
            items: 4,
        },
        // breakpoint from 960 up
        960: {
            items: 4,
        },
        // breakpoint from 1100 up
        1100: {
            items: 4,
        },
    },
  };
  /*---------- STREAM O1 SLIDER ----------*/
export const streamSlider01 = {
    items: 3,
    slideby: 1,
    responsiverefreshrate: 0,
    lazyload: "true",
    lazyloadeager: 1,
    autoPlay: true,
    nav: "false",
    navText: ['<span class="material-symbols-outlined">navigate_before</span>', '<span class="material-symbols-outlined">navigate_next</span>'],
    dots: "true",
    margin: 15,
    autoplaytimeout:5000,
    autoplayhoverpause:"false",   
    responsive: {
        // breakpoint from 0 up
        0: {
            items: 1,
        },
        // breakpoint from 570 up
        570: {
            items: 2,
        },
        // breakpoint from 670 up
        670: {
            items: 3,
        },
        // breakpoint from 960 up
        960: {
            items: 3,
        },
        // breakpoint from 1100 up
        1100: {
            items: 4,
        },
    },
  }
  /*---------- STREAM O2 SLIDER ----------*/
 export const streamSlider03 = {
    items: 7,
    slideby: 1,
    responsiverefreshrate: 0,
    lazyload: "true",
    lazyloadeager: 1,
    autoPlay: true,
    nav: "false",
    navText: ['<span class="material-symbols-outlined">navigate_before</span>', '<span class="material-symbols-outlined">navigate_next</span>'],
    dots: "true",
    margin: 15,
    autoplaytimeout:5000,
    autoplayhoverpause:false,   
    responsive: {
        // breakpoint from 0 up
        0: {
            items: 1,
        },
        // breakpoint from 570 up
        570: {
            items: 2,
        },
        // breakpoint from 670 up
        670: {
            items: 4,
        },
        // breakpoint from 960 up
        960: {
            items: 5,
        },
        // breakpoint from 1100 up
        1100: {
            items: 6,
        },
    },
  }