import React, { useState } from 'react';
import Header from "../../components/header";
import Footer from '../../components/footer';
import { AccountReview } from '../../components/dashboard/account-review';
import Loading from '../../components/loading';
import Cookies from '../../components/cookies';

export const AccountReviewPage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="dashboard" />
      <AccountReview />
      <Footer />
      <Cookies />
    </div>
  )
}

export default AccountReviewPage