import React, { useContext, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from "../components/header";
import Footer from '../components/footer';
import { Address } from '../components/checkout/address';
import { useLocation } from 'react-router-dom';
import ShopContext from '../context/shop-context';
import Cookies from '../components/cookies';
import Loading from '../components/loading';

export const AddressPage = () => {
  const context = useContext(ShopContext);
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="dashboard" />
      <Address />
      <Footer />
      <Cookies />
    </div>
  )
}