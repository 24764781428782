import React, { useState } from 'react';
import Header from "../../components/header";
import Footer from '../../components/footer';
import Inbox from '../../components/dashboard/inbox';
import Loading from '../../components/loading';
import Cookies from '../../components/cookies';

export const InboxPage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="dashboard" />
      <Inbox />
      <Footer />
      <Cookies />
    </div>
  )
}

export default InboxPage