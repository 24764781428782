import axios from 'axios';
import React from 'react';

import DashboardAside from './dashboard-aside';


export const Inbox = () => {
    return (
        <div id="dashboard">
            <div className="dashboardPagey">
                <div className="container">
                    <div className="dashboardPageyWrapper">
                        <div className="dashboardPageyGrid">
                            <DashboardAside active="inbox" />
                            <div className="dashboardPageyContent">
                                <div className="cardBoxy">
                                    <div className="dashboardPageyHeader">
                                        <h2>Inbox</h2>
                                    </div>
                                    <div className="dashboardPageyBody"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inbox