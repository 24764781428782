
import Cookies from "../components/cookies";
import ForgotPasswordContent from "../components/forgotPasswordContent";
import Header from "../components/header";
import Loading from "../components/loading";

const ForgotPassword = () => {
    return (
        <div>
          <Loading/>
          <div className="overlay-mobileMenu"></div>
          <Header login={true} />
          <ForgotPasswordContent/>
          <Cookies/>
        </div>
    )
}

export default ForgotPassword