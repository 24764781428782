import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { BulletList } from 'react-content-loader'
import { Toaster } from 'react-hot-toast';
import ReactPlaceholder from 'react-placeholder/lib';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';


export const BlogHome = () => {
    const context = useContext(ShopContext);
    const [page, setPage] = useState(0)
    const navigate = useNavigate();
    const { state } = useLocation()
    const [articles, setArticles] = useState([]);
    const [mainArticles, setMainArticles] = useState([]);
    const [otherViewsVisibility, setOtherViewsVisibility] = useState("hidden");
    const [otherArticles, setOtherArticles] = useState([]);

    const loadMore = () => {
        var newPage = page + 1
        setPage(newPage)
        context.fetchArticlesSectionWithPromise("other", newPage, context.baseUrl).then((resp) => {
            if (resp.message.length > 0) {
                setArticles(resp.message)
                setOtherViewsVisibility("visible")
            }else{
                setPage(0)
                navigate("/articles")
            }
        })
        context.fetchArticlesSectionWithPromise("highlight", newPage, context.baseUrl).then((resp) => {
            var mainArticles = [];
            if (resp.message.length > 0) {
                mainArticles.push(resp.message[0])
                setMainArticles(mainArticles)
                var offset = (resp.message.length - 1) * -1
                var others = resp.message.slice(offset)
                setOtherArticles(others.slice(0, 2))
                setOtherViewsVisibility("visible")
            } else {
                setPage(0)
                navigate("/articles")
            }
        })

        context.scrollToTop()
    }

    const viewBlogItem = (e) => {
        var key = e.currentTarget.getAttribute("data-key")
        navigate("/article", { state: { "article": key } })
        context.scrollToTop()
    }

    useEffect(() => {
        loadMore()
    }, [state])

    return (
        <main id="blogHome">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />

            <div className="blogBanner">
                <div className="container">
                    {articles.length != 0 ?
                        <div className="blogBanner__grid">

                            <div className="blogBanner__grid__item item--large">
                                {mainArticles.map((article, i) => (
                                    <div key={i} data-key={article.articleID} onClick={viewBlogItem} className="banner__image banner--large">
                                        <div className="overlay"></div>
                                        <div className="banner__image__figure">
                                            <img className="responsive-image" src={context.awsS3url + "/images/" + article.photo} />
                                        </div>
                                        <div className="banner__image__caption">
                                            <h2 className="heading-h2">
                                                <a href="#">{article.title}</a>
                                            </h2>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="blogBanner__grid__item item--small">
                                <div className="blogBanner__grid__item__aside">
                                    {otherArticles.map((article, i) => (
                                        <div key={i} data-key={article.articleID} onClick={viewBlogItem} className="banner__image banner--small">
                                            <div className="overlay"></div>
                                            <div className="banner__image__figure">
                                                <img className="responsive-image" src={context.awsS3url + "/images/" + article.photo} />
                                            </div>
                                            <div className="banner__image__caption">
                                                <h2 className="heading-h2">
                                                    <a href="#">{article.title}</a>
                                                </h2>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        :
                        <ReactPlaceholder showLoadingAnimation={context.loading} style={{ padding: "16px" }} type="media" rows={10}>
                            <p>No articles found</p>
                        </ReactPlaceholder>}
                </div>
            </div>
            <div className="post__list">
                <div className="container">
                    <div className="post__list__grid">
                        <div className="post__list__item">
                            <div className="nt-block-post-grid">
                                <div className="nt-block-post-content-grid">
                                    {articles.map((article, id) => (
                                        <div key={id} data-key={article.articleID} onClick={viewBlogItem} className="nt-block-post-item">
                                            <div className="nt-block-post-image">
                                                <div className="nt-block-post-image-inner">
                                                    <div className="block-post-image">
                                                        <a href="#!">
                                                            <img className="responsive-image" src={context.awsS3url + "/images/" + article.photo} alt="" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nt-block-post-content">
                                                {article.categories != null ? <div className="nt-block-post-category">{article.categories[0]}</div> : ""}
                                                <h4 className="entry-title">
                                                    <a href="#!">{article.title}</a>
                                                </h4>
                                                <div className="nt-block-post-top-meta">
                                                    <div className="nt-block-post-author">
                                                        <span className="author-avatar">
                                                            <img loading="lazy" className="responsive-image" src={"assets/images/blog/no_photo.jpg"} alt="No photo available" width="25" height="25" />
                                                        </span>
                                                        <span className="nt-blocks-author-pretext">By {" " + article.author}</span>
                                                    </div>
                                                    <div className="nt-block-meta-divider nt-block-meta-has-divider">|</div>
                                                    <div className="kt-blocks-date"><time className="nt-blocks-post-date">{article.dateAdded}</time></div>
                                                    {/* <div className="nt-block-meta-divider nt-block-meta-has-divider">|</div>
                                                    <div className="kt-blocks-views">
                                                        <span className="material-symbols-outlined kt-block-icon">visibility</span>
                                                        <span>{article.views}</span>
                                                    </div> */}
                                                    <div className="nt-block-meta-divider nt-block-meta-has-divider">|</div>
                                                    <div className="kt-blocks-comments">
                                                        <span className="material-symbols-outlined kt-block-icon">comment</span>
                                                        {article.comments != null ? <span>{article.comments.length}</span> : <span>{0}</span>}
                                                        {/* <span>Comments</span> */}
                                                    </div>
                                                </div>
                                                {article.content != null ? <div className="entry-content" dangerouslySetInnerHTML={{ __html: article.content.slice(0, 180) + "..." }} /> : ""}
                                            </div>
                                        </div>
                                    ))}
                                    <div  style={{visibility:otherViewsVisibility}} className="loadmore">
                                        <Link onClick={loadMore} className="btn btn--primary btn--alt">Load more</Link>
                                    </div>
                                </div>
                                <div style={{visibility:otherViewsVisibility}} className="nt-block-aside">
                                    <div className="nt-block-aside-ad">
                                        <h6>ADVERTISEMENT</h6>
                                        <div className="advert-block-250X300"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default BlogHome