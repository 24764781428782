import React from 'react'
import AddressCartItem from './address-cart-item'

const RightCard = (props) => {
    return (
        <div className="cardBoxy">
            <div className="checkoutHeader">
                <h4 className="h4">YOUR ORDER <span>(<span>{props.cart.cartItems.length}</span>)</span></h4>
            </div>
            <div className="checkoutAsideContent">
                {props.cart.cartItems.map((cartItem, index) => (
                    <AddressCartItem key={index} cartItem={cartItem} />
                ))}
                <div className="checkoutAsidePriceSummary">
                    <div className="checkoutAsidePriceBlock">
                        <div className="blockLabel">Subtotal</div>
                        <div className="blockPrice">Ksh. <span>{props.cart.amount}</span></div>
                    </div>
                    <div className="checkoutAsidePriceBlock">
                        <div className="blockLabel">DST</div>
                        <div className="blockPrice">Ksh. <span>{props.cart.tax}</span></div>
                    </div>
                    <div className="checkoutAsidePriceBlock">
                        <div className="blockLabel">Local delivery fees</div>
                        <div className="blockPrice">Ksh. <span>{props.cart.delivery}</span></div>
                    </div>
                </div>
                <div className="checkoutAsidePriceTotal">
                    <div className="blockLabel">Total</div>
                    <div className="blockPrice">Ksh. <span>{props.cart.amount}</span></div>
                </div>
            </div>
        </div>
    )
}

export default RightCard