import Cookies from "../components/cookies";
import Header from "../components/header";
import { MiniHeader } from "../components/header/mini-header";
import Loading from "../components/loading";
import ResetPasswordContent from "../components/resetPasswordContent";

const ResetPassword = () => {
    return (
        <div>
            <Loading />
            <div className="overlay-mobileMenu"></div>
            <Header login={true} />
            <ResetPasswordContent />
            <Cookies />
        </div>
    )
}

export default ResetPassword