import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from '../components/cookies';
import Footer from '../components/footer';
import Header from '../components/header';
import Loading from '../components/loading';
import Terms from '../components/terms';

export const TermsPage = (props) => {
  const { state } = useLocation();

  return (
    <div>
      <Loading/>
      <div className="overlay-mobileMenu"></div>
      <Header section="shop" />
      <Terms />
      <Footer />
      <Cookies/>
    </div>
  )
}

export default TermsPage