import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { BulletList } from 'react-content-loader'
import { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';
import { ProductItem } from './product-item';


export const ProductsView = () => {
    const context = useContext(ShopContext);
    const { state } = useLocation()
    
    const fetchProductsByType = (e) => {
        var key = e.currentTarget.getAttribute("data-key")
        context.fetchByItemType(key)
    }
    const fetchByCategory = (e) => {
        var key = e.currentTarget.getAttribute("data-key")
        context.fetchProductByCategory(key)
    }

    useEffect(() => {
        var filter = "";
        if (state != null) {
            if ("itemType" in state) {
                context.fetchByItemType(state.itemType)
                filter = state.itemType
            }
            if ("category" in state) {
                // setCategory(state.category)
                context.fetchProductByCategory(state.category)
                filter = state.category
            }else if ((context.products.length == 0) && (filter=="")) {
                context.fetchProducts()
            }
        }else{
            if ((context.products.length == 0) && (filter=="")) {
                context.fetchProducts()
            }
        }
    }, [state])

    return (
        <div id="productCategoryPage">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="productCategory">
                <div className="container">
                    <div className="productCategoryGrid">
                        <div className="productCategoryFilter">
                            <div className="productfilterCard cardBoxy">
                                <div className="filterCardHeading">
                                    <h3 className="h3">Product Categories</h3>
                                </div>
                                <div className="filterCardList">
                                    <div className="filterCardText">In This Section</div>
                                    <ul className="unstyled">
                                        {context.categories.map((category) => (
                                            <li key={category}>
                                                <a href="#" data-key={category} onClick={fetchProductsByType}>
                                                    <span>{category}</span>
                                                    <span className="material-symbols-outlined small-icon">navigate_next</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="productfilterCard cardBoxy">
                                <div className="filterCardHeading">
                                    <h3 className="h3">Platforms</h3>
                                </div>
                                <div className="filterCardList">
                                    <div className="filterCardText">Supported Platforms</div>
                                    <ul className="unstyled">
                                        {context.platforms.map((platform) => (
                                            <li>
                                                <a href="#">
                                                    <span>{platform}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="productfilterCard cardBoxy">
                                <div className="filterCardHeading">
                                    <h3 className="h3">Deals</h3>
                                </div>
                                <div className="filterCardList">
                                    <ul className="unstyled">
                                        <li>
                                            <a href="#"  data-key="recent" onClick={fetchByCategory}>
                                                <span>Recent Releases</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#"  data-key="sale" onClick={fetchByCategory}>
                                                <span>Sale</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#"  data-key="deal" onClick={fetchByCategory}>
                                                <span>Offers</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#"  data-key="trending" onClick={fetchByCategory}>
                                                <span>Trending</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#"  data-key="upcoming" onClick={fetchByCategory}>
                                                <span>Upcoming</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#"  data-key="exclusive" onClick={fetchByCategory}>
                                                <span>Exclusive</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="productCategoryMain">
                            <div className="cardContainer">
                                <div className="cardBoxy">
                                    <div className="productCategoryTop">
                                        <div className="productCategoryHeading">
                                            <h1 className="h1">PRODUCTS({context.productsCount})</h1>
                                            <p className="productCategoryPara">Our products include: gamecards, giftcards, electronics, merchandise, equipment etc</p>
                                        </div>
                                        {/* <div className="productCategoryPaginationTop">
                                            <span>Showing <span className="w-600">{count}</span> products</span>
                                        </div> */}
                                    </div>
                                    <div className="productCategoryCenter">
                                        <div className="productCategoryCenterMain">
                                            {context.loading ? <BulletList foregroundColor="rgb(205, 205, 205)" /> :
                                                <div className="productCategoryList">

                                                    {context.products.length != 0 ? context.products.map((item) => (
                                                        item.variants.map((variant, key) => (
                                                            <ProductItem key={key} product={item} variant={variant} />
                                                        ))
                                                    ))
                                                        : "No products available"
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductsView