import React, { useState } from 'react';
import Header from "../components/header";
import Footer from '../components/footer';
import Services from '../components/service/services';
import Cookies from '../components/cookies';
import Loading from '../components/loading';

const ServicesViewPage =(props)=> {
    return (
      <div>
        <Loading/>
      <div className="overlay-mobileMenu"></div>
        <Header section="shop"/>
        <Services />
        <Footer />
        <Cookies/>
      </div>
    )
}

export default ServicesViewPage