import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import shopContext from "../../context/shop-context";

export const ProductItemResult = (props) => {
    const context = useContext(shopContext);
    const [buttonCaption,setButtonCaption] = useState("ADD TO CART")
   
    const addToCart = () => {
        if(document.getElementById("btnAddToCart").classList.contains("btn--secondary")){
            toast.error("item in cart");
            return;
        }
        toast.success('Adding item to cart')
        context.addItem(props.product.variantID, "product", "1")
    }

    useEffect(()=>{
        context.itemInCart(context.cart,props.product.variantID,"product").then((val)=>{
            // setInCart(val)
            if(val){
                setButtonCaption("IN CART")
                if(document.getElementById("btnAddToCart").classList.contains("btn--primary")){
                    document.getElementById("btnAddToCart").classList.remove("btn--primary")
                }
                document.getElementById("btnAddToCart").classList.add("btn--secondary")
            }
        })
    },[])
    return (
        <div className="cardBoxy">
            <div className="searchListItemGrid">
                <div className="searchListItemImage">
                {props.product.image!="" && props.product.image!=undefined?<img src={context.awsS3url+"/images/"+props.product.image} alt="" />:
                    <img className="responsive-image"  src={"assets/images/eject.png"} alt="" />}
                </div>
                <div className="searchListItemContent">
                    <div className="searchListItemTitle">
                        <a href="#!">
                            <div className="sm-title">{props.product.name}</div>
                        </a>
                    </div>
                    <div className="searchListItemDetails">
                        <div className="listItemDetailsPrimary">
                            <div className="meta-price">Ksh {props.product.salePrice}</div>
                        </div>
                        <div className="listItemDetailsPrimary">
                            <button id="btnAddToCart" className="btn btn--primary" onClick={addToCart}>Add to cart</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}