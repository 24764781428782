import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ShopContext from '../../context/shop-context';
import * as utils from '../../context/utils';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
  
export const StreamHome = () => {
    const context = useContext(ShopContext);
    const [page, setPage] = useState(0)
    const navigate = useNavigate();
    const { state } = useLocation()
    const [articles, setArticles] = useState([]);
    const [mainArticles, setMainArticles] = useState([]);
    const [otherViewsVisibility, setOtherViewsVisibility] = useState("hidden");
    const [otherArticles, setOtherArticles] = useState([]);

    const loadMore = () => {
        var newPage = page + 1
        setPage(newPage)
        context.fetchArticlesSectionWithPromise("other", newPage, context.baseUrl).then((resp) => {
            if (resp.message.length > 0) {
                setArticles(resp.message)
                setOtherViewsVisibility("visible")
            } else {
                setPage(0)
                navigate("/articles")
            }
        })
        context.fetchArticlesSectionWithPromise("highlight", newPage, context.baseUrl).then((resp) => {
            var mainArticles = [];
            if (resp.message.length > 0) {
                mainArticles.push(resp.message[0])
                setMainArticles(mainArticles)
                var offset = (resp.message.length - 1) * -1
                var others = resp.message.slice(offset)
                setOtherArticles(others.slice(0, 2))
                setOtherViewsVisibility("visible")
            } else {
                setPage(0)
                navigate("/articles")
            }
        })

        context.scrollToTop()
    }

    const viewBlogItem = (e) => {
        var key = e.currentTarget.getAttribute("data-key")
        navigate("/article", { state: { "article": key } })
        context.scrollToTop()
    }

    useEffect(() => {
        loadMore()
    }, [state])

    return (
        <div id="streaming">
            <div className="streamFauxy">
                <div className="container">
                    <div className="streamFauxyMenu">
                        <ul className="streamFauxyMenuList">
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink active">Home</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">Live</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">EPG</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">Movies</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">Series</a>
                            </li>
                        </ul>
                    </div>
                    <div className="streamFauxyGrid">
                        {/* <div className="streamFauxyAside">
                            <div className="streamFauxyPlaylist">
                                <h4>Playlist Xtream</h4>
                            </div>
                        </div> */}
                        <div className="streamFauxyContent">
                            <div id="watching">
                                <div className="streamFauxyContentHeading">
                                    <div className="headingTitle">
                                        <h2>My Playlist</h2>
                                        <p>You can perfom actions such as <strong>Edit</strong>, <strong>delete</strong> and <strong>share</strong> by click view more</p>
                                    </div>
                                    <div className="headingLink">
                                        <a href="#" className="link">view more</a>
                                    </div>
                                </div>
                                <OwlCarousel id="streamSlider01" {...utils.streamSlider01} className="owl-carousel">
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/the_terminal_list.jpg"} alt="" />
                                                    <div className="streamFauxyContentItemProgress">
                                                        <div className="progressBar">
                                                            <div className="progress" style={{width: "20%"}}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Terminal List</h4>
                                                    <div className="streamFauxyContentItemDesc">
                                                        <span className="text-uppercase">Continue</span>
                                                        <span className="hyphen-divider">&#8211;</span>
                                                        <span>S11, E01</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/reacher.jpg"} alt="" />
                                                    <div className="streamFauxyContentItemProgress">
                                                        <div className="progressBar">
                                                            <div className="progress" style={{width: "10%"}}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Reacher</h4>
                                                    <div className="streamFauxyContentItemDesc">
                                                        <span className="text-uppercase">Continue</span>
                                                        <span className="hyphen-divider">&#8211;</span>
                                                        <span>S3, E04</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/vikings.jpg"} alt="" />
                                                    <div className="streamFauxyContentItemProgress">
                                                        <div className="progressBar">
                                                            <div className="progress" style={{width: "30%"}}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Vikings: Valhalla</h4>
                                                    <div className="streamFauxyContentItemDesc">
                                                        <span className="text-uppercase">Continue</span>
                                                        <span className="hyphen-divider">&#8211;</span>
                                                        <span>S11, E01</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/kungfu-panda.jpg"} alt="" />
                                                    <div className="streamFauxyContentItemProgress">
                                                        <div className="progressBar">
                                                            <div className="progress" style={{width: "50%"}}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Kung Fu Panda: The Dragon Knight</h4>
                                                    <div className="streamFauxyContentItemDesc">
                                                        <span className="text-uppercase">Continue</span>
                                                        <span className="hyphen-divider">&#8211;</span>
                                                        <span>S11, E01</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/1899.jpg"} alt="" />
                                                    <div className="streamFauxyContentItemProgress">
                                                        <div className="progressBar">
                                                            <div className="progress" style={{width: "20%"}}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>1899</h4>
                                                    <div className="streamFauxyContentItemDesc">
                                                        <span className="text-uppercase">Continue</span>
                                                        <span className="hyphen-divider">&#8211;</span>
                                                        <span>S11, E01</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                            <div id="channels">
                                <div className="streamFauxyContentHeading">
                                    <div className="headingTitle">
                                        <h2>Recent watched channels</h2>
                                    </div>
                                    <div className="headingLink">
                                        <a href="#" className="link">See All</a>
                                    </div>
                                </div>
                                <OwlCarousel id="streamSlider02"  {...utils.streamSlider02} className="owl-carousel">
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/espn-logo.png"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>ESPN UK</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/og-fox-news.png"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>FOX News</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/super-sports.png"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>super sport premier league</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/cnn-international.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>CNN International</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                            <div id="series">
                                <div className="streamFauxyContentHeading">
                                    <div className="headingTitle">
                                        <h2>Series recently added</h2>
                                    </div>
                                    <div className="headingLink">
                                        <a href="#" className="link">See All</a>
                                    </div>
                                </div>
                                <OwlCarousel id="streamSlider03"  {...utils.streamSlider03} className="owl-carousel">
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/legend_vox.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Legend of Vox Machina</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/daniel_spellbound.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Daniel Spellbound</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/neymar.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Neymar: The Perfect Chaos</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/bosch_legacy.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Bosch: Legacy</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/resident_evil.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Resident Evil</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <img src={"assets/images/stream/in_from_the_cold.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>In From the Cold</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StreamHome