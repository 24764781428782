import Cookies from "../components/cookies";
import Header from "../components/header";
import Loading from "../components/loading";
import RegisterContent from "../components/registerContent";

const Register = (props) => {
    return (
        <div>
            <Loading />
            <div className="overlay-mobileMenu"></div>
            <Header login={false} />
            <RegisterContent />
            <Cookies />
        </div>
    )
}

export default Register