import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ShopContext from "../../context/shop-context";
import VariantItem from "./variant-item"

export const ServiceItem = (props) => {
    const context = useContext(ShopContext);

  
    const[activeVariant,setActiveVariant]=useState(0)
    const toggleActiveVariant=(id)=>{
        if(id==activeVariant){
            console.log("Unselect, remove item")
            context.removeItem(activeVariant,"service")
        }else{
            if(activeVariant!=0){
                //remove previously selected
                context.removeItem(activeVariant,"service")
            }
            setActiveVariant(id)
            context.addItem(id,"service",1)
        }
    }
  
    return (
        <div className="subscriptionsItem">
            <div className="cardBoxy">
                <div className="subscriptionsBoxy">
                    <div className="subscriptionsTop">
                        <div className="subscriptionsLogo">
                            <img className="responsive-image" src={props.service.image==""?"assets/images/eject.png":context.awsS3url+"/images/"+props.service.image} alt="Service Logo" />
                        </div>
                        <div className="subscriptionsHeading">
                            <h2>{props.service.name}</h2>
                            <p>{props.service.description}</p>
                        </div>
                    </div>
                    <div className="subscriptionsBottom">
                        <div className="subscriptionsGrid">
                            {props.service.variants.map((variant,index)=>(
                                <VariantItem  toggleActiveVariant={toggleActiveVariant} active={activeVariant} variant={variant} key={index}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}