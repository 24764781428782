import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
// import { List } from 'react-content-loader';
import { Facebook } from 'react-content-loader'

import { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import ShopContext from '../../context/shop-context';
import { ServiceItem } from './service-item';


export const Services = () => {
    const context = useContext(ShopContext);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [activeMenu, setActiveMenu] = useState("")
    const fetchServicesByCategory = (e) => {
        var key = e.currentTarget.getAttribute("data-key")
        document.getElementById(activeMenu).classList.remove("active");
        setActiveMenu(key)
        document.getElementById(key).classList.add("active");
        if(key=="all"){
            context.fetchServices()
            return
        }
        navigate("/services", { state: { "category": key } })
    }
    useEffect(() => {
        var filter = "";
        if (state != null) {
            if ("category" in state) {
                context.fetchServicesByCategory(state.category)
                filter = state.category
                document.getElementById(filter).classList.add("active");
                setActiveMenu(filter)
            }else if ((context.services.length == 0) && (filter == "")) {
                context.fetchServices()
                setActiveMenu("all")
                document.getElementById("all").classList.add("active");
            }
        } 
        else {
            if ((context.services.length == 0) && (filter == "")) {
                context.fetchServices()
                setActiveMenu("all")
                document.getElementById("all").classList.add("active");
            }
        }
    }, [state])

    return (
        <div id="subscriptions">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="subscriptionsFauxy">
                <div className="container">
                    <div className="subscriptionsWrapper">
                        <div className="subscriptionsContent">
                            {/* <div className="subscriptionsMainBanner">
                                <img src="https://picsum.photos/1200/500" alt="" />
                            </div> */}
                            <div className="subscriptionsFilter">
                                <div className="subscriptionsFilterTags">
                                    <span className="subscriptionsFilterTag" id="all" data-key="all" onClick={fetchServicesByCategory}>All</span>
                                    <span className="subscriptionsFilterTag" id="music" data-key="music" onClick={fetchServicesByCategory}>Music</span>
                                    <span className="subscriptionsFilterTag" id="movies" data-key="movies" onClick={fetchServicesByCategory}>Movies & Series</span>
                                    <span className="subscriptionsFilterTag" id="livesports" data-key="livesports" onClick={fetchServicesByCategory}>Live Sports</span>
                                    <span className="subscriptionsFilterTag" id="anime" data-key="anime" onClick={fetchServicesByCategory}>Anime</span>
                                </div>
                            </div>
                            <div className="subscriptionsList">
                                {context.loading ?
                                <Facebook foregroundColor='rgb(205, 205, 205)'/> :
                                    context.services.length != 0 ? context.services.map((service, index) => (
                                        <ServiceItem service={service} key={index} />
                                    )) : <div><p>0 items found</p><p>Load More?<a href="/services"> click here</a></p></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Services