import React, { useContext, useEffect, useState } from 'react'
import ShopContext from '../../context/shop-context'

const VariantItem = (props) => {
    const context = useContext(ShopContext)
    const [inCart,setInCart]=useState(false)
    useEffect(()=>{
        context.itemInCart(context.cart,props.variant.subscriptionItemID,"service").then((val)=>{
            setInCart(val)
        })
    },[])

    const toggleActive = () => {
        if(inCart){
            context.removeItem(props.variant.subscriptionItemID,"service")
            setInCart(false)   
        }else{
            context.addItem(props.variant.subscriptionItemID,"service",1)   
        }
        props.toggleActiveVariant(props.variant.subscriptionItemID)
    }

    return (
        <div className="subscriptionsGridItem">
            <div className="subscriptionsGridBox">
                <div className="subscriptionsBoxTop">
                    <div className="duration">{props.variant.period}</div>
                </div>
                <div className="subscriptionsBoxCenter">
                    <small className="currency">KSH</small>
                    <div className="price">{props.variant.price}</div>
                </div>
                <div className="subscriptionsBoxLink">
                    {inCart ?
                        <button className="btn btn--black btn--alt active" onClick={toggleActive}>SELECTED</button>
                        :
                        <button className="btn btn--black btn--alt" onClick={toggleActive}>SELECT PLAN</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default VariantItem;