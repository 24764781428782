import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from '../components/cookies';
import Footer from '../components/footer';
import Header from '../components/header';
import Loading from '../components/loading';
import NotFound from '../components/notFound';
import ShopContext from '../context/shop-context';

export const NotFoundPage = () => {
  const { state } = useLocation();
  const context = useContext(ShopContext)

  return (
    <div>
      <Loading/>
      <div className="overlay-mobileMenu"></div>
      <Header section="shop" />
      <NotFound />
      <Footer />
      <Cookies/>
    </div>
  )
}

export default NotFoundPage