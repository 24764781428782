import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ShopContext from '../../context/shop-context';
import { ProductItem } from './product-item';


export const Product = () => {
    const context = useContext(ShopContext);
    const [variantID, setVariantID] = useState(-1)

    const { state } = useLocation();
    const navigate = useNavigate()
    const [inCart, setInCart] = useState(false)
    const [buttonCaption, setButtonCaption] = useState("ADD TO CART")
    const { itemVariantID,itemProductID } = useParams()

    const addToCart = () => {
        if (document.getElementById("btnAddToCart").classList.contains("btn--secondary")) {
            toast.error("item in cart");
            return;
        }
        toast.success('Adding item to cart')
        context.addItem(variantID, "product", "1")
    }

    useEffect(() => {
        if (state != null) {            
            if ("productID" in state) {
                context.fetchProduct(state.productID)
            }
            if ("variantID" in state) {
                setVariantID(state.variantID)
                context.fetchVariant(state.variantID)
                context.fetchRecommend(state.variantID)
                context.itemInCart(context.cart, state.variantID, "product").then((val) => {
                    setInCart(val)
                    if (val) {
                        setButtonCaption("IN CART")
                        if (document.getElementById("btnAddToCart").classList.contains("btn--primary")) {
                            document.getElementById("btnAddToCart").classList.remove("btn--primary")
                        }
                        document.getElementById("btnAddToCart").classList.add("btn--secondary")
                    }
                })
            }
        }
        if (itemProductID != undefined) {
            context.fetchProduct(itemProductID)
        }
        if (itemVariantID != undefined) {
            setVariantID(itemVariantID)
            context.fetchVariant(itemVariantID)
            context.fetchRecommend(itemVariantID)
            context.itemInCart(context.cart, itemVariantID, "product").then((val) => {
                setInCart(val)
                if (val) {
                    setButtonCaption("IN CART")
                    if (document.getElementById("btnAddToCart").classList.contains("btn--primary")) {
                        document.getElementById("btnAddToCart").classList.remove("btn--primary")
                    }
                    document.getElementById("btnAddToCart").classList.add("btn--secondary")
                }
            })
        }

    }, [state])

    return (
        <div id="productPage">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="productSingle">
                <div className="container">
                    <div className="productSingleHeading">
                        <div className="h1">{context.product.name}({context.variant.name})</div>
                    </div>
                    <div className="productSingleWrapper">
                        <div className="productSingleGrid">
                            <div className="productSingleContent">
                                <div className="productSinglePreview cardBoxy">
                                    <div className="productPreview">
                                        <div id="productGalleryPreview" >
                                            <div className="item">
                                                {context.variant.image == "" || context.variant.image == undefined ? context.product.imageUrl != "" && context.product.imageUrl != undefined ? <img className="responsive-image" src={context.awsS3url + "/images/" + context.product.imageUrl} alt="" /> :
                                                    <img className="responsive-image" src={"assets/images/eject.png"} alt="" /> : <img className="responsive-image" src={context.awsS3url + "/images/" + context.variant.image} alt="" />}
                                            </div>
                                        </div>
                                        {/* <div id="productGalleryPreview" className="owl-carousel">
                                            
                                            <div className="item">
                                                <img src={"assets/images/products/preview_2.png"} alt="" />
                                            </div>
                                            <div className="item">
                                                <img src={"assets/images/products/preview_2.png"} alt="" />
                                            </div>
                                            <div className="item">
                                                <img src={"assets/images/products/preview_1.png"} alt="" />
                                            </div>
                                            <div className="item">
                                                <img src={"assets/images/products/preview_2.png"} alt="" />
                                            </div>
                                        </div>
                                        <div id="productGalleryThumb" className="owl-carousel">
                                            <div className="item">
                                                <img src={"assets/images/products/preview_thumb_1.png"} alt="" />
                                            </div>
                                            <div className="item">
                                                <img src={"assets/images/products/preview_thumb_2.png"} alt="" />
                                            </div>
                                            <div className="item">
                                                <img src={"assets/images/products/preview_thumb_3.png"} alt="" />
                                            </div>
                                            <div className="item">
                                                <img src={"assets/images/products/preview_thumb_1.png"} alt="" />
                                            </div>
                                            <div className="item">
                                                <img src={"assets/images/products/preview_thumb_2.png"} alt="" />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="productContent">
                                        <div className="productContentTop">
                                            <h4 className="h4">{context.product.name}({context.variant.name}) - ({context.product.itemType})</h4>
                                            <p className="para">{context.product.summary}</p>
                                            {!context.variant.inStock ? <div className="productOutOfStock">
                                                <span className="dot"></span>
                                                <span className="renderText">Out of stock</span>
                                            </div> : ""}
                                        </div>
                                        <div className="productContentCenter">
                                            <div className="productprice">KES {context.variant.salePrice}</div>
                                        </div>
                                        <div className="productContentBottom">
                                            <button type="button" id="btnAddToCart" onClick={addToCart} className="btn btn--primary">
                                                <span className="material-symbols-outlined cart-icon">shopping_cart</span>
                                                <span className="text-uppercase">{buttonCaption}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="productSingleDetails cardBoxy">
                                    <div className="productSingleHeader">
                                        <h4 className="h4">Product details</h4>
                                    </div>
                                    <div className="productSingleContent">
                                        <p>Rating: {context.product.rating}</p>
                                        <p>Category: {context.product.category}</p>
                                        {context.variant != {} ? <p>Region: {context.variant.region}</p> : ""}
                                        {context.variant != {} ? <p>Platform: {context.variant.platform}</p> : ""}
                                    </div>
                                </div>
                                <div className="productSingleYouLike cardBoxy">
                                    <div className="productCategoryMain">
                                        <div className="cardContainer">
                                            <div className="cardBoxy">
                                                <div className="productSingleDetails">
                                                    <div className="productSingleHeader">
                                                        <h4 className="h4">You may also like</h4>
                                                    </div>
                                                </div>
                                                <div className="productCategoryCenter">
                                                    <div className="productCategoryCenterMain">
                                                        <div className="productCategoryList">
                                                            {context.recommendation.length != 0 ?
                                                                context.recommendation.map((product) => (
                                                                    product.variants.map((variant) => (
                                                                        <ProductItem baseUrl={context.baseUrl} product={product} variant={variant} />
                                                                    ))
                                                                ))
                                                                : "No recommendations available."}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="productSingleAside">
                                <div className="productSingleDeliver cardBoxy">
                                    <div className="productSingleHeader">
                                        <h4 className="h4">DELIVERY & RETURNS</h4>
                                    </div>
                                    <div className="productSingleContent">
                                        <div className="productSingleSubheader">Choose your location</div>
                                        <div className="productSingleForm">
                                            <div className="productSingleFormSelect">
                                                <div className="selectWrapper">
                                                    <select className="selectBox form-control">
                                                        <option selected>Nairobi</option>
                                                        <option>Mombasa</option>
                                                        <option>Kisumu</option>
                                                        <option>Nyeri</option>
                                                        <option>Lodwar</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="productSingleFormSelect">
                                                <div className="selectWrapper">
                                                    <select className="selectBox form-control">
                                                        <option>Starehe</option>
                                                        <option>Mombasa</option>
                                                        <option>Kisumu</option>
                                                        <option>Nyeri</option>
                                                        <option>Lodwar</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="productSinglePolicy">
                                            <div className="productPolicyList">
                                                <div className="productPolicyItem">
                                                    <div className="productPolicyIcon">
                                                        <span className="material-symbols-outlined">inventory_2</span>
                                                    </div>
                                                    <div className="productPolicyContent">
                                                        <div className="productPolicyHeader">Return Policy</div>
                                                        <div className="productPolicyDescript">Easy Return, Quick Refund. <Link
                                                            to="/terms">See more</Link></div>
                                                    </div>
                                                </div>
                                                <div className="productPolicyItem">
                                                    <div className="productPolicyIcon">
                                                        <span className="material-symbols-outlined">verified_user</span>
                                                    </div>
                                                    <div className="productPolicyContent">
                                                        <div className="productPolicyHeader">Warranty</div>
                                                        <div className="productPolicyDescript">6 Months</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="productSingleChat cardBoxy">
                                    <span className="chatBoxTitle">Questions about this product?</span>
                                    <a href="https://ticket.nitrade.pro/newissue" className="chatBoxLink">
                                        <span className="material-symbols-outlined chatBoxIcon">forum</span>
                                        <span className="chatBoxText text-uppercase">CHAT</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Product