

import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../context/shop-context';

const Loading = (props) => {
    const [ready, setReady] = useState(false)
    const context = useContext(ShopContext);
    useEffect(() => {
        document.onreadystatechange = () => {
            // console.log("State:", document.readyState)
            if (document.readyState == "complete") {
                setReady(true);
            }
        };
    }, [props])
    return (
        <div>
            {!ready ?
                context.loading ?
                    <div className="loading" title="loading">
                        <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            width="40px" height="40px" viewBox="0 0 50 50" xmlSpace="preserve">
                            <path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                                <animateTransform attributeType="xml"
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 25 25"
                                    to="360 25 25"
                                    dur="0.6s"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                        <span className="loading__text">Loading...</span>
                    </div>
                    : ""
                : ""
            }
        </div>
    )
}

export default Loading