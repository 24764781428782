import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';
import DashboardAside from './dashboard-aside';


export const AccountPreferences = () => {
    const context = useContext(ShopContext);
    const [values, setValues] = useState({
        product: "",
        service: "",
        coaching: "",
        event: "",
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const subscribeBlog = () => {
        if ((values.category == "") || (values.email = "")) {
            toast.error("Email missing.")
            return
        }
        context.subscribe(values.email, values.category)
    }

    useEffect(() => {
        context.fetchUser(localStorage.getItem("userID"))
        context.fetchPrimaryAddress(localStorage.getItem("userID"))
    }, [])

    return (
        <main id="content">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="form-pagey bg-gray v-h">
                <div className="preference__container">
                    <div className="preference__content">
                        <h1 className="preference__heading">Select your preferences</h1>
                        <form>
                            <div className="preference__grid">
                                <div className="preference__item">
                                    <div className="preference__card active">
                                        <div className="preference__button">
                                            <input name="product" onChange={onChange} type="checkbox" />
                                            <div className="preference__button__checkbox checkBox"></div>
                                        </div>
                                        <div className="preference__title">Products</div>
                                        <div className="preference__desc">Includes: gamecard, giftcard, merchandise, devices, games & equipment.</div>
                                    </div>
                                </div>
                                <div className="preference__item">
                                    <div className="preference__card active">
                                        <div className="preference__button">
                                            <input  name="service" onChange={onChange} type="checkbox" />
                                            <div className="preference__button__checkbox checkBox"></div>
                                        </div>
                                        <div className="preference__title">Services</div>
                                        <div className="preference__desc">Get updates on offers and new streaming service.</div>
                                    </div>
                                </div>
                                <div className="preference__item">
                                    <div className="preference__card active">
                                        <div className="preference__button">
                                            <input  name="event" onChange={onChange} type="checkbox" />
                                            <div className="preference__button__checkbox checkBox"></div>
                                        </div>
                                        <div className="preference__title">Events</div>
                                        <div className="preference__desc">We organize e-Sports tornaments, select this if you want to be notified.</div>
                                    </div>
                                </div>
                                <div className="preference__item">
                                    <div className="preference__card active">
                                        <div className="preference__button">
                                            <input name="coaching" onChange={onChange} type="checkbox" />
                                            <div className="preference__button__checkbox checkBox"></div>
                                        </div>
                                        <div className="preference__title">Coaching</div>
                                        <div className="preference__desc">Are you looking for a coach to perfect your gaming(fortnite, COD etc) skills?</div>
                                    </div>
                                </div>
                            </div>
                            <div className="preference__buttons">
                                <button className="btn btn--primary w-full btn--large">Continue</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default AccountPreferences