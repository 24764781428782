import axios from 'axios';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ShopContext from '../../context/shop-context';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import * as utils from '../../context/utils';

export const StreamMovie = () => {
    const context = useContext(ShopContext);
    const [page, setPage] = useState(0)
    const navigate = useNavigate();
    const { state } = useLocation()
   
    useEffect(() => {
        // loadMore()
    }, [state])

    return (
        <div id="streaming">
        <div className="streamFauxy">
            <div className="container">
                <div className="streamFauxyBreadcreumb">
                    <div id="bread">
                        <ul className="breadcrumb">
                            <li>
                                <a href="stream-home.html">Home</a>
                            </li>
                            <li>
                                <a href="stream-category-movies.html">Movies</a>
                            </li>
                            <li>
                                <a href="#">Speed 1994</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="streamFauxyPlayerGridFull">
                    <div className="streamFauxyPlayerVideo">
                        <div id="playerArea">
                            <video
                                id="media-player"
                                className="video-js"
                                controls
                                preload="auto"
                                width="640"
                                height="264"
                                poster="MY_VIDEO_POSTER.jpg"
                                data-setup="{}"
                            >
                                <source src="/videos/mortal_kombat.mp4" type="video/mp4" />
                                <source src="/videos/mortal_kombat.webm" type="video/webm" />
                                <p className="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a
                                web browser that
                                <a href="https://videojs.com/html5-video-support/" target="_blank"
                                    >supports HTML5 video</a
                                >
                                </p>
                            </video>
                        </div>
                    </div>
                </div>
                <div id="mvicInfo">
                    <div className="mvicContent">
                        <div className="mvicDesc">
                            <div className="mvicStats">
                                <div className="blockFav" id="btnFavorite">
                                    <a href="#" className="btnFavorite" title="Add to favorite">
                                        <span className="material-symbols-outlined mvicIcon">favorite</span>
                                        <span>Favorite</span>
                                    </a>
                                </div>
                                <div className="blockView">
                                    <span className="material-symbols-outlined mvicIcon">visibility</span>
                                    <span>14809 views</span>
                                </div>
                                <div className="blockTrailer">
                                    <a href="#">
                                        <span className="material-symbols-outlined mvicIcon">videocam</span>
                                        <span>Watch trailer</span>
                                    </a>
                                </div>
                            </div>
                            <div className="detailMod">
                                <div className="dmThumb"></div>
                                <h1>Speed 1994</h1>
                            </div>
                            <div className="movieDesc">LAPD SWAT bomb disposal officers Jack Traven and Harry Temple thwart an attempt to hold an elevator full of people for a $3 million ransom by an extortionist bomber, later identified as Howard Payne. As they corner Payne, he takes Harry hostage. Jack intentionally shoots Harry in the leg, forcing the bomber to release him. Payne flees and detonates the bomb, seemingly dying. Jack and Harry are praised by Lieutenant "Mac" McMahon, with Harry being promoted. Having survived the incident, however, Payne watches from afar. The next morning, Jack witnesses a mass transit bus explode from a bomb planted by Payne, who contacts Jack on a nearby payphone, explaining that a similar bomb is rigged on another bus, which will activate once it reaches 50 miles per hour (80 km/h) and detonate if it drops below 50.</div>
                            <div className="mvicInfoDetails">
                                <div className="mviciLeft">
                                    <p>
                                        <strong>Genre:</strong>
                                        <a href="#" title="Drama">Drama</a>
                                    </p>
                                    <p>
                                        <strong>Actor:</strong>
                                        <a href="#" title="Keanu Reeves">Keanu Reeves</a>, 
                                        <a href="#" title="Dennis Hopper">Dennis Hopper</a>, 
                                        <a href="#" title="Sandra Bullock">Sandra Bullock</a>,
                                        <a href="#" title="Joe Morton">Joe Morton</a>,
                                        <a href="#" title="Jeff Daniels">Jeff Daniels</a>
                                    </p>
                                    <p>
                                        <strong>Director:</strong>
                                        Jan de Bont
                                    </p>
                                    <p>
                                        <strong>Country:</strong>
                                        <a href="#" title="United States">United States</a>
                                    </p>
                                </div>
                                <div className="mviciRight">
                                    <p>
                                        <strong>Duration:</strong> 1h 56min
                                    </p>
                                    <p>
                                        <strong>Quality:</strong> 
                                        <span className="quality">HD</span>
                                    </p>
                                    <p>
                                        <strong>Release:</strong> 
                                        <a href="#">2023</a>
                                    </p>
                                    <p>
                                        <strong>IMDb:</strong> 5.1/10
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="mvicLike" className="mvicLike">
                    <div className="streamFauxyContentHeading">
                        <div className="headingTitle">
                            <h2>You May Also Like</h2>
                        </div>
                        <div className="headingLink">
                            <a href="#" className="link">See All</a>
                        </div>
                    </div>
                    <OwlCarousel id="streamSlider03" {...utils.streamSlider03} className="owl-carousel">
                        <div className="item">
                            <div className="streamFauxyContentItem">
                                <a href="#">
                                    <div className="streamFauxyContentItemImage">
                                        <img src={"assets/images/stream/legend_vox.jpg"} alt="" />
                                    </div>
                                    <div className="streamFauxyContentItemText">
                                        <h4>The Legend of Vox Machina</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="streamFauxyContentItem">
                                <a href="#">
                                    <div className="streamFauxyContentItemImage">
                                        <div className="streamFauxyContentItemPlay">
                                            <div className="streamFauxyContentItemPlayWrapper">
                                                <span className="material-symbols-outlined play-icon">play_arrow</span>
                                            </div>
                                        </div>
                                        <img src={"assets/images/stream/dark_knight.jpg"} alt="" />
                                    </div>
                                    <div className="streamFauxyContentItemText">
                                        <h4>The Dark Knight</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="streamFauxyContentItem">
                                <a href="#">
                                    <div className="streamFauxyContentItemImage">
                                        <div className="streamFauxyContentItemPlay">
                                            <div className="streamFauxyContentItemPlayWrapper">
                                                <span className="material-symbols-outlined play-icon">play_arrow</span>
                                            </div>
                                        </div>
                                        <img src={"assets/images/stream/aliens.jpg"} alt="" />
                                    </div>
                                    <div className="streamFauxyContentItemText">
                                        <h4>Alien</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="streamFauxyContentItem">
                                <a href="#">
                                    <div className="streamFauxyContentItemImage">
                                        <div className="streamFauxyContentItemPlay">
                                            <div className="streamFauxyContentItemPlayWrapper">
                                                <span className="material-symbols-outlined play-icon">play_arrow</span>
                                            </div>
                                        </div>
                                        <img src={"assets/images/stream/jaws.jpg"} alt="" />
                                    </div>
                                    <div className="streamFauxyContentItemText">
                                        <h4>Jaws</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="streamFauxyContentItem">
                                <a href="#">
                                    <div className="streamFauxyContentItemImage">
                                        <div className="streamFauxyContentItemPlay">
                                            <div className="streamFauxyContentItemPlayWrapper">
                                                <span className="material-symbols-outlined play-icon">play_arrow</span>
                                            </div>
                                        </div>
                                        <img src={"assets/images/stream/inception.jpg"} alt="" />
                                    </div>
                                    <div className="streamFauxyContentItemText">
                                        <h4>Inception</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="streamFauxyContentItem">
                                <a href="#">
                                    <div className="streamFauxyContentItemImage">
                                        <div className="streamFauxyContentItemPlay">
                                            <div className="streamFauxyContentItemPlayWrapper">
                                                <span className="material-symbols-outlined play-icon">play_arrow</span>
                                            </div>
                                        </div>
                                        <img src={"assets/images/stream/pulp_fiction.jpg"} alt="" />
                                    </div>
                                    <div className="streamFauxyContentItemText">
                                        <h4>Pulp Fiction</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="streamFauxyContentItem">
                                <a href="#">
                                    <div className="streamFauxyContentItemImage">
                                        <div className="streamFauxyContentItemPlay">
                                            <div className="streamFauxyContentItemPlayWrapper">
                                                <span className="material-symbols-outlined play-icon">play_arrow</span>
                                            </div>
                                        </div>
                                        <img src={"assets/images/stream/heat.jpg"} alt="" />
                                    </div>
                                    <div className="streamFauxyContentItemText">
                                        <h4>Heat</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
       </div>
    </div>
    )
}

export default StreamMovie