import React, { useContext } from 'react'
import ShopContext from '../../../context/shop-context'

const SubscriptionItem = (props) => {
    const context = useContext(ShopContext)
    return (
        <div className="tabsBodyItem">
            <div className="tabsBodyImage">
                <img src={context.awsS3url + "/images/" + props.subscription.image} alt="Service Profile Image" />
            </div>
            <div className="tabsBodyContent">
                <h3 className="tabsBodyTitle">{props.subscription.service} {props.subscription.period}</h3>
                <div className="tabsBodySerial">
                    <span>Order #</span>
                    <span>{props.subscription.subscriptionID}</span>
                </div>
                <div className="tabsBodyLabel">{props.subscription.status?"ACTIVE":"INACTIVE"}</div>
                <div className="tabsBodyDate">Expiry Date: {props.subscription.expiry}</div>
            </div>
        </div>
    )
}

export default SubscriptionItem