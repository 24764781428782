
import axios from "axios"
import { useContext, useState } from "react"
import toast, { Toaster } from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import ShopContext from "../context/shop-context"

const ResetPasswordContent = (props) => {
    const context = useContext(ShopContext)
    const navigate = useNavigate()
    const [values, setValues] = useState({
        phoneNumber: "",
        password: "",
        code: "",
    })
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const submitValue = () => {
        if ((values.phoneNumber == "") || (values.code == "") || (values.password == "")) {
            toast.error("Required details missing.");
            return
        }
        if (values.password != values.confirmPassword) {
            toast.error("Password not matching.");
            return
        }
        context.changePasswd(values.phoneNumber, values.code, values.password)
    }

    return (

        <main id="content">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="form-pagey bg-gray vh-100">
                <div className="form-pagey__container">
                    <div className="form-pagey__content">
                        <div className="form-pagey__boxy">
                            <div className="form-pagey__form">

                                <h2 className="h2">Reset your password?</h2>
                                <p className="p">Enter your new password below.</p>
                                <form>
                                    <div className="input-container">
                                        <label className="label">Phone Number</label>
                                        <input type="text" name="phoneNumber" onChange={onChange} className="form-control" placeholder="Your phone number" />
                                    </div>
                                    <div className="input-container">
                                        <label className="label">Code</label>
                                        <input type="text" name="code" onChange={onChange} className="form-control" placeholder="Enter code received via SMS"  />
                                    </div>
                                    <div className="input-container">
                                        <label className="label">New pasword</label>
                                        <input type="password" name="password" onChange={onChange} className="form-control" placeholder="New password"  />
                                    </div>
                                    <div className="input-container">
                                        <label className="label">Confirm New pasword</label>
                                        <input type="password" name="confirmPassword" onChange={onChange} className="form-control"  placeholder="Confim password" />
                                    </div>
                                    <button type="button" className="btn btn--accent btn--large w-full text--uppercase" onClick={submitValue}>Submit</button>
                                </form>
                                <div className="form-pagey__alt text--center"><Link to="/login" className="w-600">Return to Login</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ResetPasswordContent