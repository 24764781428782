import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import RightCard from './right-card';
import ShopContext from '../../context/shop-context';


export const Address = () => {
    const context = useContext(ShopContext);
    const [addressID, setAddressID] = useState(0)
    const navigate = useNavigate()
    const [values, setValues] = useState({
        addressID: 0,
        existing: "",
        phoneNumber: "",
        area: "",
        city: "",
        street: "",
        addressType: "",
        firstName: "",
        lastName: ""
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
        if (event.target.name == "existing") {
            console.log("Existing:", event.target.value)
            setAddressID(event.target.value)
            for (var i = 0; i < context.addresses.length; i++) {
                var address = context.addresses[i]
                if (address.addressID == event.target.value) {
                    values.city = address.city
                    values.street = address.street
                    values.area = address.area
                    document.getElementById("city").value = address.city
                    document.getElementById("street").value = address.street
                    document.getElementById("area").value = address.area
                }
            }
        }
    }


    useEffect(() => {
        if ((context.userID == "undefined") || (context.userID == undefined)) {
            navigate("/login")
        } else {
            context.fetchAddresses(context.userID)
            context.fetchUserWithResp(context.userID, context.baseUrl, context.tokenVal).then((val) => {
                // document.getElementById("firstName").value = val.firstName
                // document.getElementById("lastName").value = val.lastName
                // document.getElementById("phoneNumber").value = val.phoneNumber
                values.firstName = val.firstName
                values.lastName = val.lastName
                values.phoneNumber = val.phoneNumber
            })
        }
    }, [])
    const viewPayment = () => {
        if ((values.firstName == "") || (values.lastName = "")) {
            toast.error("First or last name missing.")
            return
        }
        context.updateUserName(context.userID,values.firstName,values.lastName)

        if (addressID == 0) {
            if ((values.phoneNumber = "") || (values.city == "") || (values.area == "")) {
                Swal.fire("Address", "Required details missing.", "error")
                return
            }
            context.addAddress(context.userID, values.area, values.street, values.city)
        } else {
            context.attachAddressToCart(addressID)
        }
    }

    return (
        <div id="checkoutAddress">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="checkoutFauxy">
                <div className="container">
                    <div className="checkoutWrapper">
                        <div className="checkoutGrid">
                            <div className="checkoutContent">
                                <div className="checkoutLabel">
                                    <h3>CHECKOUT</h3>
                                </div>
                                <div className="checkoutContentBlock active">
                                    <div className="cardBoxy">
                                        <div className="checkoutHeader">
                                            <span className="material-symbols-outlined check-icon">check_circle</span>
                                            <h2>1. DELIVERY ADDRESS</h2>
                                        </div>
                                        <div className="checkoutMainContent">

                                            <form>
                                                <div className="checkoutFormInput">
                                                    <label>Select from existing address</label>
                                                    <div className="selectWrapper">
                                                        <select name="existing" id="existing" onChange={onChange} className="form-control selectbox">
                                                            {context.addresses.map((address, i) => (
                                                                <option key={i} value={address.addressID}>{address.area}({address.city})</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="checkoutFormInput">
                                                    <label>Or create a new address</label>
                                                </div>
                                                <div className="checkoutFormGrid">
                                                    <div className="checkoutFormInput">
                                                        <label>First Name <span>*</span></label>
                                                        <input name="firstName" id="firstName" type="text" value={values.firstName} onChange={onChange} className="form-control" />
                                                    </div>
                                                    <div className="checkoutFormInput">
                                                        <label>Last Name <span>*</span></label>
                                                        <input name="lastName" id="lastName" type="text" value={values.lastName} onChange={onChange} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="checkoutFormGrid">
                                                    <div className="checkoutFormInput">
                                                        <label>Phone number <span>*</span></label>
                                                        <input name="phoneNumber" id="phoneNumber" type="text" value={values.phoneNumber} onChange={onChange} className="form-control" />
                                                    </div>
                                                    <div className="checkoutFormInput">
                                                        <label>City <span>*</span></label>
                                                        <div className="selectWrapper">
                                                            <select name="city" id="city" onChange={onChange} className="form-control selectbox">
                                                                <option value="nairobi">Nairobi</option>
                                                                <option value="mombasa">Mombasa</option>
                                                                <option value="kisumu">Kisumu</option>
                                                                <option value="nakuru">Nakuru</option>
                                                                <option value="kisii"> Kisii</option>
                                                                <option value="kiambu">Kiambu</option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="checkoutFormGrid">
                                                    <div className="checkoutFormInput">
                                                        <label>Area <span>*</span></label>
                                                        <input name="area" id="area" type="text" onChange={onChange} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="checkoutFormInput">
                                                    <textarea name="street" id="street" onChange={onChange} placeholder="Street Name / Building / Apartment No. / Floor"
                                                        className="form-control form-textarea"></textarea>
                                                </div>
                                                <div className="checkoutFormButton">
                                                    <button type="button" onClick={viewPayment} className="btn btn--primary text-uppercase">SAVE AND
                                                        CONTINUE</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkoutContentBlock">
                                    <div className="cardBoxy">
                                        <div className="checkoutHeader">
                                            <span className="material-symbols-outlined check-icon">check_circle</span>
                                            <h2>2. PAYMENT METHOD</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="checkoutAside">
                                <div className="checkoutLabel">
                                    <h3>ORDER SUMMARY</h3>
                                </div>
                                <RightCard cart={context.cart} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}