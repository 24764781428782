import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';

// v1/payment/overlay/:cartID
export const PaymentOverlay = (props) => {
    const context = useContext(ShopContext);
    const [isExpress, setIsExpress] = useState(false)
    const [isCard, setIsCard] = useState(false)
    const [notClear, setNotClear] = useState(false)
    const [isPaybill, setIsPaybill] = useState(false)

    const [values, setValues] = useState({
        phoneNumber: "",
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const navigate = useNavigate()

    const initiate = () => {
        setNotClear(true)
        if (values.phoneNumber == "") {
            toast.error("Required details missing.");
            return
        }
        context.initiatePay(values.phoneNumber);
        setInterval(() => {
            setNotClear(false)
        }, 20000)
    }
    const completePayment = () => {

        if (isPaybill || ((isExpress) && (context.paymentAction == "COMPLETE ORDER"))) {
            //complete paybill order.
            context.fetchOrder(context.cart.cartID, context.baseUrl, context.tokenVal).then((val) => {
                if ((val.status == "pending") || ((val.status == "completed"))) {
                    toast.success("Order made successfully")
                    var timer = window.setInterval(() => {
                        navigate("/orders")
                        window.clearInterval(timer)
                    }, 2000)
                } else {
                    toast.error("Make payment first before completing order")
                }
            })
            return
        }

        // if ((isExpress) && (context.paymentAction == "COMPLETE ORDER")) {
        //     context.completeOrder(context.cart.cartID);
        //     return
        // }

        if (notClear) {
            toast.Toast("Wait for mpesa transaction to be complete before completing order")
            return
        }

        if (context.CheckoutRequestID == "") {
            toast.error("Required details missing.");
            return
        }

        context.completePayment(context.CheckoutRequestID)
    }
    const closePaybill = () => {
        navigate("/checkout-payment", { state: { "cart": context.cart, "baseUrl": context.baseUrl } })
    }
    const closeCard = () => {
        navigate("/checkout-payment", { state: { "cart": context.cart, "baseUrl": context.baseUrl } })
    }
    const closeExpress = () => {
        navigate("/checkout-payment", { state: { "cart": context.cart, "baseUrl": context.baseUrl } })
    }
    useEffect(() => {
        context.fetchPaymentDetails(context.cart.cartID)
        if (props.method == "express") {
            setIsExpress(true);
        }
        if (props.method == "paybill") {
            setIsPaybill(true);
        }
        if (props.method == "card") {
            setIsCard(true);
        }
    }, [])

    return (
        <div>
            <div id="mpesa-paybill" className={isPaybill ? "modal show-modal" : "modal"}>
                <div className="modal__container">
                    <div className="modal__header">
                        <h3>M-PESA Paybill</h3>
                        <div className="icon-close-button" onClick={closePaybill}>
                            <span className="material-symbols-outlined">close</span>
                        </div>
                    </div>
                    <div className="modal__content">
                        <Toaster
                            position="bottom-right"
                            reverseOrder={false}
                        />
                        <div className="modal__desc">
                            <div className="modal__item">1. Go to MPESA menu</div>
                            <div className="modal__item">2. Select “Lipa na MPESA”</div>
                            <div className="modal__item">3. Select Paybill Number</div>
                            <div className="modal__item">4. Enter business number:</div>
                            <div className="modal__box">{context.storeDetails.paybill}</div>
                            <div className="modal__item">5. Account number:</div>
                            <div className="modal__box">{context.storeDetails.account}</div>
                            <div className="modal__item">6. Enter amount:</div>
                            <div className="modal__box">Ksh. {context.storeDetails.amount}</div>
                            <div className="modal__box">7. Enter your PIN</div>
                            <div className="modal__box">8. Payment will come from: “{context.storeDetails.store}”</div>
                            <div className="modal__box">Are you done? Proceed to complete order below</div>
                            <div className="modal__submit">
                                <button type="button" onClick={completePayment} className="btn btn--primary">COMPLETE ORDER</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="card" className={isCard ? "modal show-modal" : "modal"}>
                <div className="modal__container">
                    <div className="modal__header">
                        <h3>Online Card Payment</h3>
                        <div className="icon-close-button" onClick={closeCard}>
                            <span className="material-symbols-outlined">close</span>
                        </div>
                    </div>
                    <div className="modal__content">
                        <Toaster
                            position="bottom-right"
                            reverseOrder={false}
                        />
                        <div className="modal__desc">
                            <div className="modal__item">Not available</div>
                            {/* <div className="modal__box">{context.storeDetails.paybill}</div> */}

                            <div className="modal__submit">
                                <button type="button" onClick={closeCard} className="btn btn--primary">USE MPESA</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mpesa-express" className={isExpress ? "modal show-modal" : "modal"}>
                <div className="modal__container">
                    <div className="modal__header">
                        <h3>M-PESA Express</h3>
                        <div className="icon-close-button" onClick={closeExpress}>
                            <span className="material-symbols-outlined">close</span>
                        </div>
                    </div>
                    <div className="modal__content">
                        <Toaster
                            position="bottom-right"
                            reverseOrder={false}
                        />
                        <form className="modal__form">
                            <div className="modal__desc">
                                {context.paymentIsCompleteVisible ? <>
                                    <h4>{context.paymentResult}</h4>
                                </> : <>
                                    <h4>Pay now ksh. {context.storeDetails.amount} Via M-PESA Online Checkout</h4>
                                    <h4>You will receive an M-PESA push to the following number: <span
                                        className="w-600">{values.phoneNumber}</span></h4>
                                    <h4>Your account: <span
                                        className="w-600">{context.storeDetails.account}</span></h4>
                                </>}
                            </div>
                            <div className="modal__input" style={{ visibility: context.paymentIsCompleteVisible ? 'hidden' : 'visible' }}>
                                <label className="w-600">Enter phone number you want to use to pay</label>
                                <input name="phoneNumber" type="text" onChange={onChange} values={values.phoneNumber} placeholder="Enter your phone number." className="form-control" />
                            </div>
                            {/* <div className="modal__actions">
                                <button type="button" className="btn btn-full">CONFIRM</button>
                                <button type="button" className="btn btn-outline">CANCEL</button>
                            </div> */}
                            <div className="modal__submit" style={{ visibility: context.paymentIsCompleteVisible ? 'hidden' : 'visible' }}>
                                <button type="button" onClick={initiate} className="btn btn--primary">INITIATE PAYMENT</button>
                            </div>
                            <div className="modal__submit" style={{ visibility: context.paymentIsCompleteVisible ? 'visible' : 'hidden' }}>
                                <button type="button" onClick={completePayment} className="btn btn--primary">{context.paymentAction}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}