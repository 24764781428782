import axios from 'axios';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ShopContext from '../../context/shop-context';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Series = () => {
    const context = useContext(ShopContext);
    const [page, setPage] = useState(0)
    const navigate = useNavigate();
    const { state } = useLocation()

    useEffect(() => {
        // loadMore()
    }, [state])

    return (
        <div id="streaming">
            <div className="streamFauxy">
                <div className="container">
                    <div className="streamFauxyMenu">
                        <ul className="streamFauxyMenuList">
                            <li className="streamFauxyMenuItem">
                                <a href="stream-home.html" className="streamFauxyMenuLink">Home</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">Live</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">EPG</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="stream-category-movies.html" className="streamFauxyMenuLink">Movies</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="stream-category-series.html" className="streamFauxyMenuLink active">Series</a>
                            </li>
                        </ul>
                    </div>

                    <div className="streamFauxyGrid">
                        <div className="streamFauxyContent">
                            <div id="allSeries">
                                <div className="streamFauxyContentHeading">
                                    <div className="headingTitle">
                                        <h2>All TV Series</h2>
                                    </div>
                                    <div className="headingLink">
                                        <a href="javascript:void(0);" className="link">
                                            <span className="material-symbols-outlined">filter_list</span>
                                            <span>Filter</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="streamFauxyContentList">
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/terminal_list.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Terminal List</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/reacher.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Reacher</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/vikings.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Vikings: Valhalla</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/kungfu-panda.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Kung Fu Panda</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/legend_vox.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Legend of Vox Machina</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/daniel_spellbound.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Daniel Spellbound</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/neymar.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Neymar: The Perfect Chaos</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/bosch_legacy.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Bosch: Legacy</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/resident_evil.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Resident Evil</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/in_from_the_cold.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>In From the Cold</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/cobra_kai.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Cobra Kai</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/devils_hour.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Devil's Hour</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/kaleidoscope.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Kaleidoscope</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/barbarians.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Barbarians</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/lockwood.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Lockwood</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/riffTrax.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>RiffTrax</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/wu_assassins.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Wu Assassins</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/girls5eva.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Girls5eva</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="paginationGlobal">
                                    <div className="pagination-nav pagination-prev" disabled>
                                        <span className="material-symbols-outlined">navigate_before</span>
                                    </div>
                                    <ul className="paginationList unstyled">
                                        <li>
                                            <a href="#" className="active">1</a>
                                        </li>
                                        <li>
                                            <a href="#">2</a>
                                        </li>
                                        <li>
                                            <a href="#">3</a>
                                        </li>
                                    </ul>
                                    <div className="pagination-nav pagination-next">
                                        <span className="material-symbols-outlined">navigate_next</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Series