import { useContext, useEffect, useRef, useState } from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import Swal from "sweetalert2";
import ShopContext from "../../context/shop-context";
import toast from 'react-hot-toast';

export const BlogSingle = (props) => {
    const [searchParams] = useSearchParams()
    const { state } = useLocation()
    const context = useContext(ShopContext)
    const navigate = useNavigate()
    const [article, setArticle] = useState({ articleID: 0 })
    const [page, setPage] = useState(1)
    const [prevArticle, setPrevArticle] = useState({ title: "" })
    const [nextArticle, setNextArticle] = useState({ title: "" })
    const [otherArticles, setOtherArticles] = useState([])
    // const [toggleArticles, setToggleArticles] = useState([])
    const [comments, setComments] = useState([])
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const { articleID } = useParams()

    const viewHighlight = (e) => {
        var key = e.currentTarget.getAttribute("data-key")
        navigate("/article", { state: { "article": key } })
        context.scrollToTop()
    }

    const getAccess = () => {
        navigate("/blog-subscribe")
        context.scrollToTop()
    }

    const likePost = () => {
        // console.log("Test")
        context.postLike(article.articleID, context.userID)
        context.scrollToTop()
    }

    const dislikePost = () => {
        context.postDisLike(article.articleID)
        context.scrollToTop()
    }

    const comment = () => {
        if ((values.author == "") || (values.email == "") || (values.comment == "")) {
            toast.error("Required details missing")
            return
        }
        context.postComment(article.articleID, values.email, values.author, values.comment)
        context.scrollToTop()
    }

    const [values, setValues] = useState({
        email: "",
        author: "",
        comment: "",
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    useEffect(() => {
        var id = localStorage.getItem("selectedBlog")
        // const params = new URLSearchParams(window.location.pathname)
        // console.log("ID: ", articleID)
        if (state != null) {
            if ("article" in state) {
                id = state.article
                localStorage.setItem("selectedBlog", id)
            }
        }

        if (articleID != undefined) {
            id = articleID
        }

        context.fetchArticleWithPromise(id, context.baseUrl).then((val) => {
            if (val.success == 1) {
                setArticle(val.message)
                if (val.message.comments != null) {
                    setComments(val.message.comments.slice(0, 5))
                }

                setOtherArticles(val.message.recommendedArticles)
                setNextArticle(val.message.nextArticle)
                setPrevArticle(val.message.previousArticle)
            } else {
                setArticle({ articleID: 0 })
            }
        })


    }, [state])

    return (
        <main id="blogPageSingle">
            <div className="post__list">
                <div className="container">
                    <div className="post__list__grid">
                        <div className="post__list__item">
                            {article.articleID != 0 ?
                                <div className="nt-block-post-grid">
                                    <div className="nt-block-post-content-grid">
                                        <div className="single-block">
                                            <div className="nt-block-post-content">
                                                <div style={{ marginRight: '8px' }} className="nt-block-post-category">{article.categories[0]}</div>
                                                <div className="nt-block-post-category">{article.tier == "paid" ? "premium" : "blog"}</div>
                                                <h1 className="entry-title">{article.title}</h1>
                                                <div className="nt-block-post-top-meta">
                                                    <div className="nt-block-post-author">
                                                        <span className="author-avatar">
                                                            <img loading="lazy" className="responsive-image" src={"assets/images/blog/no_photo.jpg"} alt="No photo available" width="25" height="25" />
                                                        </span>
                                                        <span className="nt-blocks-author-pretext">By{" " + article.author}</span>
                                                    </div>
                                                    <div className="nt-block-meta-divider nt-block-meta-has-divider">|</div>
                                                    <div className="kt-blocks-date"><time className="nt-blocks-post-date">{article.dateAdded}</time></div>
                                                    {/* <div className="nt-block-meta-divider nt-block-meta-has-divider">|</div>
                                                    <div className="kt-blocks-views">
                                                        <span className="material-symbols-outlined kt-block-icon">visibility</span>
                                                        <span>{" " + article.views}</span>
                                                    </div> */}
                                                    <div className="nt-block-meta-divider nt-block-meta-has-divider">|</div>
                                                    <div className="kt-blocks-comments">
                                                        <span className="material-symbols-outlined kt-block-icon">comment</span>
                                                        {article.comments != null ? <span>{" " + article.comments.length}</span> : <span>{0}</span>}
                                                    </div>
                                                </div>
                                                {article.entryText!=null?<div className="entry-content">{article.entryText}</div>:""}
                                                <div className="nt-block-post-social"></div>
                                                <div className="nt-block-post-banner">
                                                    <img className="responsive-image" src={context.awsS3url + "/images/" + article.photo} alt="" />
                                                </div>

                                                {article.tier == "paid" ?
                                                    <div className="nt-block-post-content">
                                                        <p dangerouslySetInnerHTML={{ __html: article.intro }} />
                                                        <div className="ww-container__empty-apt"></div>
                                                        <div className="ww-container__tease-promo">
                                                            <p className="ww-container__tease-title">Subscribe to read article now</p>
                                                            <p className="ww-container__tease-para">This is a premium article, subscribe to read the full story.</p>
                                                            <p className="ww-container__tease-links">
                                                                Already a subscriber? <Link to="/login">Log in</Link>
                                                            </p>
                                                            <div className="ww-container__access-btn">
                                                                <a href="#" onClick={getAccess} className="btn no-box-shadow">Get Access Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div id="content" dangerouslySetInnerHTML={{ __html: article.content }} className="nt-block-post-content">
                                                    </div>
                                                }
                                                <div className="content-like">
                                                    <span className="content-like__renderText">Was this article helpful?</span>
                                                    <button className="content-like__button active">
                                                        <span onClick={likePost} className="material-symbols-outlined">thumb_up</span>
                                                    </button>
                                                    <button className="content-like__button">
                                                        <span onClick={dislikePost} className="material-symbols-outlined">thumb_down</span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="page-navlinks">
                                            {prevArticle.articleID != 0 ? <div className="nav-previous" data-key={prevArticle.articleID} onClick={viewHighlight}>
                                                <div href="#" ref={prevRef}>
                                                    <div className="post-navigation-sub" >
                                                        <span className="material-symbols-outlined">arrow_back</span>
                                                        <span className="post-navigation-label">Previous</span>
                                                    </div>
                                                    <span className="post-navigation-text">{prevArticle.title}</span>
                                                </div>
                                            </div> : ""}
                                            {nextArticle.articleID != 0 ? <div className="nav-next" data-key={nextArticle.articleID} onClick={viewHighlight} >
                                                <a href="#" ref={nextRef}>
                                                    <div className="post-navigation-sub">
                                                        <span className="post-navigation-label">Next</span>
                                                        <span className="material-symbols-outlined">arrow_forward</span>
                                                    </div>
                                                    <span className="post-navigation-text">{nextArticle.title}</span>
                                                </a>
                                            </div> : ""}
                                        </div>
                                        {comments.length != 0 ?
                                            <div className="comments-reply-list">
                                                {comments.map((comment) => (
                                                    <div className="comments-reply-item">
                                                        <div className="comments-reply-item-avatar">
                                                            <img loading="lazy" className="responsive-image" src={"assets/images/blog/no_photo.jpg"} alt="" width="50"
                                                                height="50" />
                                                        </div>
                                                        <div className="comments-reply-item-comment">
                                                            <div className="comments-reply-item-content">
                                                                <h5>{comment.name}</h5>
                                                                <div className="comment-post-meta">
                                                                    <time className="comment-post-date">{comment.dateAdded}</time>
                                                                </div>
                                                            </div>
                                                            <p>{comment.message}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div> : <p>No comments</p>
                                        }
                                        <div className="comments-area">
                                            <h3 className="comment-reply-title">Leave a Reply </h3>

                                            <p className="comment-notes"><span>Your email address will not be published.</span> <span
                                                className="required-field-message" aria-hidden="true">Required fields are
                                                marked <span className="required" aria-hidden="true">*</span></span></p>
                                            <p className="comment-form-comment">
                                                <textarea id="comment" name="comment" placeholder="Leave a comment..."
                                                    cols="45" rows="8" onChange={onChange} maxLength="65525" aria-required="true"
                                                    required="required" className="form-control form-textarea"></textarea>
                                            </p>
                                            <div className="comment-input-wrap">
                                                <p className="comment-form-author">
                                                    <label className="label" hmtlFor="author">Name <span
                                                        className="required">*</span></label>
                                                    <input aria-label="Name" onChange={onChange} id="author" name="author" type="text"
                                                        placeholder="John Doe" size="30" maxLength="245"
                                                        aria-required="true" required="required" className="form-control" />
                                                </p>
                                                <p className="comment-form-email">
                                                    <label className="label" hmtlFor="email">Email <span
                                                        className="required">*</span></label>
                                                    <input aria-label="Email" onChange={onChange} id="email" name="email" type="email"
                                                        placeholder="john@example.com" size="30" maxLength="100"
                                                        aria-describedby="email-notes" aria-required="true"
                                                        required="required" className="form-control" />
                                                </p>
                                            </div>
                                            <button onClick={comment} className="btn btn--primary">Post Comment</button>
                                        </div>
                                        {/* <BottomSection article={article}/> */}
                                    </div>
                                    <div className="nt-block-aside">
                                        <div className="nt-block-aside-title">
                                            <h3>Popular News</h3>
                                        </div>
                                        {otherArticles.map((article, i) => (
                                            <div key={i} data-key={article.articleID} onClick={viewHighlight} className="nt-block-post-item">
                                                <div className="nt-block-post-image">
                                                    <div className="nt-block-post-image-inner">
                                                        <div className="block-post-image">
                                                            <a href="#!">
                                                                <img className="responsive-image" src={context.awsS3url + "/images/" + article.photo} alt="" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nt-block-post-content">
                                                    <h4 className="entry-title">
                                                        <a href="#!">{article.title}</a>
                                                    </h4>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="nt-block-aside-ad">
                                            <h6>ADVERTISEMENT</h6>
                                            <div className="advert-block-250X300"></div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <ReactPlaceholder showLoadingAnimation={context.loading} style={{ padding: "16px" }} type="media" rows={10}>
                                    <p>No content found</p>
                                </ReactPlaceholder>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}