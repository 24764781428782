import React, { Component, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast'
import ShopContext from '../../context/shop-context';

export const DashboardAside = (props) => {
    const context = useContext(ShopContext)


    return (
        <div className="dashboardPageyAside">
            <div className="cardBoxy">
                <div className="dashboardTop">
                    <div className="dashboardNavigation">
                        <Toaster
                            position="bottom-right"
                            reverseOrder={false}
                        />
                        <nav className="dashboardNav">
                            <ul className="dashboardNavList">
                                <li className="dashboardNavItem">
                                    <Link to="/dashboard" className={props.active == "dashboard" ? "dashboardNavLink active" : "dashboardNavLink"}>
                                        <span
                                            className="material-symbols-outlined small-icon">account_circle</span>
                                        <span>Account Overview</span>
                                    </Link>
                                </li>
                                <li className="dashboardNavItem">
                                    <Link to="/orders" className={props.active == "orders" ? "dashboardNavLink active" : "dashboardNavLink"}>
                                        <span
                                            className="material-symbols-outlined small-icon">inventory_2</span>
                                        <span>Orders</span>
                                    </Link>
                                </li>
                                <li className="dashboardNavItem">
                                    <Link to="/subscriptions" className={props.active == "subscriptions" ? "dashboardNavLink active" : "dashboardNavLink"}>
                                        <span
                                            className="material-symbols-outlined small-icon">subscriptions</span>
                                        <span>Subscriptions</span>
                                    </Link>
                                </li>
                                {/* <li className="dashboardNavItem">
                                    <Link to="/inbox" className={props.active == "inbox" ? "dashboardNavLink active" : "dashboardNavLink"}>
                                        <span className="material-symbols-outlined small-icon">mail</span>
                                        <span>Inbox</span>
                                    </Link>
                                </li>
                                <li className="dashboardNavItem">
                                    <Link to="/saved-items" className={props.active == "saved-items" ? "dashboardNavLink active" : "dashboardNavLink"}>
                                        <span
                                            className="material-symbols-outlined small-icon">favorite</span>
                                        <span>Saved Items</span>
                                    </Link>
                                </li>
                                <li className="dashboardNavItem">
                                    <Link to="/recently-viewed" className={props.active == "recently-viewed" ? "dashboardNavLink active" : "dashboardNavLink"}>
                                        <span className="material-symbols-outlined small-icon">update</span>
                                        <span>Recently Viewed</span>
                                    </Link>
                                </li> */}
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="dashboardCenter">
                    <nav className="dashboardAccount">
                        <ul className="dashboardAccountList">
                            <li className="dashboardAccountItem">
                                <Link to="/account-management" className={props.active == "account-management" ? "dashboardAccountLink active" : "dashboardAccountLink"}>Account
                                    Management</Link>
                            </li>
                            <li className="dashboardAccountItem">
                                <Link to="/address" className={props.active == "edit-address" ? "dashboardAccountLink active" : "dashboardAccountLink"}>Address
                                    Book</Link>
                            </li>
                            {/* <li className="dashboardAccountItem">
                                <Link to="/delete-account" className="dashboardAccountLink">delete
                                    Account</Link>
                            </li> */}
                        </ul>
                    </nav>
                </div>
                <div className="dashboardBottom">
                    <div className="dashboardLogut">
                        <a href="#" onClick={context.logout}>Log Out</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardAside