import axios from 'axios';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ShopContext from '../../context/shop-context';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Movie = () => {
    const context = useContext(ShopContext);
    const [page, setPage] = useState(0)
    const navigate = useNavigate();
    const { state } = useLocation()


   
    useEffect(() => {
        // loadMore()
    }, [state])

    return (
        <div id="streaming">
            <div className="streamFauxy">
                <div className="container">
                    <div className="streamFauxyMenu">
                        <ul className="streamFauxyMenuList">
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink active">Home</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">Live</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">EPG</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">Movies</a>
                            </li>
                            <li className="streamFauxyMenuItem">
                                <a href="#" className="streamFauxyMenuLink">Series</a>
                            </li>
                        </ul>
                    </div>
                    <div className="streamFauxyGrid">
                        <div className="streamFauxyContent">
                            <div id="allMovies">
                                <div className="streamFauxyContentHeading">
                                    <div className="headingTitle">
                                        <h2>All Movies</h2>
                                    </div>
                                    <div className="headingLink">
                                        <a href="javascript:void(0);" className="link">
                                            <span className="material-symbols-outlined">filter_list</span>
                                            <span>Filter</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="streamFauxyContentList">
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="stream-video-movie.html">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/speed_1994.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Speed 1994</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/dark_knight.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Dark Knight</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/aliens.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Alien</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/jaws.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Jaws</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/inception.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Inception</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/pulp_fiction.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Pulp Fiction</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/heat.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Heat</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/mad_max.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Mad Max: Fury Road</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/lord_of_rings.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Return of the King</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/seven.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Seven</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/good_fellas.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Goodfellas</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/shawshank_redemption.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Shawshank Redemption</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/old_men.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>No Country for Old Men</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/terminator.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>Terminator 2</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/matrix.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Matrix</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/good_ugly.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Good, the Bad and the Ugly</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="streamFauxyContentItem">
                                            <a href="#">
                                                <div className="streamFauxyContentItemImage">
                                                    <div className="streamFauxyContentItemPlay">
                                                        <div className="streamFauxyContentItemPlayWrapper">
                                                            <span className="material-symbols-outlined play-icon">play_arrow</span>
                                                        </div>
                                                    </div>
                                                    <img src={"assets/images/stream/two_towers.jpg"} alt="" />
                                                </div>
                                                <div className="streamFauxyContentItemText">
                                                    <h4>The Two Towers</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="paginationGlobal">
                                    <div className="pagination-nav pagination-prev" disabled>
                                        <span className="material-symbols-outlined">navigate_before</span>
                                    </div>
                                    <ul className="paginationList unstyled">
                                        <li>
                                            <a href="#" className="active">1</a>
                                        </li>
                                        <li>
                                            <a href="#">2</a>
                                        </li>
                                        <li>
                                            <a href="#">3</a>
                                        </li>
                                    </ul>
                                    <div className="pagination-nav pagination-next">
                                        <span className="material-symbols-outlined">navigate_next</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Movie