import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import './assets/css/build.css';
import './assets/css/material.css';
import Login from './containers/login';
import Register from './containers/register';
import ForgotPassword from './containers/forgotPassword';
import ResetPassword from './containers/resetPassword';

import React from 'react';
import Index from './containers';
import ProductsViewPage from './containers/products-view-page';
import ProductPage from './containers/product-page';
import CartPage from './containers/cart-page';
import { AddressPage } from './containers/address-page';
import { PaymentPage } from './containers/payment-page';
import OrdersViewPage from './containers/dashboard/orders-view-page';
import PaymentOverlayPage from './containers/payment-overlay-page';
import AccountReviewPage from './containers/dashboard/account-overview-page';
import ServicesViewPage from './containers/services-view-page';
import SavedItemsPage from './containers/dashboard/saved-items-page';
import RecentlyViewedPage from './containers/dashboard/recently-viewed-page';
import SubscriptionsPage from './containers/dashboard/subscriptions-page';
import InboxPage from './containers/dashboard/inbox-page';
import EditAddressPage from './containers/dashboard/edit-address-page';
import AccountManagementPage from './containers/dashboard/account-management-page';

import ContactUsPage from './containers/contact-us-page';
import BlogHomePage from './containers/blog-home-page';
import BlogSinglePage from './containers/blog-single-page';
import AboutUsPage from './containers/about-us-page';
import TermsPage from './containers/terms-page';
import NotFoundPage from './containers/404-page';
import GlobalState from './context/GlobalState';
import { SearchResultPage } from './containers/search-result-page';
import { BlogSubscribePage } from './containers/dashboard/blog-subscribe-page';
import { StreamHomePage } from './containers/stream-home-page';
import AccountPreferencesPage from './containers/dashboard/account-preferences-page';
import MoviePage from './containers/movies-page';
import StreamMoviePage from './containers/stream-movie-page';
import StreamSeriesPage from './containers/stream-series-page';
import SeriesPage from './containers/series-page';

class App extends React.Component {
  

  render() {
    return (
      <GlobalState>
        <Router>
          <Routes>
            <Route exact path="/" element={<Index />} />
            <Route exact path="/products" element={<ProductsViewPage/>} />
            <Route exact path="/services" element={<ServicesViewPage />} />
            <Route exact path="/cart" element={<CartPage />} />
            <Route exact path="/orders" element={<OrdersViewPage />} />
            <Route exact path="/subscriptions" element={<SubscriptionsPage />} />
            <Route exact path="/saved-items" element={<SavedItemsPage />} />
            <Route exact path="/recently-viewed" element={<RecentlyViewedPage />} />
            <Route exact path="/inbox" element={<InboxPage />} />
            <Route exact path="/article/:articleID" element={<BlogSinglePage />} />
            <Route exact path="/article" element={<BlogSinglePage />} />
            <Route exact path="/watch" element={<StreamHomePage />} />
            <Route exact path="/search" element={<SearchResultPage />} />
            <Route exact path="/preferences" element={<AccountPreferencesPage />} />
            <Route exact path="/articles" element={<BlogHomePage />} />
            <Route exact path="/blog-subscribe" element={<BlogSubscribePage  />} />
            <Route exact path="/address" element={<EditAddressPage  />} />
            <Route exact path="/account-management" element={<AccountManagementPage />} />
            <Route exact path="/payment-overlay" element={<PaymentOverlayPage  />} />
            <Route exact path="/dashboard" element={<AccountReviewPage  />} />
            <Route exact path="/movies" element={<MoviePage  />} />
            <Route exact path="/series" element={<SeriesPage  />} />
            <Route exact path="/stream-movie" element={<StreamMoviePage  />} />
            <Route exact path="/stream-series" element={<StreamSeriesPage  />} />
            <Route exact path="/checkout-address" element={<AddressPage  />} />
            <Route exact path="/contact-us" element={<ContactUsPage  />} />
            <Route exact path="/about-us" element={<AboutUsPage  />} />
            <Route exact path="/terms" element={<TermsPage  />} />
            <Route exact path="/events" element={<NotFoundPage  />} />
            <Route exact path="/blog" element={<NotFoundPage  />} />
            <Route exact path="/checkout-payment" element={<PaymentPage />} />
            <Route path="/register" element={<Register/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/product/:itemProductID/:itemVariantID" element={<ProductPage />} />
          </Routes>
        </Router>
      </GlobalState>

    );
  }
}

export default App;
