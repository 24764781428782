import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';
import RightCard from './right-card';


export const Payment = () => {
    const context = useContext(ShopContext);
    const navigate = useNavigate();
    
    const [values, setValues] = useState({
        radio: "express",
    })
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const viewPaymentOverlay = () => {
        navigate("/payment-overlay", { state: { "method":values.radio} })
    }
    return (
        <div id="checkoutAddress">
             <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="checkoutFauxy">
                <div className="container">
                    <div className="checkoutWrapper">
                        <div className="checkoutGrid">
                            <div className="checkoutContent">
                                <div className="checkoutLabel">
                                    <h3>CHECKOUT</h3>
                                </div>
                                <div className="checkoutContentBlock done">
                                    <div className="cardBoxy">
                                        <div className="checkoutHeader">
                                            <span className="material-symbols-outlined check-icon">check_circle</span>
                                            <h2>1. DELIVERY ADDRESS</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkoutContentBlock active">
                                    <div className="cardBoxy">
                                        <div className="checkoutHeader">
                                            <span className="material-symbols-outlined check-icon">check_circle</span>
                                            <h2>2. PAYMENT METHOD</h2>
                                        </div>
                                        <div className="checkoutMainContent">
                                            <form>
                                                <div className="checkoutMainContentLabel">How do you want to pay for your order?
                                                </div>
                                                <div className="checkoutPaymentMethods">
                                                    <div className="checkoutPaymentOption">
                                                        <div className="checkoutPaymentLeft">
                                                            <label className="checkContainer">
                                                                <input type="radio" checked="checked" name="radio" value="express" onChange={onChange} />
                                                                <span className="checkCheckmark"></span>
                                                            </label>
                                                        </div>
                                                        <div className="checkoutPaymentRight">
                                                            <div className="checkoutPaymentImage">
                                                                <img src={"assets/images/logos/mpesa_express_logo.png"}
                                                                    alt="Pay with M-PESA Express" />
                                                            </div>
                                                            <div className="checkoutPaymentContent">
                                                                <h3 className="checkoutPaymentTitle">M-PESA Express</h3>
                                                                <p className="checkoutPaymentdesc">You will receive a M-PESA
                                                                    push notification on your phone</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="checkoutPaymentOption">
                                                        <div className="checkoutPaymentLeft">
                                                            <label className="checkContainer">
                                                                <input type="radio" name="radio" value="paybill" onChange={onChange}/>
                                                                <span className="checkCheckmark"></span>
                                                            </label>
                                                        </div>
                                                        <div className="checkoutPaymentRight">
                                                            <div className="checkoutPaymentImage">
                                                                <img src={"assets/images/logos/mpesa_paybill_logo.png"}
                                                                    alt="Pay with M-PESA PAYBILL" />
                                                            </div>
                                                            <div className="checkoutPaymentContent">
                                                                <h3 className="checkoutPaymentTitle">M-PESA PAYBILL</h3>
                                                                <p className="checkoutPaymentdesc">Pay manually using M-Pesa paybill</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="checkoutPaymentOption">
                                                        <div className="checkoutPaymentLeft">
                                                            <label className="checkContainer">
                                                                <input type="radio" name="radio"  value="card"  onChange={onChange}/>
                                                                <span className="checkCheckmark"></span>
                                                            </label>
                                                        </div>
                                                        <div className="checkoutPaymentRight">
                                                            <div className="checkoutPaymentImage">
                                                                <img src={"assets/images/logos/cards.png"} alt="Pay with card" />
                                                            </div>
                                                            <div className="checkoutPaymentContent">
                                                                <h3 className="checkoutPaymentTitle">Card</h3>
                                                                <p className="checkoutPaymentdesc">Pay using your card</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="checkoutPaymentPriceContent">
                                                    <div className="checkoutPaymentPriceSummary">
                                                        <div className="checkoutPaymentPriceBlock">
                                                            <div className="blockLabel">Subtotal</div>
                                                            <div className="blockPrice">Ksh. <span>{context.cart.amount}</span></div>
                                                        </div>
                                                        <div className="checkoutPaymentPriceBlock">
                                                            <div className="blockLabel">VAT</div>
                                                            <div className="blockPrice">Ksh. <span>{0}</span></div>
                                                        </div>
                                                        <div className="checkoutPaymentPriceBlock">
                                                            <div className="blockLabel">Local delivery fees</div>
                                                            <div className="blockPrice">Ksh. <span>{0}</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="checkoutPaymentPriceTotal">
                                                        <div className="blockLabel">Total</div>
                                                        <div className="blockPrice">Ksh. <span>{context.cart.amount}</span></div>
                                                    </div>
                                                </div>
                                                <div className="checkoutFormButton">
                                                    <button className="btn btn--primary text-uppercase" onClick={viewPaymentOverlay}>MAKE PAYMENT</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="checkoutAside">
                                <div className="checkoutLabel">
                                    <h3>ORDER SUMMARY</h3>
                                </div>
                                <RightCard cart={context.cart}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}