import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from "../components/header";
import Footer from '../components/footer';
import { Address } from '../components/checkout/address';
import { Payment } from '../components/checkout/payment';
import Cookies from '../components/cookies';
import Loading from '../components/loading';

export const PaymentPage = (props) => {

  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="dashboard" />
      <Payment />
      <Footer />
      <Cookies />
    </div>
  )
}