import { useContext, useEffect, useState } from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { useLocation } from "react-router-dom";
import ShopContext from "../../context/shop-context";
import { BlogItemResult } from "./blog-item-result";
import { ProductItemResult } from "./product-item-result";
import { ServiceResult } from "./service-result";


export const Results = (props) => {
    const context = useContext(ShopContext);
    const [searchText, setSearchText] = useState("")
    const { state } = useLocation();
    useEffect(() => {
        if (state != null) {
            if ("searchText" in state) {
                console.log("Search Text:" + state.searchText)
                setSearchText(state.searchText)
                context.search(state.searchText)
            }
        }
    }, [state])
    return (
        <main id="cartPage">
            <div className="searchResults">
                <div className="container">
                    <div className="searchResultsWrapper">
                        <div className="searchResultsHeading">
                            <h1>Search Results for: {searchText}</h1>
                        </div>

                        {context.searchResult.count != 0 ?
                            <div className="searchList">
                                {/* <div className="searchResultsHeading">
                                    <h1>Services Results({context.searchResult.services.length})</h1>
                                </div> */}
                                {context.searchResult.services!=null?context.searchResult.services.length != 0 ?
                                    context.searchResult.services.map((item,index) => (
                                        <div key={index} className="searchListItem">
                                            <ServiceResult service={item} />
                                        </div>
                                    ))
                                    : "": ""
                                }
                                {/* <div className="searchResultsHeading">
                                    <h1>Products Results({context.searchResult.products.length})</h1>
                                </div> */}
                                {context.searchResult.products!=null?context.searchResult.products.length != 0 ?
                                    context.searchResult.products.map((item,index) => (
                                        <div key={index} className="searchListItem">
                                            <ProductItemResult product={item} />
                                        </div>
                                    ))
                                    : "":""
                                }
                                {/* <div className="searchResultsHeading">
                                    <h1>Blog Results({context.searchResult.articles.length})</h1>
                                </div> */}
                                {context.searchResult.articles!=null?context.searchResult.articles.length != 0 ?
                                    context.searchResult.articles.map((item,index) => (
                                        <div key={index} className="searchListItem">
                                            <BlogItemResult article={item} />
                                        </div>
                                    ))
                                    : "": ""
                                }
                            </div>
                            :
                            <div className="searchList">
                                <div className="cartProductEmpty">
                                    <div className="cartProductEmptyIcon">
                                        <span className="material-symbols-outlined">search</span>
                                    </div>
                                    <p>No records found</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}