import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ShopContext from '../../context/shop-context';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import * as utils from '../../context/utils';
import OwlCarousel from 'react-owl-carousel';


export const StreamSeries = () => {
    const context = useContext(ShopContext);
    const [page, setPage] = useState(0)
    const navigate = useNavigate();
    const { state } = useLocation()


    useEffect(() => {
        // loadMore()
    }, [state])

    return (
        <div id="streaming">
            <div className="streamFauxy">
                <div className="container">
                    <div className="streamFauxyBreadcreumb">
                        <div id="bread">
                            <ul className="breadcrumb">
                                <li>
                                    <a href="#">Home</a>
                                </li>
                                <li>
                                    <a href="#">Series</a>
                                </li>
                                <li>
                                    <a href="#">The Ark - Season 1</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="streamFauxyPlayerGrid">
                        <div className="streamFauxyPlayerVideo">
                            <div id="playerArea">
                                <video
                                    id="media-player"
                                    className="video-js"
                                    controls
                                    preload="auto"
                                    width="640"
                                    height="264"
                                    poster="MY_VIDEO_POSTER.jpg"
                                    data-setup="{}"
                                >
                                    <source src="/videos/mortal_kombat.mp4" type="video/mp4" />
                                    <source src="/videos/mortal_kombat.webm" type="video/webm" />
                                    <p className="vjs-no-js">
                                        To view this video please enable JavaScript, and consider upgrading to a
                                        web browser that
                                        <a href="https://videojs.com/html5-video-support/" target="_blank"
                                        >supports HTML5 video</a>
                                    </p>
                                </video>
                            </div>
                        </div>
                        <div className="streamFauxyPlayerPlaylist">
                            <div className="playlistWrapper">
                                <div className="playlistWrapperHeading">
                                    <h3>Season 1 Episodes</h3>
                                </div>
                                <div className="playlistContent">
                                    <div className="playlistContentItem">
                                        <div className="episodeNumber"><span className="episodeNumberText">1</span></div>
                                        <div className="episodePoster">
                                            <img src={"assets/images/stream/NUP_198043_00064_lowRes.jpg"} alt="" />
                                        </div>
                                        <div className="episodeContent">
                                            <h4>Episode 1</h4>
                                            <p>Everyone Wanted to Be on This Ship</p>
                                        </div>
                                    </div>
                                    <div className="playlistContentItem active-playing">
                                        <div className="episodeNumber">
                                            <span className="episodeNumberText">2</span>
                                            <span className="material-symbols-outlined icon-play">play_arrow</span>
                                        </div>
                                        <div className="episodePoster">
                                            <img src={"assets/images/stream/NUP_198043_00332.jpg"} alt="" />
                                        </div>
                                        <div className="episodeContent">
                                            <h4>Episode 2</h4>
                                            <p>Like It Touched the Sun</p>
                                        </div>
                                    </div>
                                    <div className="playlistContentItem">
                                        <div className="episodeNumber"><span className="episodeNumberText">3</span></div>
                                        <div className="episodePoster">
                                            <img src={"assets/images/stream/lt-spencer-lane-in-a-oxygen-suit-the-ark.jpg"} alt="" />
                                        </div>
                                        <div className="episodeContent">
                                            <h4>Episode 3</h4>
                                            <p>Get Out and Push</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mvicInfo">
                        <div className="mvicContent">
                            <div className="mvicDesc">
                                <div className="mvicStats">
                                    <div className="blockFav" id="btnFavorite">
                                        <a href="#" className="btnFavorite" title="Add to favorite">
                                            <span className="material-symbols-outlined mvicIcon">favorite</span>
                                            <span>Favorite</span>
                                        </a>
                                    </div>
                                    <div className="blockView">
                                        <span className="material-symbols-outlined mvicIcon">visibility</span>
                                        <span>14809 views</span>
                                    </div>
                                    <div className="blockTrailer">
                                        <a href="#">
                                            <span className="material-symbols-outlined mvicIcon">videocam</span>
                                            <span>Watch trailer</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="detailMod">
                                    <div className="dmThumb"></div>
                                    <h1>The Ark - Season 1</h1>
                                </div>
                                <div className="movieDesc">100 years in the future, planetary colonization missions have begun as a necessity to help secure the survival of the human race. The first of these missions on a spacecraft known as Ark One encounters a catastrophic event causing massive destruction and loss of life. With more than a year left to go before reaching their target planet, a lack of life-sustaining supplies and loss of leadership, the remaining crew must become the best versions of themselves to stay on course and survive.</div>
                                <div className="mvicInfoDetails">
                                    <div className="mviciLeft">
                                        <p>
                                            <strong>Genre:</strong>
                                            <a href="#" title="Drama">Drama</a>, <a href="#" title="Sci-Fi">Sci-Fi</a>
                                        </p>
                                        <p>
                                            <strong>Actor:</strong>
                                            <a href="#" title="Lisa Brenner">Lisa Brenner</a>, <a href="#" title="Christina Wolfe">Christina Wolfe</a>, <a href="#" title="Christie Burke">Christie Burke</a>
                                        </p>
                                        <p>
                                            <strong>Director:</strong>
                                            Dean Devlin
                                        </p>
                                        <p>
                                            <strong>Country:</strong>
                                            <a href="#" title="United States">United States</a>
                                        </p>
                                    </div>
                                    <div className="mviciRight">
                                        <p>
                                            <strong>Duration:</strong> 0m min
                                        </p>
                                        <p>
                                            <strong>Quality:</strong>
                                            <span className="quality">HD</span>
                                        </p>
                                        <p>
                                            <strong>Release:</strong>
                                            <a href="#">2023</a>
                                        </p>
                                        <p>
                                            <strong>IMDb:</strong> 5.1/10
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mvicLike" className="mvicLike">
                        <div className="streamFauxyContentHeading">
                            <div className="headingTitle">
                                <h2>You May Also Like</h2>
                            </div>
                            <div className="headingLink">
                                <a href="#" className="link">See All</a>
                            </div>
                        </div>
                        <OwlCarousel id="streamSlider03" {...utils.streamSlider03} className="owl-carousel">
                            <div className="item">
                                <div className="streamFauxyContentItem">
                                    <a href="#">
                                        <div className="streamFauxyContentItemImage">
                                            <img src={"assets/images/stream/legend_vox.jpg"} alt="" />
                                        </div>
                                        <div className="streamFauxyContentItemText">
                                            <h4>The Legend of Vox Machina</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="item">
                                <div className="streamFauxyContentItem">
                                    <a href="#">
                                        <div className="streamFauxyContentItemImage">
                                            <img src={"assets/images/stream/daniel_spellbound.jpg"} alt="" />
                                        </div>
                                        <div className="streamFauxyContentItemText">
                                            <h4>Daniel Spellbound</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="item">
                                <div className="streamFauxyContentItem">
                                    <a href="#">
                                        <div className="streamFauxyContentItemImage">
                                            <img src={"assets/images/stream/neymar.jpg"} alt="" />
                                        </div>
                                        <div className="streamFauxyContentItemText">
                                            <h4>Neymar: The Perfect Chaos</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="item">
                                <div className="streamFauxyContentItem">
                                    <a href="#">
                                        <div className="streamFauxyContentItemImage">
                                            <img src={"assets/images/stream/bosch_legacy.jpg"} alt="" />
                                        </div>
                                        <div className="streamFauxyContentItemText">
                                            <h4>Bosch: Legacy</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="item">
                                <div className="streamFauxyContentItem">
                                    <a href="#">
                                        <div className="streamFauxyContentItemImage">
                                            <img src={"assets/images/stream/resident_evil.jpg"} alt="" />
                                        </div>
                                        <div className="streamFauxyContentItemText">
                                            <h4>Resident Evil</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="item">
                                <div className="streamFauxyContentItem">
                                    <a href="#">
                                        <div className="streamFauxyContentItemImage">
                                            <img src={"assets/images/stream/in_from_the_cold.jpg"} alt="" />
                                        </div>
                                        <div className="streamFauxyContentItemText">
                                            <h4>In From the Cold</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StreamSeries