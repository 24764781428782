
import React, { useEffect } from 'react';
import Header from "../components/header";
import Footer from '../components/footer';
import Cookies from '../components/cookies';
import Loading from '../components/loading';
import { Results } from '../components/search/results';

export const SearchResultPage =(props)=> {
    return (
      <div>
        <Loading/>
        <div className="overlay-mobileMenu"></div>
        <Header  section="shop"/>
        <Results />
        <Footer />
        <Cookies/>
      </div>
    )
}