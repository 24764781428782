import React, { Component, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

export const BottomHeader = (props) => {
    const mobile = useMediaQuery({ query: `(max-width: 480px)` });
    const [isMobile, setIsMobile] = useState(false)

    const closeMenu = () => {
        if (isMobile) {
            if (document.body.classList.contains("open-menu")) {
                document.body.classList.remove("open-menu")
            }
        }
    }

    useEffect(() => {
        if (mobile) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [props])


    return (
        <div className="navigationWrapper">
            <div className="nav-wrapper">
                <div className="nav-container">
                    <div className="navbar">
                        <ul className="navbar-nav">
                            <li className="dropdown" onClick={closeMenu}>
                                <Link to="/">MARKETPLACE</Link>
                            </li>
                            <li className="dropdown" onClick={closeMenu}>
                                <Link to="/products">PRODUCTS</Link>
                            </li>
                            <li className="dropdown" onClick={closeMenu}>
                                <Link to="/services">SERVICES</Link>
                            </li>
                            {/* <li className="dropdown">
                                    <Link to="/events">EVENTS</Link>
                                </li> */}
                            <li className="dropdown" onClick={closeMenu}>
                                <Link to="/articles">BLOG</Link>
                            </li>
                            <li className="dropdown" onClick={closeMenu}>
                                <Link to="/about-us" onClick={closeMenu}>ABOUT US</Link>
                            </li>
                            <li className="dropdown" onClick={closeMenu}>
                                <Link to="/contact-us">CONTACT US</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};