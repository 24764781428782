

import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../context/shop-context';

const Footer = () => {
    const context = useContext(ShopContext);
    const navigate = useNavigate()
    const [year, setYear] = useState("2022")
    const [values, setValues] = useState({
        email: "",
    })
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    
    const subscribeToNewsletter = () => {
        if (values.email == "") {
            toast.error("Required details missing.");
            return
        }
        context.subscribe(values.email,"all")
        context.scrollToTop()

    }
    const fetchProductsByItemType = (e) => {
        var key = e.currentTarget.getAttribute("data-key")
        navigate("/products", { state: { "itemType": key } })
        context.scrollToTop()
    }
    useEffect(() => {
        setYear(new Date().getFullYear())
    }, [])
    return (
        <div className="footer">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="footerWrapper">
                <div id="responsiveFooter" className="responsiveFooter">
                    <div className="bs-container">
                        <div className="discoverNitrade">
                            <h2 className="h2 text-uppercase">Nitrade Shop</h2>
                        </div>
                        <div className="row navLinks">
                            <div className="grid grid-container-four column-gap-20">
                                <div className="grid-item">
                                    <span className="h4">Our Products</span>
                                    <ul className="ulFontSize">
                                        <li><Link data-key="giftcard" onClick={fetchProductsByItemType}>Gift Cards</Link></li>
                                        <li><Link to="/products"  data-key="merchandise" onClick={fetchProductsByItemType}>Merchandise</Link></li>
                                        <li><a href="https://nitrade.jovinga.com">Jovinga Marketplace</a></li>
                                        <li><Link to="/products">Playstation®</Link> | <Link to="/products">Xbox®</Link> | <Link to="/products">Nintendo®</Link></li>
                                    </ul>
                                </div>
                                <div className="grid-item">
                                    <span className="h4">Our Services</span>
                                    <ul className="ulFontSize">
                                        <li><Link to="/services" onClick={context.scrollToTop}>Streaming</Link></li>
                                        <li><Link to="/articles"  onClick={context.scrollToTop}>Blog</Link></li>
                                        <li><Link to="/events" onClick={context.scrollToTop}>Events</Link></li>
                                    </ul>
                                </div>
                                <div className="grid-item">
                                    <span className="h4">Customer Services</span>
                                    <ul className="ulFontSize">
                                        <li><Link to="/contact-us" onClick={context.scrollToTop}>Contact Us</Link></li>
                                        <li><Link to="/login" onClick={context.scrollToTop}>Where is my order?</Link></li>
                                        <li><Link to="/login" onClick={context.scrollToTop}>Returns information</Link></li>
                                    </ul>
                                </div>
                                <div className="grid-item">
                                    <span className="h4">About Company</span>
                                    <ul className="ulFontSize">
                                        <li><Link to="/about-us" onClick={context.scrollToTop}>About Us</Link></li>
                                        <li><a href="https://landing.nitrade.pro" target="_blank">Landing Page</a></li>
                                        <li><Link to="/terms" onClick={context.scrollToTop}>Terms & Conditions</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="additionalLinks">
                            <div className="grid grid-container-three column-gap-20">
                                <div className="grid-item-3">
                                    <span className="h4">Ways to pay</span>
                                    <ul>
                                        <li>
                                            <img className="img-responsive" src={"assets/images/payment_method.png"} alt="" />
                                        </li>
                                    </ul>
                                </div>
                                <div id="footerEmail" className="grid-item-3">
                                    <div className="footerEmailWrapper">
                                        <span className="h4">Email sign-up</span>
                                        <ul className="ulFontSize">
                                            <li>
                                                <p className="signUpCopy">Sign up for the latest news, game releases and best
                                                    deals.</p>
                                                <div className="inputField">
                                                    <input type="email" name="email" onChange={onChange} className="form-control"
                                                        placeholder="Your email" />
                                                    <button className="btn primary" id="submitEmail" onClick={subscribeToNewsletter}>Sign me up</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="grid-item-3">
                                    <span className="h4">Social Channels</span>
                                    <ul>
                                        <li className="socialIcons">
                                            <a href="https://www.facebook.com/nitrade.info" target="_blank">
                                                <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448">
                                                    <g id="Layer_1-2">
                                                        <path fill="#fff"
                                                            d="M400,0H48C21.5,0,0,21.5,0,48V400c0,26.5,21.5,48,48,48H400c26.5,0,48-21.5,48-48V48c0-26.5-21.5-48-48-48Zm-107.34,126.98h-32.42c-11.45,0-13.83,4.68-13.83,16.52v28.61h46.25l-4.46,50.23h-41.8v150.01h-59.9V222.96h-31.16v-50.86h31.16v-40.05c0-37.58,20.1-57.21,64.68-57.21h41.48v52.13Z">
                                                        </path>
                                                    </g>
                                                </svg>
                                            </a>
                                            <a href="https://twitter.com/nitrade_app" target="_blank">
                                                <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448">
                                                    <g id="Layer_1-2">
                                                        <path fill="#fff"
                                                            d="M400,0H48C21.5,0,0,21.5,0,48V400c0,26.5,21.5,48,48,48H400c26.5,0,48-21.5,48-48V48c0-26.5-21.5-48-48-48Zm-48.9,158.8c.2,2.8,.2,5.7,.2,8.5,0,86.7-66,186.6-186.6,186.6-37.2,0-71.7-10.8-100.7-29.4,5.3,.6,10.4,.8,15.8,.8,30.7,0,58.9-10.4,81.4-28-28.8-.6-53-19.5-61.3-45.5,10.1,1.5,19.2,1.5,29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7,4.9,18.9,7.9,29.6,8.3-18.27-12.15-29.24-32.66-29.2-54.6,0-12.2,3.2-23.4,8.9-33.1,32.3,39.8,80.8,65.8,135.2,68.6-9.3-44.5,24-80.6,64-80.6,18.9,0,35.9,7.9,47.9,20.7,14.8-2.8,29-8.3,41.6-15.8-4.9,15.2-15.2,28-28.8,36.1,13.2-1.4,26-5.1,37.8-10.2-8.9,13.1-20.1,24.7-32.9,34Z">
                                                        </path>
                                                    </g>
                                                </svg>
                                            </a>
                                            <a href="https://www.instagram.com/nitrade_gamers" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448">
                                                    <g id="Layer_2" data-name="Layer 2">
                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                            <circle fill="#fff" cx="224" cy="224.06" r="49.58"
                                                                transform="translate(-45.49 57.46) rotate(-13.28)"></circle>
                                                            <path fill="#fff"
                                                                d="M284.12,103c-15.69-.72-20.4-.87-60.12-.87s-44.41.15-60.11.86c-40.35,1.83-59.13,21-61,61-.71,15.7-.87,20.4-.87,60.11s.15,44.43.87,60.11c1.83,39.93,20.56,59.14,61,61,15.68.72,20.39.87,60.11.87s44.43-.15,60.12-.87c40.35-1.84,59.13-21,61-61,.7-15.68.85-20.38.85-60.11s-.14-44.42-.85-60.1C343.26,124,324.43,104.83,284.12,103ZM224,300.46a76.39,76.39,0,1,1,76.38-76.4A76.4,76.4,0,0,1,224,300.46Zm79.41-137.94a17.85,17.85,0,1,1,17.84-17.85A17.86,17.86,0,0,1,303.41,162.52Z">
                                                            </path>
                                                            <path fill="#fff"
                                                                d="M400,0H48A48,48,0,0,0,0,48V400a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V48A48,48,0,0,0,400,0ZM371.85,285.4c-2.45,54.07-32.54,84-86.51,86.52-15.87.72-20.94.89-61.34.89s-45.46-.17-61.34-.89c-54.07-2.48-84-32.5-86.52-86.52-.72-15.87-.89-20.94-.89-61.34s.17-45.45.9-61.33c2.48-54.07,32.51-84,86.53-86.53,15.86-.72,20.92-.89,61.32-.89s45.47.17,61.35.91c54.07,2.47,84.08,32.55,86.51,86.52.72,15.87.89,20.92.89,61.32S372.58,269.53,371.85,285.4Z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerCopy">
                        <div className="bs-container">
                            <p>&copy; Copyrights {year} Nitrade Shop - All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer