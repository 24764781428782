import axios from 'axios';
import React, { Component, useContext, useState } from 'react';
import { useEffect } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import ReactPlaceholder from 'react-placeholder/lib';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';


export const BlogSubscribe = () => {
    const context = useContext(ShopContext);
    const navigate = useNavigate();
    const [inCart, setInCart] = useState(false)
    const { state } = useLocation()
    const [values, setValues] = useState({
        blog: ""
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const viewBlog = (e) => {
        var key = e.currentTarget.getAttribute("data-key")
        navigate("/articles")
        context.scrollToTop()
    }
    const subscribeBlog = () => {
        if (values.blog == "") {
            toast.error("Select a package.")
            return;
        }
        context.addItem(values.blog, "blog", 1) 
    }

    useEffect(() => {

    }, [state])

    return (
        <main id="blogSubscription">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <section className="subscription-page">
                <div className="container">
                    <section className="subpt-boxy">
                        <div className="subpt-boxy__top">
                            <h1>Nitrade Connect</h1>
                            <p>Nitrade Connect is a service where gamers share their gaming inspiration, tips and tricks, and compete with one another to improve their skills. Get access to coaching services(fortnite, COD), e-Sports events and interviews of popular e-Sports gamers. Once you subscribe you get entertainment news updates on popular movies, series, matchups, music. For those who want to go pro, you can get access to e-Sports events, compete, win and become a master.</p>
                        </div>
                        <section className="subpt-boxy__middle">
                            <div className="subpt-boxy__offer-container">
                                <div className="subpt-boxy__offer-grid">
                                    <div className="subpt-boxy__offer-item best-value">
                                        <div className="subpt-boxy__offer-content">
                                            <div className="subpt-boxy__offer-description">
                                                <h2 className="subpt-boxy__offer-heading">Blog</h2>
                                                <div className="subpt-boxy__offer-body">
                                                    <p>Entertainment Newsletter</p>
                                                    <p>Access to interviews(e-Sports athletes)</p>
                                                    <p>Tips & tricks for improving skills</p>
                                                    <p>Customer Support</p>
                                                </div>
                                            </div>
                                            <div className="subpt-boxy__offer-selection">
                                                <div className="subpt-boxy__offer-options">
                                                    <div className="subpt-boxy__offer-option">
                                                        <div className="subpt-boxy__offer-option-button">
                                                            <div className="subpt-boxy__form-radio-button">
                                                                <input onChange={onChange} value="113" type="radio" name="blog" />
                                                                <div className="subpt-boxy__form-radio-button-box"></div>
                                                                <label className="subpt-boxy__form-check-label"></label>
                                                            </div>
                                                        </div>
                                                        <div className="subpt-boxy__offer-option-container">
                                                            <div className="subpt-boxy__offer-option-heading">
                                                                <h2>1 month</h2>
                                                            </div>
                                                            <h3><p>$6.00</p></h3>
                                                            <h4><p>Cancel at any time</p></h4>
                                                        </div>
                                                    </div>
                                                    <div className="subpt-boxy__offer-option">
                                                        <div className="subpt-boxy__offer-option-button">
                                                            <div className="subpt-boxy__form-radio-button">
                                                                <input onChange={onChange} value="114" type="radio" name="blog" />
                                                                <div className="subpt-boxy__form-radio-button-box"></div>
                                                                <label className="subpt-boxy__form-check-label"></label>
                                                            </div>
                                                        </div>
                                                        <div className="subpt-boxy__offer-option-container">
                                                            <div className="subpt-boxy__offer-option-heading">
                                                                <h2>3 months</h2>
                                                            </div>
                                                            <h3><p>$15.00</p></h3>
                                                            <h4><p>Cancel at any time</p></h4>
                                                        </div>
                                                    </div>
                                                    <div className="subpt-boxy__offer-option">
                                                        <div className="subpt-boxy__offer-option-button">
                                                            <div className="subpt-boxy__form-radio-button">
                                                                <input onChange={onChange} value="115" type="radio" name="blog" />
                                                                <div className="subpt-boxy__form-radio-button-box"></div>
                                                                <label className="subpt-boxy__form-check-label"></label>
                                                            </div>
                                                        </div>
                                                        <div className="subpt-boxy__offer-option-container">
                                                            <div className="subpt-boxy__offer-option-heading">
                                                                <h2>6 months</h2>
                                                                <span>SAVE OVER 15%</span>
                                                            </div>
                                                            <h3><p>$35.00</p></h3>
                                                            <h4><p>Cancel at any time</p></h4>
                                                        </div>
                                                    </div>
                                                    <div className="subpt-boxy__offer-option">
                                                        <div className="subpt-boxy__offer-option-button">
                                                            <div className="subpt-boxy__form-radio-button">
                                                                <input onChange={onChange} value="116" type="radio" name="blog" />
                                                                <div className="subpt-boxy__form-radio-button-box"></div>
                                                                <label className="subpt-boxy__form-check-label"></label>
                                                            </div>
                                                        </div>
                                                        <div className="subpt-boxy__offer-option-container">
                                                            <div className="subpt-boxy__offer-option-heading">
                                                                <h2>1 year</h2>
                                                                <span></span>
                                                            </div>
                                                            <h3><p>$60.00</p></h3>
                                                            <h4><p>Cancel at any time</p></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="offer-buttons selected-group">
                                                    <button onClick={subscribeBlog} className="btn btn--primary" type="button">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="subpt-boxy__offer-item">
                                        <div className="subpt-boxy__offer-content">
                                            <div className="subpt-boxy__offer-description">
                                                <h2 className="subpt-boxy__offer-heading">Nitrade Pro</h2>
                                                <div className="subpt-boxy__offer-body">
                                                    <p>Coaching Services</p>
                                                    <p>Access to interviews</p>
                                                    <p>Entertainment Newsletter</p>
                                                    <p>Access to e-Sports events</p>
                                                    <p>Customer Support</p>
                                                </div>
                                            </div>
                                            <div className="subpt-boxy__offer-selection">
                                                <div className="subpt-boxy__offer-options">
                                                    <div className="subpt-boxy__offer-option">
                                                        <div className="subpt-boxy__offer-option-button">
                                                            <div className="subpt-boxy__form-radio-button">
                                                                <input onChange={onChange} value="117" type="radio" name="blog" />
                                                                <div className="subpt-boxy__form-radio-button-box"></div>
                                                                <label className="subpt-boxy__form-check-label"></label>
                                                            </div>
                                                        </div>
                                                        <div className="subpt-boxy__offer-option-container">
                                                            <div className="subpt-boxy__offer-option-heading">
                                                                <h2>1 month</h2>
                                                            </div>
                                                            <h3><p>$20.00</p></h3>
                                                            <h4><p>Cancel at any time</p></h4>
                                                        </div>
                                                    </div>
                                                    <div className="subpt-boxy__offer-option">
                                                        <div className="subpt-boxy__offer-option-button">
                                                            <div className="subpt-boxy__form-radio-button">
                                                                <input name="blog" onChange={onChange} value="118" type="radio" />
                                                                <div className="subpt-boxy__form-radio-button-box"></div>
                                                                <label className="subpt-boxy__form-check-label"></label>
                                                            </div>
                                                        </div>
                                                        <div className="subpt-boxy__offer-option-container">
                                                            <div className="subpt-boxy__offer-option-heading">
                                                                <h2>3 months</h2>
                                                            </div>
                                                            <h3><p>$50.00</p></h3>
                                                            <h4><p>Cancel at any time</p></h4>
                                                        </div>
                                                    </div>
                                                    <div className="subpt-boxy__offer-options">
                                                        <div className="subpt-boxy__offer-option">
                                                            <div className="subpt-boxy__offer-option-button">
                                                                <div className="subpt-boxy__form-radio-button">
                                                                    <input name="blog" onChange={onChange} value="119" type="radio" />
                                                                    <div className="subpt-boxy__form-radio-button-box"></div>
                                                                    <label className="subpt-boxy__form-check-label"></label>
                                                                </div>
                                                            </div>
                                                            <div className="subpt-boxy__offer-option-container">
                                                                <div className="subpt-boxy__offer-option-heading">
                                                                    <h2>6 months</h2>
                                                                    <span>SAVE OVER 15%</span>
                                                                </div>
                                                                <h3><p>$90.00</p></h3>
                                                                <h4><p>Cancel at any time</p></h4>
                                                            </div>
                                                        </div>
                                                        <div className="subpt-boxy__offer-option">
                                                            <div className="subpt-boxy__offer-option-button">
                                                                <div className="subpt-boxy__form-radio-button">
                                                                    <input name="blog" onChange={onChange} value="120" type="radio" />
                                                                    <div className="subpt-boxy__form-radio-button-box"></div>
                                                                    <label className="subpt-boxy__form-check-label"></label>
                                                                </div>
                                                            </div>
                                                            <div className="subpt-boxy__offer-option-container">
                                                                <div className="subpt-boxy__offer-option-heading">
                                                                    <h2>1 year</h2>
                                                                    <span></span>
                                                                </div>
                                                                <h3><p>$165.00</p></h3>
                                                                <h4><p>Cancel at any time</p></h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="offer-buttons selected-group">
                                                        <button onClick={subscribeBlog} className="btn btn--primary" type="button">Subscribe</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </section>
        </main>
    )
}

export default BlogSubscribe