import React from 'react';
import Header from "../components/header";
import Footer from '../components/footer';
import { HomeContent } from '../components/index/home-content';
import Cookies from '../components/cookies';
import Loading from '../components/loading';

export const Index = (props) => {
  

  return (
    <>
      <Loading/>
      <div className="overlay-mobileMenu"></div>
      <Header section="shop"/>
      <HomeContent />
      <Footer />
      <Cookies/>
    </>
  )
}

export default Index