

import { Link} from 'react-router-dom';
const AboutUs = () => {
    return (
        <main id="aboutPage">
            <div className="about-pagey">
                <div className="about-pagey__container">
                    <div className="about-pagey__heading">
                        <h1>About Us</h1>
                        <p> Nitrade is an online marketplace that connects gamers, offering a platform where they can buy or rent digital games. Our community-driven approach cultivates entertainment and inspires gamers to share their gaming experiences, compete with one another, and improve their skills. We offer a wide variety of games and gift card deals for popular platforms such as PlayStation, Nintendo, Xbox, Netflix, Uber, iTunes, Google Play, and Amazon.</p><br/>
                        <p>At Nitrade, we strive to provide a valuable community for gamers, where they can not only buy and sell their games and gaming equipment but also share tips and tricks and compete with one another. We believe in educating our customers on how to save money and get the most out of their gaming experience. Our ultimate goal is to offer gamers an affordable and enjoyable way to access the latest and greatest games.</p><br/>
                        <p>Our blog is filled with stories from gamers and athletes who have overcome challenges, learned valuable lessons, and achieved success in the competitive sports world. We believe that these stories can help others to do the same, whether it's improving their skills, developing a better mindset, or just finding inspiration to keep going.</p><br/>
                        <p>In summary, Nitrade is the go-to platform for gamers who want to enjoy their favorite games without spending a fortune. <Link to="/contact-us">Contact us</Link> for more information on how we can help you save money and get the most out of your gaming experience. </p>
                    </div>
                    <div className="about-pagey__banner">
                        <img src={"assets/images/about-us.jpg"} alt="" />
                    </div>
                    <div className="about-pagey__content">

                        <h3>Our Mission</h3>
                        <p>Our mission at Nitrade is to provide gamers with a unified platform to buy easily, rent, and share digital games, gift cards, and gaming experiences. We strive to create a safe, transparent, and enjoyable environment for gamers of all ages and backgrounds. We want to enable gamers to experience the most out of their gaming experience.</p>
                        <h3>Our Vision</h3>
                        <p>Educate gamers on ways to save while gaming, enabling gamers share their gaming experiences and taste.</p>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default AboutUs