
import React, { useEffect } from 'react';
import Header from "../components/header";
import Footer from '../components/footer';
import { BlogSingle } from '../components/blog/blog-single';
import BlogHome from '../components/blog/blog-home';
import Cookies from '../components/cookies';
import Loading from '../components/loading';

export const BlogHomePage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="shop" />
      <BlogHome />
      <Footer />
      <Cookies />
    </div>
  )
}
export default BlogHomePage
