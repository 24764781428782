import React, { useContext, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import ShopContext from '../../context/shop-context';

const CartItem = (props) => {
    const context = useContext(ShopContext);
    const [quantity, setQuantity] = useState(1)
    const remove = (e) => {


        var itemType = props.cartItem.itemType
        var id = ""
        if (itemType == "service") {
            id = props.cartItem.variant.subscriptionID
        } else {
            id = props.cartItem.variant.variantID
        }
        toast.success('Updating cart')
        props.removeItem(id, itemType)
    }
    const add = (e) => {
        var itemType = props.cartItem.itemType
        var id = ""
        if (itemType == "service") {
            id = props.cartItem.variant.subscriptionID
        } else {
            id = props.cartItem.variant.variantID
        }
        var newQty = quantity + 1
        toast.success('Updating cart')
        setQuantity(newQty)
        props.addItem(id, itemType, newQty)
    }
    const sub = (e) => {
        var itemType = props.cartItem.itemType
        var id = ""
        if (itemType == "service") {
            id = props.cartItem.variant.subscriptionID
        } else {
            id = props.cartItem.variant.variantID
        }
        if (quantity > 1) {
            var newQty = quantity - 1
            setQuantity(newQty)
            toast.success('Updating cart')
            props.subItem(id, itemType, newQty)
        }
    }
    useEffect(() => {
        setQuantity(props.cartItem.quantity)
    }, [props])

    return (
        <div className="cartProductItem">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="cartProductImgAction">
                <div className="cartProductImage">
                    {props.cartItem.itemType == "service" ? props.cartItem.item.image != "" && props.cartItem.item.image != undefined ?
                        <img  className="responsive-image" src={context.awsS3url + "/images/" + props.cartItem.item.image} alt="" /> :
                        <img  className="responsive-image" src={"assets/images/eject.png"} alt="" /> :
                        props.cartItem.item.imageURL != "" && props.cartItem.item.imageURL != undefined ?
                            <img  className="responsive-image" src={context.awsS3url + "/images/" + props.cartItem.item.imageURL} alt="" /> :
                            <img  className="responsive-image" src={"assets/images/eject.png"} alt="" />
                    }
                </div>
                <div className="cartProductAction" onClick={remove}>
                    <span className="material-symbols-outlined">delete</span>
                    <span className="text-uppercase w-600">Remove</span>
                </div>
            </div>
            <div className="cartProductDesc">
                <p>{props.cartItem.item.name}({props.cartItem.itemType == "product" ? props.cartItem.variant.name : props.cartItem.variant.period})</p>
                {/* <p>Description: {props.cartItem.item.summary}</p>
                <p>Category: {props.cartItem.variant.itemType}</p> */}
            </div>
            <div className="cartProductPriceQuanity">
                <div className="cartProductPrice">
                    <span className="price">Ksh. {props.cartItem.amount}</span>
                </div>
                <div className="cartProductAction">
                    <button className="quantityIncrease" onClick={add}>
                        <span className="material-symbols-outlined">add</span>
                    </button>
                    <span className="quantityNumber">{quantity}</span>
                    <button className="quantityDescrase" onClick={sub}>
                        <span className="material-symbols-outlined">remove</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CartItem