import axios from 'axios';
import React, { Component } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardAside from './dashboard-aside';


export const SavedItems = (props) => {
    // const context = useContext(ShopContext);

    return (
        <div id="dashboard">
            <div className="dashboardPagey">
                <div className="container">
                    <div className="dashboardPageyWrapper">
                        <div className="dashboardPageyGrid">
                            <DashboardAside active="saved-items" />
                            <div className="dashboardPageyContent">
                                <div className="cardBoxy">
                                    <div className="dashboardPageyHeader">
                                        <h2>Saved Items</h2>
                                    </div>
                                    <div className="dashboardPageyBody"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SavedItems