import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import shopContext from "../../context/shop-context";

export const BlogItemResult = (props) => {
    const context = useContext(shopContext);
    const navigate = useNavigate();

    const viewBlogItem = () => {
        navigate("/article", { state: { "article": props.article.articleID } })
        context.scrollToTop()
    }
    
    return (
        <div className="cardBoxy" onClick={viewBlogItem}>
            <div className="searchListItemGrid">
                <div className="searchListItemImage">
                    <img src={context.awsS3url + "/images/" + props.article.photo} alt="" />
                </div>
                <div className="searchListItemContent">
                    <div className="searchListItemTitle">
                        <a href="#!">
                            <div className="sm-title">{props.article.title}</div>
                        </a>
                    </div>
                    <div className="searchListItemDetails">
                        <div className="listItemDetailsPrimary">
                            <div className="nt-block-post-item">
                                <div className="nt-block-post-top-meta">
                                    <div className="nt-block-post-author">
                                        <span className="author-avatar">
                                            <img loading="lazy" src={"assets/images/blog/no_photo.jpg"} alt="No photo available" width="25" height="25" />
                                        </span>
                                        <span className="nt-blocks-author-pretext">By {props.article.author}</span>
                                    </div>
                                    <div className="nt-block-meta-divider nt-block-meta-has-divider">|</div>
                                    <div className="kt-blocks-date"><time className="nt-blocks-post-date">{props.article.dateAdded}</time></div>
                                </div>
                            </div>
                        </div>
                        <div className="listItemDetailsPrimary">
                            <div className="renderText" dangerouslySetInnerHTML={{ "__html": props.article.content.slice(0, 180) + "..." }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}