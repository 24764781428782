
import React, { useEffect } from 'react';
import Header from "../components/header";
import Footer from '../components/footer';
import Cookies from '../components/cookies';
import Loading from '../components/loading';
import StreamHome from '../components/tv/stream-home';
import Movie from '../components/tv/movies';
import Series from '../components/tv/series';

export const SeriesPage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="shop" />
      <Series />
      <Footer />
      <Cookies />
    </div>
  )
}
export default SeriesPage
