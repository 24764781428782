import axios from 'axios';
import React, { Component, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CartItem from './cartitem';
import ShopContext from '../../context/shop-context';
import { Toaster } from 'react-hot-toast';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";


// /user/:uid/cart/:status
export const Cart = () => {
    const context = useContext(ShopContext);
    const navigate = useNavigate()
    const {state} = useLocation()

    const viewAddress = () => {
        if (context.cart.addressNeeded) {
            navigate("/checkout-address", { state: { "cart": context.cart, "baseUrl": context.baseUrl } })
        } else {
            navigate("/checkout-payment", { state: { "cart": context.cart, "baseUrl": context.baseUrl } })
        }
    }
    useEffect(()=>{
        context.fetchCartByIP()
    },[state])

    return (
        <ShopContext.Consumer>
            {context => (
                <div id="cartPage">
                    <Toaster
                        position="bottom-right"
                        reverseOrder={false}
                    />
                    <div className="cartProduct">
                        <div className="container">
                            <div className="cartProductWrapper">
                                <div className="cartProductGrid">
                                    <div className="cartProductContent">
                                        <div className="cardBoxy">
                                            <div className="cartProductHeader">
                                                <h4 className="h4">Cart <span>({context.cart.ID != 0 ? context.cart.cartItems.length : 0})</span></h4>
                                            </div>
                                            <div className="cartProductContent">
                                                <div className="cartProductList">
                                                    {context.cart.cartID != 0 ? context.cart.cartItems.length != 0 ? context.cart.cartItems.map((cartItem, index) => (
                                                        <CartItem removeItem={context.removeItem} subItem={context.subItem} addItem={context.addItem} key={index} cartItem={cartItem} />
                                                    )) :
                                                        <div className="cartProductEmpty">
                                                            <div className="cartProductEmptyIcon">
                                                                <span className="material-symbols-outlined">shopping_cart</span>
                                                            </div>
                                                            <p>No items in cart</p>
                                                        </div>
                                                        :
                                                        <div className="cartProductEmpty">
                                                            <div className="cartProductEmptyIcon">
                                                                <span className="material-symbols-outlined">shopping_cart</span>
                                                            </div>
                                                            <p>No cart found</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="cartProductAside">
                                        <div className="cartProductSummary cardBoxy">
                                            <div className="cartProductHeader">
                                                <h4 className="h4">CART SUMMARY</h4>
                                            </div>
                                            <div className="cartProductSummaryContainer">
                                                <div className="cartProductSummaryPrice">
                                                    <div className="cartProductSubTotalText">Subtotal</div>
                                                    <div className="cartProductSubTotalPrice">Ksh. {context.cart.subtotal}</div>
                                                </div>
                                                <div className="cartProductSummaryPrice">
                                                    <div className="cartProductSubTotalText">DST</div>
                                                    <div className="cartProductSubTotalPrice">Ksh. {context.cart.tax}</div>
                                                </div>
                                                <div className="cartProductSummaryPrice">
                                                    <div className="cartProductSubTotalText">Local delivery fees</div>
                                                    <div className="cartProductSubTotalPrice">Ksh. {context.cart.delivery} </div>
                                                </div>
                                                <div className="cartProductSummaryPrice">
                                                    <div className="cartProductSubTotalText">Total</div>
                                                    <div className="cartProductSubTotalPrice">Ksh. {context.cart.amount}</div>
                                                </div>
                                                <div className="cartProductCheckout">
                                                    <button className="btn btn--primary" onClick={viewAddress}>
                                                        <span>CHECKOUT</span>
                                                        {/* <span>(Ksh. {context.cart.amount})</span> */}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="productSingleChat cardBoxy">
                                            <span className="chatBoxTitle">Questions about this product/service?</span>
                                            <a href="https://ticket.nitrade.pro/newissue" target="_blank" className="chatBoxLink">
                                                <span className="material-symbols-outlined chatBoxIcon">forum</span>
                                                <span className="chatBoxText text-uppercase">CHAT</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ShopContext.Consumer>

    )
}