import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';
import DashboardAside from './dashboard-aside';


export const EditAddress = (props) => {
    const context = useContext(ShopContext)

    const deleteAccount = () => { }
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        city: "",
        street:"",
        area:"",
        password: "",
        confirmPassword: "",
    })
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    useEffect(()=>{
        context.fetchUser(localStorage.getItem("userID"))
        context.fetchAddresses(localStorage.getItem("userID"))
    },[])
    return (
        <div id="dashboard">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="dashboardPagey">
                <div className="container">
                    <div className="dashboardPageyWrapper">
                        <div className="dashboardPageyGrid">
                            <DashboardAside active="edit-address" />
                            <div className="dashboardPageyContent">
                                <div className="cardBoxy">
                                    <div className="dashboardPageyHeader">
                                        <h2 className="headingWithBack">
                                            <a href="dashboard-address-book.html" className="headingLink">
                                                <span className="material-symbols-outlined headingArrowButton">arrow_back</span>
                                            </a>
                                            <span className="headingText">Edit Address</span>
                                        </h2>
                                    </div>
                                    <div className="dashboardPageyBody">
                                        <div className="dashboardPageyForm">
                                            <form className="dashboardPageyformBlock">
                                                <div className="inputGridBlock">
                                                    <div className="inputBlock">
                                                        <label>First Name</label>
                                                        <input name="fistName" onChange={onChange} value={context.user.firstName} type="text" className="form-control" />
                                                    </div>
                                                    <div className="inputBlock">
                                                        <label>Last Name</label>
                                                        <input name="lastName" onChange={onChange} value={context.user.lastName}  type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="inputGridBlock">
                                                    <div className="inputBlock">
                                                        <label>Phone Number</label>
                                                        <input name="phoneNumber" onChange={onChange} value={context.user.phoneNumber} type="text" className="form-control" />
                                                    </div>
                                                    <div className="inputBlock">
                                                        <label>City</label>
                                                        <div className="selectWrapper">
                                                            <select  name="city" onChange={onChange}  className="form-control selectBox">
                                                                <option value="nairobi">Nairobi</option>
                                                                <option value="mombasa">Mombasa</option>
                                                                <option value="kisumu">Kisumu</option>
                                                                <option value="nakuru">Nakuru</option>
                                                                <option value="kisii"> Kisii</option>
                                                                <option value="kiambu">Kiambu</option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputGridBlock">
                                                    <div className="inputBlock">
                                                        <label>Area</label>
                                                        <input name="area" onChange={onChange}  type="text" className="form-control" />
                                                    </div>
                                                    <div className="inputBlock">
                                                        <label>Address</label>
                                                        <input name="street" onChange={onChange}  type="text" className="form-control" placeholder='Street Name / Building / Apartment No. / Floor'/>
                                                    </div>
                                                </div>
                                                <button className="btn btn--primary w-full">
                                                    <span className="text-uppercase">Save</span>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAddress