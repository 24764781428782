import React, { useState } from 'react';
import Header from "../../components/header";
import Footer from '../../components/footer';
import Loading from '../../components/loading';
import Cookies from '../../components/cookies';
import AccountPreferences from '../../components/dashboard/account-preference';

export const AccountPreferencesPage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="shop" />
      <AccountPreferences />
      <Footer />
      <Cookies />
    </div>
  )
}

export default AccountPreferencesPage