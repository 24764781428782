import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';
import DashboardAside from './dashboard-aside';


export const AccountManagement = () => {
    const context = useContext(ShopContext)
    const deleteAccount = () => { }
    const updateAccount = () => { }
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    })
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    useEffect(()=>{
        context.fetchUser(localStorage.getItem("userID"))
    },[])

    return (
        <div id="dashboard">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="dashboardPagey">
                <div className="container">
                    <div className="dashboardPageyWrapper">
                        <div className="dashboardPageyGrid">
                            <DashboardAside active="account-management" />
                            <div className="dashboardPageyContent">
                                <div className="cardBoxy">
                                    <div className="dashboardPageyHeader">
                                        <h2 className="headingWithBack">
                                            <a href="dashboard-address-book.html" className="headingLink">
                                                <span className="material-symbols-outlined headingArrowButton">arrow_back</span>
                                            </a>
                                            <span className="headingText">Account Management</span>
                                        </h2>
                                    </div>
                                    <div className="dashboardPageyBody">
                                        <div className="dashboardPageyForm">
                                            <form className="dashboardPageyformBlock">
                                                <div className="inputGridBlock">
                                                    <div className="inputBlock">
                                                        <label>First Name</label>
                                                        <input name="firstName" onChange={onChange} value={context.user.firstName} type="text" className="form-control" />
                                                    </div>
                                                    <div className="inputBlock">
                                                        <label>Last Name</label>
                                                        <input name="lastName" onChange={onChange} value={context.user.lastName} type="text" className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="inputGridBlockFull">
                                                    <div className="inputBlock">
                                                        <label>Email</label>
                                                        <input name="email" onChange={onChange} value={context.user.email} type="text" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="inputGridBlock">
                                                    <div className="inputBlock">
                                                        <label>Pasword</label>
                                                        <input name="password" onChange={onChange} type="password" className="form-control" />
                                                    </div>
                                                    <div className="inputBlock">
                                                        <label>Confirm Password</label>
                                                        <input name="confirmPassword" onChange={onChange} type="password" className="form-control" />
                                                    </div>
                                                </div>
                                                <button onClick={updateAccount} className="btn btn--primary w-full">
                                                    <span className="text-uppercase">UPDATE</span>
                                                </button>

                                                <button onClick={deleteAccount} style={{ marginTop: "10px" }} className="btn btn--secondary w-full">
                                                    <span className="text-uppercase">DELETE ACCOUNT</span>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountManagement