import Swal from "sweetalert2";
import { useContext, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { MiniHeader } from "./header/mini-header";
import ShopContext from "../context/shop-context";
import toast, { Toaster } from "react-hot-toast";

const RegisterContent = () => {
    const context = useContext(ShopContext);
    const [values, setValues] = useState({
        alias: "",
        phoneNumber: "",
        email: "",
        password: "",
        referredBy: "",
        confirmPassword: "",
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const submitValue = () => {
        if ((values.phoneNumber == "") || (values.password == "")) {
            toast.error("Required details missing.");
            return
        }
        if (values.password != values.confirmPassword) {
            toast.error("Passwords not matching");
            return
        }
        context.register(values.phoneNumber, values.password, values.email, values.alias, values.referredBy)
    }

    return (
        <div>
            <main id="content">
                <Toaster
                    position="bottom-right"
                    reverseOrder={false}
                />
                <div className="form-pagey bg-gray">
                    <div className="form-pagey__container">
                        <div className="form-pagey__content">
                            <div className="form-pagey__boxy">
                                <div className="form-pagey__navigation">
                                    <Link to="/login">
                                        <div className="dialog-box-tab disabled sign-in">SIGN IN</div>
                                    </Link>
                                    <Link to="/register">
                                        <div className="dialog-box-tab">REGISTER</div>
                                    </Link>
                                </div>
                                <div className="form-pagey__form">
                                    <form>
                                        <div className="input-container">
                                            <label className="label">Email Address</label>
                                            <input type="email" name="email" className="form-control" onChange={onChange} placeholder="Your email" />
                                        </div>
                                        <div className="input-container">
                                            <label className="label">Username</label>
                                            <input type="text" name="alias" className="form-control" onChange={onChange} placeholder="Preferably your IG username eg john_doe" />
                                        </div>
                                        <div className="input-container">
                                            <label className="label">Phone Number<span>*</span></label>
                                            <input type="text" name="phoneNumber" className="form-control" onChange={onChange} placeholder="Your phone number" />
                                        </div>
                                        <div className="input-container">
                                            <label className="label">Referral Code</label>
                                            <input type="text" name="referredBy" className="form-control" onChange={onChange} placeholder="Referral Code" />
                                        </div>
                                        <div className="input-container">
                                            <label className="label">Password</label>
                                            <input type="password" name="password" className="form-control" onChange={onChange} placeholder="Password" />
                                        </div>
                                        <div className="input-container">
                                            <label className="label">Confirm Password</label>
                                            <input type="password" name="confirmPassword" className="form-control" onChange={onChange} placeholder="Confirm password" />
                                        </div>
                                        <div className="policy">By clicking “SIGN UP” you agree to the Nitrade <Link to="/terms">Terms of Use</Link></div>
                                        <button type="button" className="btn btn--primary btn--large w-full text--uppercase" onClick={submitValue}>SIGN UP</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

    )
}

export default RegisterContent