

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import ShopContext from '../context/shop-context';
import toast, { Toaster } from 'react-hot-toast';

const LoginContent = () => {
    const context = useContext(ShopContext);
    const [to,setTo]=useState("");

    const navigate = useNavigate()
    const { state } = useLocation();

    const [values, setValues] = useState({
        phoneNumber: "",
        password: "",
    })
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const submitValue = () => {
        if ((values.phoneNumber == "")||(values.password == ""))  {
            toast.error("Required details missing.");
            return
        }
        context.login(values.phoneNumber,values.password,to)
    }
    
    useEffect(()=>{
        if (state != null) {
            if ("to" in state) {
                setTo(state.to)
            } 
        }
    },[state])

    return (
        <main id="content">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="form-pagey bg-gray">
                <div className="form-pagey__container">
                    <div className="form-pagey__content">
                        <div className="form-pagey__boxy">
                            <div className="form-pagey__navigation">
                                <Link to="/login">
                                    <div className="dialog-box-tab">SIGN IN</div>
                                </Link>
                                <Link to="/register">
                                    <div className="dialog-box-tab disabled register">REGISTER</div>
                                </Link>
                            </div>
                            <div className="form-pagey__form">
                                <form>
                                    <div className="input-container">
                                        <label className="label">Phone Number</label>
                                        <input type="text" name="phoneNumber" className="form-control" onChange={onChange} placeholder="Your phone number eg +254727...." />
                                    </div>
                                    <div className="input-container">
                                        <label className="label">Password</label>
                                        <input type="password" name="password" className="form-control" onChange={onChange} placeholder="password" />
                                    </div>
                                    <div className="forgot-password">
                                        <Link to="/forgot-password" className="w-600">Forgot your password?</Link>
                                    </div>
                                    <button type="button" className="btn btn--primary btn--large w-full text--uppercase" onClick={submitValue}>SIGN IN</button>
                                    <div className="form-pagey__alt text--center">Don't have an account? <Link to="/register" className="w-600">Create one</Link></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default LoginContent