import React from 'react';
import Header from "../../components/header";
import Footer from '../../components/footer';
import EditAddress from '../../components/dashboard/edit-address';
import Loading from '../../components/loading';
import Cookies from '../../components/cookies';


export const EditAddressPage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="dashboard" />
      <EditAddress />
      <Footer />
      <Cookies />
    </div>
  )
}

export default EditAddressPage