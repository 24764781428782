

import axios from 'axios';
import { useContext, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../context/shop-context';

const ForgotPasswordContent = (props) => {
    const navigate = useNavigate()
    const context = useContext(ShopContext)
    const [values, setValues] = useState({
        phoneNumber: "",
    })
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const submitValue = () => {
        if (values.phoneNumber == "") {
            toast.error("Required details missing.");
            return
        }
        context.requestAuthReset(values.phoneNumber)
    }

    return (
        <main id="content">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="form-pagey bg-gray">
                <div className="form-pagey__container">
                    <div className="form-pagey__content">
                        <div className="form-pagey__boxy">
                            <div className="form-pagey__form">
                                <h2 className="h2">Reset Your Password</h2>
                                <p className="p">Fear not. We’ll text you instructions to reset your password.</p>
                                <form>
                                    <div className="input-container">
                                        <label className="label">Phone Number</label>
                                        <input type="text" name="phoneNumber" onChange={onChange} placeholder='Your phone number' className="form-control" />
                                    </div>
                                    <button type="button" className="btn btn--accent btn--large w-full text--uppercase" onClick={submitValue}>Reset Password</button>
                                </form>
                                <div className="form-pagey__alt text--center"><Link to="/login" className="w-600">Return to Login</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ForgotPasswordContent