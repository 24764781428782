import axios from 'axios';
import React, { Component, useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../../../context/shop-context';
import DashboardAside from '../dashboard-aside';
import SubscriptionItem from './subscription-item';


export const Subscriptions = () => {
    const context = useContext(ShopContext);

    const [subscriptions, setSubscriptions] = useState([]);
    
    useEffect(() => {
        var userID = localStorage.getItem("userID")
        context.fetchSubscriptions(userID)
    }, [])



    return (
        <div id="dashboard">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="dashboardPagey">
                <div className="container">
                    <div className="dashboardPageyWrapper">
                        <div className="dashboardPageyGrid">
                            <DashboardAside active="subscriptions" />
                            <div className="dashboardPageyContent">
                                <div className="cardBoxy">
                                    <div className="dashboardPageyHeader">
                                        <h2>Subscriptions</h2>
                                    </div>
                                    <div className="dashboardPageyBody">
                                        <div className="dashboardPageyTabs">
                                            <nav className="tabsNavigation">
                                                <ul className="tabsNavigationList">
                                                    <li className="tabsNavigationItem active">
                                                        <span>ALL SUBSCRIPTIONS</span>
                                                        <span>({context.subscriptions.length})</span>
                                                    </li>
                                                    {/* <li className="tabsNavigationItem">
                                                        <span>EXPIRED</span>
                                                    </li> */}
                                                </ul>
                                            </nav>
                                            <div className="tabsBody">
                                                <div className="tabsBodyList">
                                                    {context.subscriptions.length != 0 ? context.subscriptions.map((subscription, key) => (<SubscriptionItem key={key} subscription={subscription} />)) : <span>No subscriptions found</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscriptions