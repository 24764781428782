

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logos/nitrade_logo.png';
import ShopContext from '../context/shop-context';
import { BottomHeader } from './header/bottom-header';
import { TopHeader } from './header/top-header';

const Header = (props) => {
    const context = useContext(ShopContext);

    return (
        <header className="header-main">
            <TopHeader section={props.section}/>
            {/* {context.logged ==1 && props.section == "dashboard" ? <></>:<BottomHeader />} */}
            <BottomHeader />
        </header>
    )
}

export default Header