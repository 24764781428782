import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2";
import ShopContext from "../../context/shop-context";

export const ProductItem = (props) => {
    const context = useContext(ShopContext);
    const navigate = useNavigate();
    const [inCart, setInCart] = useState(false)

    const viewProduct = (e) => {
        navigate("/product", { state: { "productID": props.product.itemID, "baseUrl": context.baseUrl, "cartID": context.cart.cartID, "variantID": props.variant.variantID } })
    }

    useEffect(() => {
        context.itemInCart(context.cart, props.variant.variantID, "product").then((val) => {
            setInCart(val)
        })
    }, [])

    return (
        <div className="productCategoryListItem" onClick={viewProduct}>
            <div className="productCategoryListImage">
                <a href="#">
                    {props.variant.image == "" || props.variant.image == undefined ? props.product.imageURL != "" && props.product.imageURL != undefined ? <img  className="responsive-image" src={context.awsS3url + "/images/" + props.product.imageURL} alt="" /> :
                        <img src={"assets/images/eject.png"} alt="" /> : <img className="responsive-image" src={context.awsS3url + "/images/" + props.variant.image} alt="" />}
                </a>
            </div>
            <div className="productCategoryListTitle">
                <h4 className="h4">
                    <a href="#">{props.product.name}({props.variant.name})</a>
                </h4>
                {!props.variant.inStock?<div className="productOutOfStock">
                    <span className="dot"></span>
                    <span className="renderText">Out of stock</span>
                </div>:""}
            </div>
            <div className="productCategoryListPlatform XBOXONE">({props.variant.platform})</div>
            <div className="productCategoryListAction">
                <a href="#">
                    {inCart ? <span className="actionText">In Cart</span> : <span className="actionText">BUY NOW</span>}
                    <span className="actionPrice">Ksh. {props.variant.salePrice}</span>
                </a>
            </div>
        </div>
    )
}