

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import ShopContext from '../context/shop-context';
import toast, { Toaster } from 'react-hot-toast';

const ContactUs = () => {
    const context = useContext(ShopContext);

    const navigate = useNavigate()
    const { state } = useLocation();

    const [values, setValues] = useState({
        name: "",
        email: "",
        subject: "",
        issue: "",
    })
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const submitValue = () => {
        if ((values.email == "")||(values.name == "")||(values.subject == "")||(values.issue == ""))  {
            toast.error("Required details missing.");
            return
        }
        context.addContact(values.name,values.email,values.subject,values.issue)
    }

    return (
        <main id="content">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="contact-pagey">
                <div className="contact-pagey__container">
                    <div className="contact-pagey__content">
                        <div className="contact-pagey__h1">
                            <h1>How can we help you?</h1>
                        </div>
                        <div className="contact-pagey__h2">
                            <h2>Submit a ticket below. Alternatively you can go to <a target="_blank" href="https://ticket.nitrade.pro/newissue">ticket system</a>. Want to follow up on a ticket? <a target="_blank" href="https://ticket.nitrade.pro/">click here</a></h2>
                        </div>
                        <div className="contact-pagey__contact">
                            <form>
                                <div className="input-container">
                                    <label className="label">Full Name<span className="asterik">*</span></label>
                                    <input  onChange={onChange}  name="name" type="text" className="form-control" />
                                </div>
                                <div className="input-container">
                                    <label className="label">Email<span className="asterik">*</span></label>
                                    <input  onChange={onChange}  name="email" type="text" className="form-control" />
                                </div>
                                <div className="input-container">
                                    <label className="label">Subject<span className="asterik">*</span></label>
                                    <input  onChange={onChange}  name="subject" type="text" className="form-control" />
                                </div>
                                <div className="input-container">
                                    <label className="label">Message<span className="asterik">*</span></label>
                                    <textarea  onChange={onChange} name="issue" className="form-control form-textarea"></textarea>
                                </div>
                                <div className="contact-pagey__grid">
                                    <button type="button" className="btn btn--primary" onClick={submitValue}>Submit</button>
                                    <a href="/" className="btn btn--black">Cancel</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ContactUs