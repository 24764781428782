import React from 'react';
import Header from "../components/header";
import Footer from '../components/footer';
import Product from '../components/product/product';
import Cookies from '../components/cookies';
import Loading from '../components/loading';

const ProductPage =()=> {
  
    return (
      <div>
        <Loading/>
      <div className="overlay-mobileMenu"></div>
        <Header section="shop"/>
        <Product />
        <Footer />
        <Cookies/>
      </div>
    )
}

export default ProductPage