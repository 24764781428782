
import React, { useEffect } from 'react';
import Header from "../components/header";
import Footer from '../components/footer';
import Cookies from '../components/cookies';
import Loading from '../components/loading';
import StreamHome from '../components/tv/stream-home';

export const StreamHomePage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="shop" />
      <StreamHome />
      <Footer />
      <Cookies />
    </div>
  )
}
export default StreamHomePage
