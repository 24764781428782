

import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import ShopContext from '../context/shop-context';

const Cookies = () => {
    const context = useContext(ShopContext);

    const [show, setShow] = useState(true)

    const acceptCookies = () => {
        console.log("Accept cookies")
        localStorage.setItem("NitradeShop_payload", true)
        setShow(false)
    }
    const rejectCookies = () => {
        console.log("Reject cookies")
        localStorage.setItem("NitradeShop_payload", false)
        setShow(false)
    }
    useEffect(() => {
        var payload = localStorage.getItem("NitradeShop_payload")
        if (payload == undefined) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [])
    return (
        <div>
            {!context.cookiesAllowed ?
                show ?
                    <div className="cookies">
                        <div className="container">
                            <div className="cookies__grid">
                                <div className="cookies__content">
                                    <h2>Cookies</h2>
                                    <p>We understand that your online privacy is very important and consenting to our collection of some personal information takes great trust. We ask for this consent because it allows Nitrade to provide an experience that you truly love.</p>
                                </div>
                                <div className="cookies__buttons">
                                    <a href="#" onClick={rejectCookies} className="btn btn--primary btn--alt">Reject all cookies</a>
                                    <a href="#" onClick={acceptCookies} className="btn btn--primary">Accept all cookies</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
                : ""
            }</div>
    )
}

export default Cookies