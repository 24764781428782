import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import ShopContext from '../../context/shop-context';
import DashboardAside from './dashboard-aside';


export const AccountReview = () => {
    const context = useContext(ShopContext);

    useEffect(() => {
        context.fetchUser(localStorage.getItem("userID"))
        context.fetchPrimaryAddress(localStorage.getItem("userID"))
    }, [])

    return (
        <div id="dashboard">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="dashboardPagey">
                <div className="container">
                    <div className="dashboardPageyWrapper">
                        <div className="dashboardPageyGrid">
                            <DashboardAside active="dashboard"/>
                            <div className="dashboardPageyContent">
                                <div className="cardBoxy">
                                    <div className="dashboardPageyHeader">
                                        <h2>Account Overview</h2>
                                    </div>
                                    <div className="dashboardPageyBody">
                                        <div className="dashboardBoxGrid gridTwo">
                                            <div className="dashboardBoxItem">
                                                <div className="dashboardBoxHeader">
                                                    <h3>ACCOUNT DETAILS</h3>
                                                </div>
                                                <div className="dashboardBoxBody">
                                                {context.user.userId!=0?<>
                                                    <h3 className="dashboardBoxTitle text-uppercase">{context.user.firstName} {context.user.lastName}</h3>
                                                    <p className="dashboardBoxPara">{context.user.email}</p>
                                                    <p className="dashboardBoxPara">Phone: {context.user.phoneNumber}</p>
                                                    </>:<span>User not found</span>}
                                                </div>
                                            </div>
                                            <div className="dashboardBoxItem">
                                                <div className="dashboardBoxHeader">
                                                    <h3>ADDRESS BOOK</h3>
                                                </div>
                                                <div className="dashboardBoxBody">
                                                    <h3 className="dashboardBoxTitle text-uppercase">Your default shipping
                                                        address:</h3>
                                                    
                                                    {context.address.addressID!=0?<ul className="dashboardBoxList">
                                                        {context.user.userId!=0?<li>{context.user.firstName} {context.user.lastName}</li>:<></>}
                                                        <li>City: {context.address.city}</li>
                                                        <li>Area: {context.address.area}</li>
                                                        <li>Street: {context.address.street}</li>
                                                        {context.user.userId!=0?<li>{context.user.phoneNumber}</li>:<></>}
                                                    </ul>:<span>No address</span>}
                                                </div>
                                            </div>
                                            <div className="dashboardBoxItem">
                                                <div className="dashboardBoxHeader">
                                                    <h3>NEWSLETTER PREFERENCES</h3>
                                                </div>
                                                <div className="dashboardBoxBody">
                                                    <h3 className="dashboardBoxTitle text-uppercase">You are currently
                                                        subscribed to following newsletters:</h3>
                                                    <ul className="dashboardBoxList">
                                                        <li>daily newsletters</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountReview