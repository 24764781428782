import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PaymentOverlay } from '../components/checkout/payment-overlay';
import { AddressPage } from './address-page';

export const PaymentOverlayPage = () => {
  const { state } = useLocation();

  return (
    <div>
      <PaymentOverlay method={state.method}  />
      <AddressPage />
    </div>
  )
}

export default PaymentOverlayPage