import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ContactUs from '../components/contactUs';
import Cookies from '../components/cookies';
import Footer from '../components/footer';
import Header from '../components/header';
import Loading from '../components/loading';

export const ContactUsPage = (props) => {
  const { state } = useLocation();

  return (
    <div>
      <Loading/>
      <div className="overlay-mobileMenu"></div>
      <Header section="shop" />
      <ContactUs />
      <Footer />
      <Cookies/>
    </div>
  )
}

export default ContactUsPage