import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import ShopContext from '../../../context/shop-context';
import DashboardAside from '../dashboard-aside';
import OrderItem from './order-item';


export const Orders = () => {
    const context = useContext(ShopContext);
    const [orders, setOrders] = useState([]);
    // const navigate = useNavigate();



    useEffect(() => {
        var userID = localStorage.getItem("userID")
        context.fetchOrders(userID)
    }, [])

    return (
        <div id="dashboard">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="dashboardPagey">
                <div className="container">
                    <div className="dashboardPageyWrapper">
                        <div className="dashboardPageyGrid">
                            <DashboardAside active="orders"/>
                            <div className="dashboardPageyContent">
                            <div className="cardBoxy">
                                <div className="dashboardPageyHeader">
                                    <h2>Orders</h2>
                                </div>
                                <div className="dashboardPageyBody">
                                    <div className="dashboardPageyTabs">
                                        <nav className="tabsNavigation">
                                            <ul className="tabsNavigationList">
                                                <li className="tabsNavigationItem active">
                                                    <span>ALL ORDERS</span>
                                                    <span>({context.orders.length})</span>
                                                </li>
                                                {/* <li className="tabsNavigationItem">
                                                    <span>CLOSED ORDERS</span>
                                                </li> */}
                                            </ul>
                                        </nav>
                                        <div className="tabsBody">
                                            <div className="tabsBodyList">
                                                {context.orders.length!=0 ? context.orders.map((order,key)=>(<OrderItem key={key} order={order}/>)):<span>No orders found</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Orders