import React, { useEffect, useState } from 'react'

const OrderItem = (props) => {
    const [title,setTitle] = useState("")
    useEffect(()=>{
        var cartItems = props.order.cart.cartItems
        var caption = ""
        for(var i =0;i<cartItems.length;i++ ){
            var cartItem = cartItems[i]
            if(i==(cartItems.length-1)){
                if(cartItem.itemType=="product"){
                    caption=caption+cartItem.item.name+"("+cartItem.variant.name+")"
                }else{
                    caption=caption+cartItem.item.name+"("+cartItem.variant.period+")"
                }
            }else{
                if(cartItem.itemType=="product"){
                    caption=caption+cartItem.item.name+"("+cartItem.variant.name+") - "
                }else{
                    caption=caption+cartItem.item.name+"("+cartItem.variant.period+") - "
                }
            }
        }
        setTitle(caption)
    },[])
    return (
        <div className="tabsBodyItem">
            <div className="tabsBodyImage">
                <img src={"assets/images/eject.png"} alt="" />
            </div>
            <div className="tabsBodyContent">
                <h3 className="tabsBodyTitle">{title}</h3>
                <div className="tabsBodySerial">
                    <span>Order #{props.order.orderID}</span>
                </div>
                <div className="tabsBodyLabel">{props.order.status}</div>
                <div className="tabsBodyDate">Details: {props.order.payload == null?"N/A":props.order.payload}</div>
                <div className="tabsBodyDate">Amount: {props.order.cart.amount}</div>
            </div>
        </div>
    )
}

export default OrderItem