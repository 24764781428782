import React from 'react';
import Header from "../../components/header";
import Footer from '../../components/footer';
import AccountManagement from '../../components/dashboard/account-management';
import Loading from '../../components/loading';
import Cookies from '../../components/cookies';


export const AccountManagementPage = (props) => {
  return (
    <div>
      <Loading />
      <div className="overlay-mobileMenu"></div>
      <Header section="dashboard" />
      <AccountManagement />
      <Footer />
      <Cookies />
    </div>
  )
}

export default AccountManagementPage