

const NotFound = () => {
    
    return (
        <main id="404Page">
        <div className="erroPagefauxy">
            <div className="container">
                <div className="erroPageWrapper">
                    <div className="erroPageGrid">
                        <div className="erroPageContent">
                            <h1>Not Found</h1>
                            <p className="bold">We couldn’t find the page you are looking for</p>
                            <p>But we have many more shopping items for you to browse!</p>
                            <a href="/" className="btn btn--primary">GO TO HOMEPAGE</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    )
}

export default NotFound