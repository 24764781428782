import React, { useContext } from 'react'
import ShopContext from '../../context/shop-context'

const AddressCartItem = (props) => {
    const context = useContext(ShopContext)
    return (
        <div className="checkoutAsideProduct">
            <div className="checkoutAsideProductImg">
                {props.cartItem.itemType == "service" ? props.cartItem.item.image != "" && props.cartItem.item.image != undefined ?
                    <img className="responsive-image" src={context.awsS3url + "/images/" + props.cartItem.item.image} alt="" /> :
                    <img src={"assets/images/eject.png"} alt="" /> :
                    props.cartItem.item.imageURL != "" && props.cartItem.item.imageURL != undefined ?
                        <img className="responsive-image" src={context.awsS3url + "/images/" + props.cartItem.item.imageURL} alt="" /> :
                        <img src={"assets/images/eject.png"} alt="" />
                }
            </div>
            <div className="checkoutAsideProductDesc">
                <p>{props.cartItem.item.name}({props.cartItem.itemType == "product" ? props.cartItem.variant.name : props.cartItem.variant.period})</p>
                <br />
                <span>Qty: {props.cartItem.quantity}</span>
            </div>
        </div>
    )
}

export default AddressCartItem