import Cookies from '../components/cookies';
import Header from '../components/header';
import Loading from '../components/loading';
import LoginContent from "../components/loginContent";

const Login = (props) => {
    return (
        <div>
            <Loading />
            <div className="overlay-mobileMenu"></div>
            <Header />
            <LoginContent />
            <Cookies />
        </div>
    )
}

export default Login