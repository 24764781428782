import React, { Component, useContext } from 'react';

import ShopContext from './shop-context';
import axios from 'axios';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';


class GlobalState extends Component {

  state = {
    sliderItems: [
      {
        productID: 1,
        img: "tournament_slider.jpg",
        title: "FORTNITE TOURNAMENT",
        description: "Competitive events where players compete for cash prizes by playing the popular battle royale game Fortnite, ranging from local events to international competitions, which can be a way for skilled players to showcase their abilities and potentially earn money or other prizes.",
        category: "tournament",
        itemType: "service"
      },
      {
        productID: 1,
        img: "ps_xbox_gamepass_slider.jpg",
        title: "PS PLUS & XBOX GAME PASS",
        description: "Subscription-based services offered by Sony and Microsoft that provide users with access to a selection of games each month, as well as exclusive discounts, free game trials, and other perks.",
        category: "giftcard",
        itemType: "service"
      },
      {
        productID: 1,
        img: "coaching_slider.jpg",
        title: "COACHING SERVICES",
        description: " One-on-one or team coaching sessions offered by experienced players or coaches to help players improve their skills, strategies, and gameplay in popular games such as Fortnite, Call of Duty, and PUBG.🔥",
        category: "coaching",
        itemType: "service"
      },
      {
        productID: 1,
        img: "entertainment_slider.jpg",
        title: "ENTERTAINMENT NEWSLETTER",
        description: "A regular publication that provides subscribers with news, updates, and highlights related to various forms of entertainment such as sports, movies, esports events, and athletes, offering a convenient way to stay up-to-date on the latest entertainment news and events across a variety of topics.",
        category: "blog",
        itemType: "service"
      },
      {
        productID: 2,
        img: "devices_slider.jpg",
        title: "DEVICES & EQUIPMENT",
        description: "Physical tools and hardware used to play video games, such as consoles, gaming PCs, monitors, keyboards, mice, and controllers, which can impact a player's gaming experience and performance.",
        category: "device",
        itemType: "product"
      },
      {
        productID: 2,
        img: "gamecard_slider.jpg",
        title: "GAMECARDS",
        description: "Prepaid cards used to purchase digital games or in-game content such as skins, weapons, or other virtual items, often sold in retail stores and used with popular gaming platforms.",
        category: "gifcard",
        itemType: "service"
      },
      {
        productID: 2,
        img: "giftcard_slider.jpg",
        title: "GIFTCARDS",
        description: "Prepaid debit cards loaded with a certain amount of money that can be used to purchase goods or services from a specific retailer or brand, often given as gifts.",
        category: "livesports",
        itemType: "service"
      }
    ],
    articles: [
    ],
    articleHighlights: [
    ],
    searchResult: { count: 0 },
    products: [],
    services: [],
    platforms: [],
    categories: [],
    recommendation: [],
    subscriptions: [],
    orders: [],
    order: {},
    article: {},
    variant: {},
    storeDetails: { "account": "", "cartID": -1, "amount": 0, "cart": {}, "paybill": "", "store": "" },
    product: { imageUrl: "" },
    address: {},
    cart: { cartItems: [], cartID: 0 },
    user: { userId: 0 },
    paymentIsCompleteVisible: false,
    cookiesAllowed: false,
    paymentResult: "",
    paymentAction: "CANCEL",
    menuVisible: "hidden",
    addresses: [],
    // baseUrl: "http://127.0.0.1:8082/v1",
    baseUrl: "https://api.nitrade.pro/v1",
    logged: 0,
    token: "",
    CheckoutRequestID: "",
    tokenVal: "nitrade-user-token",
    userID: 0,
    loading: false,
    productsCount: 0,
    usertype: -1,
    awsS3url: "https://nitradedata.s3.eu-central-1.amazonaws.com"
  };

  fetchProducts = () => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/product/item").then(res => {
      if (res.data.success == 1) {
        // var res = prepareData(res.data.message)
        this.setState({ products: res.data.message.products })
        this.setState({ productsCount: res.data.message.count })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  fetchArticles = () => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/article").then(res => {
      if (res.data.success == 1) {
        this.setState({ articles: res.data.message })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchArticlesWithPromise = (page, url) => {
    return new Promise(function (resolve, reject) {
      // this.setState({ loading: true })
      axios.get(url + "/article/page/" + page).then(res => {
        resolve(res.data)
      })
    })
  }

  fetchArticlesSectionWithPromise = (section, page, url) => {
    return new Promise(function (resolve, reject) {
      // this.setState({ loading: true })
      axios.get(url + "/article/section/" + section + "/page/" + page + "/get").then(res => {
        resolve(res.data)
      })
    })
  }

  fetchArticlesHighlight = () => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/articles/highlight").then(res => {
      if (res.data.success == 1) {
        this.setState({ articleHighlights: res.data.message })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  search = (searchText) => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/search/" + searchText).then(res => {
      if (res.data.success == 1) {
        this.setState({ searchResult: res.data.message })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchArticle = (id) => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/article/" + id + "/get").then(res => {
      if (res.data.success == 1) {
        this.setState({ article: res.data.message })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchArticleWithPromise = (articleID, url) => {
    return new Promise(function (resolve, reject) {
      // this.setState({ loading: true })
      axios.get(url + "/article/" + articleID + "/get").then(res => {
        resolve(res.data)
      })
    })
  }

  postComment = (articleID, strEmail, strName, strComment) => {
    var params = { "email": strEmail, "name": strName, "comment": strComment }
    this.setState({ loading: true })
    axios.post(this.state.baseUrl + "/article/" + articleID + "/comment", params).then(res => {
      if (res.data.success == 1) {
        toast.success("Comment posted");
        var comment = window.setInterval(() => {
          window.location.reload()
          window.clearInterval(comment);
        }, 500)
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  postLike = (articleID, uid) => {
    var params = { "uid": uid + "" }
    this.setState({ loading: true })
    axios.post(this.state.baseUrl + "/article/" + articleID + "/like", params).then(res => {
      if (res.data.success == 1) {
        toast.success("Post liked");
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  postDisLike = (articleID) => {
    var params = {}
    this.setState({ loading: true })
    axios.post(this.state.baseUrl + "/article/" + articleID + "/dislike", params).then(res => {
      if (res.data.success == 1) {
        toast.success("Post disliked");
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchByItemType = (key) => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/product/item/type/" + key).then(res => {
      if (res.data.success == 1) {
        this.setState({ products: res.data.message.products })
        this.setState({ productsCount: res.data.message.count })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchProductByCategory = (key) => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/product/item/category/" + key).then(res => {
      if (res.data.success == 1) {
        this.setState({ products: res.data.message.products })
        this.setState({ productsCount: res.data.message.count })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchServicesByCategory = (key) => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/service/item/category/" + key).then(res => {
      if (res.data.success == 1) {
        this.setState({ services: res.data.message })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  componentDidMount = () => {
    const logged = localStorage.getItem("logged");
    const token = localStorage.getItem(this.state.tokenVal)
    const userID = localStorage.getItem("userID");
    this.setState({ userID: userID })
    this.setState({ logged: logged })
    this.setState({ token: token })
    this.fetchCartByIP()
    this.fetchCategories()
    this.fetchPlatform()

    var payload = localStorage.getItem("NitradeShop_payload")
    if (payload == undefined) {
      this.setState({ cookiesAllowed: false });
    } else {
      // console.log(payload);
      this.setState({ cookiesAllowed: true });
    }
    const headerMain = document.querySelector(".header-main")
    const body = document.querySelector("body")
    let headerHeight = headerMain.getBoundingClientRect().height
    //add top padding to body to accomodate header height
    body.style.paddingTop = `${headerHeight}px`
  }

  addItem = (id, itemType, qty) => {
    var type = itemType
    if (itemType == "blog") {
      type = "service"
    }

    var params = { "itemID": id + "", "itemType": type, "quantity": qty + "", "action": "add" }
    this.updateCartItem(params, "add", itemType)
  }
  subItem = (id, itemType, qty) => {
    var params = { "itemID": id + "", "itemType": itemType, "quantity": qty + "", "action": "sub" }
    this.updateCartItem(params, "sub", itemType)
  }
  removeItem = (id, itemType) => {
    var params = { "itemID": id + "", "itemType": itemType, "quantity": "0", "action": "remove" }
    this.updateCartItem(params, "remove", itemType)
  }

  updateCartItem(params, action, type) {
    var cartID = this.state.cart.cartID
    console.log(cartID);
    axios.patch(this.state.baseUrl + "/cart/" + cartID + "/item/update", params, { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        if (action == "remove") {
          if (type == "service") {
            if (window.location.pathname == "/cart") {
              window.location.href = "/cart"
            } else {
              window.location.href = "/services"
            }
          } else {
            window.location.href = "/cart"
          }
        } else if (action == "add") {
          if (type == "service") {
            window.location.href = "/services"
          } else if (type == "blog") {
            window.location.href = "/cart"
          } else if (type == "product") {
            window.location.href = "/products"
          }
        }
      } else {
        toast.error(res.data.message);
      }
    })
  }
  getArticle = (articleID, articles) => {
    return new Promise(function (resolve, reject) {
      for (var i = 0; i < articles.length; i++) {
        var article = articles[i]
        if (article.articleID == articleID) {
          resolve(article);
        }
      }
      resolve({})
    });
  }

  itemInCart(cart, itemID, itemType) {
    return new Promise(function (resolve, reject) {
      for (var i = 0; i < cart.cartItems.length; i++) {
        var cartItem = cart.cartItems[i]
        if ((cartItem.itemType == itemType) && (cartItem.itemType == "product")) {
          if (cartItem.variant.itemID == itemID) {
            resolve(true);
          }
        } else if ((cartItem.itemType == itemType) && (cartItem.itemType == "service")) {
          if (cartItem.variant.subscriptionID == itemID) {
            resolve(true);
          }
        }
      }
      resolve(false)
    });
  }

  addCart = (uid) => {
    var params = { userID: uid }
    axios.post(this.state.baseUrl + "/cart", params).then(res => {
      if (res.data.success == 1) {
        localStorage.setItem("userCartID", res.data.message.cartID)
        this.setState({ cart: res.data.message })
      } else {
        // alert(res.data.message);
        toast.error(res.data.message);
      }
    })
  }

  fetchOrder = (cartID, url, tokenVal) => {
    return new Promise(function (resolve, reject) {
      axios.get(url + "/user/cart/" + cartID + "/order", { headers: { token: localStorage.getItem(tokenVal) } }).then(res => {
        if (res.data.success == 1) {
          resolve(res.data.message)
        } else {
          resolve({});
        }
      })
    })
  }

  fetchCart(uid, strToken) {
    axios.get(this.state.baseUrl + "/user/" + uid + "/cart/available", { headers: { token: strToken } }).then(res => {
      if (res.data.success == 1) {
        this.setState({ cart: res.data.message })
        localStorage.setItem("userCartID", res.data.message.cartID)
      } else {
        localStorage.setItem("userCartID", 0);
      }
    })
  }

  fetchCartByIP = () => {
    axios.get(this.state.baseUrl + "/cart").then(res => {
      if (res.data.success == 1) {
        this.setState({ cart: res.data.message })
        localStorage.setItem("userCartID", res.data.message.cartID)
      } else {
        localStorage.setItem("userCartID", 0);
      }
    })
  }

  completeOrder = (cartID) => {
    var params = { status: "pending" };
    axios.patch(this.state.baseUrl + "/user/order/cart/" + cartID, params, { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        if (res.data.success == 1) {
          toast.success("Order made successfully.")
          this.setState({ cart: { cartItems: [], cartID: 0 } })
          setTimeout(() => {
            window.location.href = "/orders"
          }, 2000)
        } else {
          toast.success("Error completing order.")
        }
      }
    })
  }

  completePayment = (txn) => {
    axios.get(this.state.baseUrl + "/payment/mpesa/query/" + txn, { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        if (res.data.message.ResponseCode == 0) {
          if (res.data.message.ResultDesc.includes("cancelled")) {
            this.setState({ paymentResult: "Mpesa payment has been cancelled. Kindly try again" })
            this.setState({ paymentAction: "INITIATE PAYMENT" })
            this.setState({ paymentIsCompleteVisible: false })
            // this.setState({ CheckoutRequestID: resp.CheckoutRequestID })
          } else {
            this.setState({ paymentResult: "Payment has successfully been made." })
            this.setState({ paymentAction: "COMPLETE ORDER" })
          }


        } else {
          this.setState({ paymentResult: "Make sure you have completed the payment on your phone before proceeding. Continue to retry?" })
          this.setState({ paymentAction: "RETRY" })
        }
      } else {
        toast.error(res.data.message);
      }
    })
  }

  toggleMenu = () => {
    var arg = ""
    if (this.state.menuVisible == "hidden") {
      this.setState({ menuVisible: "visible" })
      arg = "open-menu"
      document.body.classList.add(arg);
      if (document.body.classList.contains("close-menu")) {
        document.body.classList.remove("close-menu")
      }
    } else {
      this.setState({ menuVisible: "hidden" })
      arg = "close-menu"
      document.body.classList.add(arg);
      if (document.body.classList.contains("open-menu")) {
        document.body.classList.remove("open-menu")
      }
    }
  }

  fetchCategories = () => {
    axios.get(this.state.baseUrl + "/product/item/category").then(res => {
      if (res.data.success == 1) {
        this.setState({ categories: res.data.message })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchPlatform = () => {
    axios.get(this.state.baseUrl + "/product/item/platform").then(res => {
      if (res.data.success == 1) {
        // var res = prepareData(res.data.message)
        this.setState({ platforms: res.data.message })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchProduct = (id) => {
    axios.get(this.state.baseUrl + "/product/item/" + id).then(res => {
      if (res.data.success == 1) {
        // var res = prepareData(res.data.message)
        this.setState({ product: res.data.message })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchVariant = (id) => {
    axios.get(this.state.baseUrl + "/product/item/variant/" + id).then(res => {
      if (res.data.success == 1) {
        this.setState({ variant: res.data.message })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchRecommend = (id) => {
    axios.get(this.state.baseUrl + "/product/item/variant/" + id + "/recommend").then(res => {
      if (res.data.success == 1) {
        this.setState({ recommendation: res.data.message })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchServices = () => {
    this.setState({ loading: true })
    axios.get(this.state.baseUrl + "/subscription/item").then(res => {
      if (res.data.success == 1) {
        // var res = prepareData(res.data.message)
        this.setState({ services: res.data.message })
        this.setState({ loading: false })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  subscribe = (email, category) => {
    var params = { email: email, category: category }
    axios.post(this.state.baseUrl + "/subscribe", params).then(res => {
      if (res.data.success == 1) {
        Swal.fire("Subscribed", "You've successfully subscribed. You will be receiving updates", "success").then(() => {
          window.location.href = "/"
        });
      } else {
        toast.error(res.data.message);
      }
    })
  }

  register = (phoneNumber, password, email, username, referredBy) => {
    axios.post(this.state.baseUrl + "/register", {
      'phoneNumber': phoneNumber,
      'password': password,
      'email': email,
      'username': username,
      'referredBy': referredBy,
    }).then(res => {
      if (res.data.success == 1) {
        Swal.fire("Register", "Registration successful", "success").then(() => {
          window.location.href = "/login"
        })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  login = (phoneNumber, password, to) => {
    const loginDetails = {
      'password': password,
      'phoneNumber': phoneNumber
    }

    axios.post(this.state.baseUrl + "/login", loginDetails).then(res => {
      if (res.data.success == 1) {
        Swal.fire("Login", "Login successful", "success").then(() => {
          localStorage.setItem("logged", 1);
          localStorage.setItem(this.state.tokenVal, res.data.message.token);
          localStorage.setItem("userType", res.data.message.type);
          localStorage.setItem("userID", res.data.message.userId);
          localStorage.setItem("username", res.data.message.username);
          localStorage.setItem("email", res.data.message.email);
          if (to != "") {
            window.location.href = to
          } else {
            window.location.href = "/dashboard"
          }
        })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  addContact = (name, email, subject, issue) => {
    const loginDetails = {
      'name': name,
      'email': email,
      'subject': subject,
      'issue': issue,
    }

    axios.post(this.state.baseUrl + "/user/contact", loginDetails).then(res => {
      if (res.data.success == 1) {

        var msg = "";
        if ("password" in res.data.message) {
          msg = "Contact message added successfully. Use email:" + res.data.message.email + " and password:" + res.data.message.password + " to login to ticketing system and follow up. Take a screenshot of this and save it somewhere."
        } else {
          msg = "Contact message added successfully. Use email:" + res.data.message.email + " to login to ticketing system and follow up."
        }

        Swal.fire("Contact Us", msg, "success").then(() => {
          window.location.href = "/contact-us"
        })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchAddresses = (uid) => {
    axios.get(this.state.baseUrl + "/user/" + uid + "/address", { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        var addresses = res.data.message
        this.setState({ addresses: addresses })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  fetchPrimaryAddress = (uid) => {
    axios.get(this.state.baseUrl + "/user/" + uid + "/address/primary", { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        var address = res.data.message
        this.setState({ address: address })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  changePasswd = (phoneNumber, code, password) => {
    const forgotPassDetails = {
      'phoneNumber': phoneNumber,
      'code': code,
      'password': password
    }

    axios.patch(this.state.baseUrl + "/user/auth", forgotPassDetails).then(res => {
      if (res.data.success == 1) {
        Swal.fire("Password Update", "Password update successful.", "success").then(res => {
          window.location.href = "/login"
        });
      } else {
        toast.error(res.data.message);
      }
    })
  }

  requestAuthReset = (phoneNumber) => {
    axios.get(this.state.baseUrl + "/user/auth/reset/" + phoneNumber).then(res => {
      if (res.data.success == 1) {
        Swal.fire("Password Reset", "Reset Successful. Proceed to enter the new passwword", "success").then(() => {
          window.location.href = "/reset-password"
        })
      } else {
        toast.error(res.data.message);
      }
    })
  }
  initiatePay = (phoneNumber) => {
    axios.get(this.state.baseUrl + "/payment/mpesa/express/" + this.state.cart.cartID + "/" + phoneNumber, { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        // setDetails(res.data.message)
        var resp = res.data.message;
        if ((res.data.success == 1) && (resp.ResponseCode == 0)) {
          this.setState({ paymentResult: "Transaction successfully initiated. Check your phone  to complete payment" })
          this.setState({ paymentAction: "COMPLETE" })
          this.setState({ paymentIsCompleteVisible: true })
          this.setState({ CheckoutRequestID: resp.CheckoutRequestID })
        } else {
          this.setState({ paymentResult: res.data.message.ResponseDescription })
          this.setState({ paymentAction: "RETRY" })
          this.setState({ paymentIsCompleteVisible: true })
        }
      } else {
        // Swal.fire("Payment Details", res.data.message, "error");
        this.setState({ paymentResult: res.data.message })
        this.setState({ paymentAction: "RETRY" })
        this.setState({ paymentIsCompleteVisible: true })
      }
    })
  }

  fetchPaymentDetails = (id) => {
    axios.get(this.state.baseUrl + "/payment/overlay/" + id, { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        this.setState({ storeDetails: res.data.message })
      } else {
        toast.error(res.data.message);
      }
    })
  }
  fetchUserWithResp = (uid, url, tokenVal) => {
    return new Promise(function (resolve, reject) {
      axios.get(url + "/user/" + uid + "/get", { headers: { token: localStorage.getItem(tokenVal) } }).then(res => {
        if (res.data.success != 1) {
          toast.error(res.data.message);
        } else {
          resolve(res.data.message)
        }
      })
    })
  }
  fetchUser = (uid) => {
    axios.get(this.state.baseUrl + "/user/" + uid + "/get", { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        var user = res.data.message
        this.setState({ user: user })
      } else {
        toast.error(res.data.message);
      }
    })
  }
  fetchSubscriptions = (uid) => {
    axios.get(this.state.baseUrl + "/user/" + uid + "/subscription", { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        var msg = res.data.message
        this.setState({ subscriptions: msg })
      } else {
        toast.error(res.data.message);
      }
    })
  }
  fetchOrders = (uid) => {
    axios.get(this.state.baseUrl + "/user/" + uid + "/order", { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        var msg = res.data.message
        this.setState({ orders: msg })
      } else {
        toast.error(res.data.message);
      }
    })
  }

  logout = () => {
    toast.success("Logging you out");
    window.setInterval(() => {
      window.location.href = "/login"
      localStorage.setItem("logged", 0);
      localStorage.setItem(this.state.tokenVal, undefined);
      localStorage.setItem("userType", undefined);
      localStorage.setItem("userID", undefined);
      localStorage.setItem("username", undefined);
    }, 500)

  }

  updateUserName = (uid, firstName, lastName) => {
    var params = { firstName: firstName, lastName: lastName }
    axios.patch(this.state.baseUrl + "/user/" + uid + "/name", params, { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        toast.success("Name successfully updated")
      } else {
        toast.error(res.data.message)
      }
    })
  }

  addAddress = (uid, area, street, city) => {
    var params = { area: area, street: street, city: city }
    axios.post(this.state.baseUrl + "/user/" + uid + "/address", params, { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        toast.success("Address successfully updated")
        this.attachAddressToCart(res.data.message.addressID)
      } else {
        toast.error(res.data.message)
      }
    })
  }
  attachAddressToCart = (id) => {
    var params = { addressID: id }
    axios.patch(this.state.baseUrl + "/cart/" + this.state.cart.cartID + "/address", params, { headers: { token: localStorage.getItem(this.state.tokenVal) } }).then(res => {
      if (res.data.success == 1) {
        toast.success("Address successfully attached to cart")
        var pay = window.setInterval(() => {
          window.location.href = "/checkout-payment"
          window.clearInterval(pay);
        }, 500)
      } else {
        toast.error(res.data.message)
      }
    })
  }


  render() {
    return (
      <ShopContext.Provider
        value={{
          products: this.state.products,
          services: this.state.services,
          cart: this.state.cart,
          baseUrl: this.state.baseUrl,
          token: this.state.token,
          usertype: this.state.usertype,
          userID: this.state.userID,
          awsS3url: this.state.awsS3url,
          loading: this.state.loading,
          platforms: this.state.platforms,
          categories: this.state.categories,
          productsCount: this.state.productsCount,
          productsCount: this.state.productsCount,
          recommendation: this.state.recommendation,
          variant: this.state.variant,
          product: this.state.product,
          sliderItems: this.state.sliderItems,
          logged: this.state.logged,
          user: this.state.user,
          orders: this.state.orders,
          address: this.state.address,
          subscriptions: this.state.subscriptions,
          storeDetails: this.state.storeDetails,
          paymentIsCompleteVisible: this.state.paymentIsCompleteVisible,
          paymentResult: this.state.paymentResult,
          paymentAction: this.state.paymentAction,
          CheckoutRequestID: this.state.CheckoutRequestID,
          menuVisible: this.state.menuVisible,
          order: this.state.order,
          addresses: this.state.addresses,
          tokenVal: this.state.tokenVal,
          articles: this.state.articles,
          articleHighlights: this.state.articleHighlights,
          cookiesAllowed: this.state.cookiesAllowed,
          article: this.state.article,
          searchResult: this.state.searchResult,
          postLike: this.postLike,
          postDisLike: this.postDisLike,
          getArticle: this.getArticle,
          fetchArticlesHighlight: this.fetchArticlesHighlight,
          search: this.search,
          fetchArticleWithPromise: this.fetchArticleWithPromise,
          fetchArticlesWithPromise: this.fetchArticlesWithPromise,
          fetchArticlesSectionWithPromise: this.fetchArticlesSectionWithPromise,
          fetchArticles: this.fetchArticles,
          fetchArticle: this.fetchArticle,
          postComment: this.postComment,
          attachAddressToCart: this.attachAddressToCart,
          fetchAddresses: this.fetchAddresses,
          fetchOrder: this.fetchOrder,
          toggleMenu: this.toggleMenu,
          completeOrder: this.completeOrder,
          completePayment: this.completePayment,
          itemInCart: this.itemInCart,
          changePasswd: this.changePasswd,
          requestAuthReset: this.requestAuthReset,
          initiatePay: this.initiatePay,
          fetchPaymentDetails: this.fetchPaymentDetails,
          addAddress: this.addAddress,
          updateUserName: this.updateUserName,
          login: this.login,
          register: this.register,
          addContact: this.addContact,
          scrollToTop: this.scrollToTop,
          subItem: this.subItem,
          addItem: this.addItem,
          removeItem: this.removeItem,
          fetchProducts: this.fetchProducts,
          addCart: this.addCart,
          fetchRecommend: this.fetchRecommend,
          fetchVariant: this.fetchVariant,
          fetchProduct: this.fetchProduct,
          fetchByItemType: this.fetchByItemType,
          fetchProductByCategory: this.fetchProductByCategory,
          fetchPlatform: this.fetchPlatform,
          fetchCategories: this.fetchCategories,
          fetchServicesByCategory: this.fetchServicesByCategory,
          subscribe: this.subscribe,
          fetchPrimaryAddress: this.fetchPrimaryAddress,
          fetchUserWithResp: this.fetchUserWithResp,
          fetchUser: this.fetchUser,
          fetchOrders: this.fetchOrders,
          fetchSubscriptions: this.fetchSubscriptions,
          logout: this.logout,
          fetchServices: this.fetchServices,
          fetchCartByIP: this.fetchCartByIP
        }}
      >
        {this.props.children}
      </ShopContext.Provider>
    );
  }
}

export default GlobalState;